import { app, authentication } from '@microsoft/teams-js'
import Confetti from 'react-confetti'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { getCookie } from 'helpers'
import { ButtonFilled } from 'styles/buttons-styles'
import { MANAGER_ROUTES } from 'types/routes'

import * as S from '../styles'

export const LastLeftColumn = () => {
    const { t } = useTranslation(['translation'])
    const navigate = useNavigate()
    const isMSTeamsFlow = localStorage.getItem('is-ms-teams-flow')

    const microsoftAccessToken = getCookie('MicrosoftAccessToken')
    const goNext = () => {
        ;(async () => {
            if (isMSTeamsFlow && microsoftAccessToken) {
                await app.initialize()

                authentication.notifySuccess(microsoftAccessToken)

                return
            } else {
                navigate(MANAGER_ROUTES.OFFICES)
            }
        })()
    }

    return (
        <S.WizardContent>
            <div>
                <h2>Welcome to Yoffix</h2>
                <p>Everything is ready to start!</p>
            </div>

            <S.ContainerForConfetti>
                <Confetti />
            </S.ContainerForConfetti>

            <ButtonFilled width="100%" onClick={() => goNext()}>
                {t('Go to Yoffix!', { ns: 'translation' })}
            </ButtonFilled>
        </S.WizardContent>
    )
}
