import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, NormalizedCacheObject } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { fromPromise } from '@apollo/client/link/utils'
import { Auth } from 'aws-amplify'
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

import { getNewToken } from 'helpers/getNewToken'
import { JwtTokenName } from 'shared/constants'
import { AUTH_ROUTES } from 'types/routes'

type TAppSyncApolloClient = { connectToDevTools?: boolean }
export type TApolloClient = ApolloClient<NormalizedCacheObject>

const APPSYNC_GRAPHQL_ENDPOINT = import.meta.env.VITE_APPSYNC_GRAPHQL_ENDPOINT || ''
const REGION = import.meta.env.VITE_COGNITO_REGION || ''

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            /* todo: ask back-end to add err.extensions.code */
            console.log('err', err)
            switch (err.message) {
                /* Token has expired */
                case 'Valid authorization header not provided.':
                case 'Token has expired.': {
                    return fromPromise(
                        getNewToken().catch((error) => {
                            /* redirect to login */
                            window.location.replace(AUTH_ROUTES.LOGIN)
                            return
                        }),
                    )
                        .filter((value) => Boolean(value))
                        .flatMap((newToken) => {
                            const oldHeaders = operation.getContext().headers

                            localStorage.setItem(JwtTokenName, newToken || '')

                            operation.setContext({
                                headers: {
                                    ...oldHeaders,
                                    ...(newToken ? { Authorization: newToken } : {}),
                                },
                            })

                            return forward(operation)
                        })
                }
                default: {
                    const { message, locations, path } = err
                    console.log(`[GraphQl error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
                }
            }
        }
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError.message || networkError} `)
        if (networkError.message?.includes('UnauthorizedException')) {
            return fromPromise(
                getNewToken().catch((error) => {
                    /* redirect to login */
                    window.location.replace(AUTH_ROUTES.LOGIN)
                    return
                }),
            )
                .filter((value) => Boolean(value))
                .flatMap((newToken) => {
                    const oldHeaders = operation.getContext().headers

                    localStorage.setItem(JwtTokenName, newToken || '')

                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            ...(newToken ? { Authorization: newToken } : {}),
                        },
                    })

                    return forward(operation)
                })
        }
    }
})

const getAccessToken = async () => {
    try {
        const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()

        return jwtToken
    } catch (error) {
        // If the session cannot be restored, redirect the user to the authorization page
        window.location.replace(AUTH_ROUTES.LOGIN)
        return ''
    }
}

export const createClient = ({ connectToDevTools }: TAppSyncApolloClient): TApolloClient => {
    const httpLink = new HttpLink({
        uri: APPSYNC_GRAPHQL_ENDPOINT,
    })

    const auth: AuthOptions = {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: getAccessToken,
    }

    const awsAuthLinkOptions = {
        url: APPSYNC_GRAPHQL_ENDPOINT,
        auth,
        region: REGION,
        subscriptionFailedCallback: (error) => {
            console.error('Subscription error:', error)
        },
    }

    return new ApolloClient({
        link: ApolloLink.from([
            // retryLink,
            errorLink,
            createAuthLink(awsAuthLinkOptions),
            createSubscriptionHandshakeLink(awsAuthLinkOptions, httpLink),
        ]),
        cache: new InMemoryCache(),
        connectToDevTools,
    })
}
