import { gql } from '@apollo/client'

import { DEPARTMENT, EMPLOYEE, OFFICE, POSITION } from '@graphql/dataQuery'

export const QUERY_GET_SEARCH_EMPLOYEES = gql`
    ${DEPARTMENT}
    query GetListOfEmployees(
        $filter: SearchableEmployeeFilterInput
        $limit: Int
        $nextToken: String
        $sortName: SearchableEmployeeSortableFields
        $sortOrder: SearchableSortDirection
        $filterBookingsByCreatedAt: ModelBookingFilterInput
        $loadBookings: Boolean = false
    ) {
        searchEmployees(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sort: { field: $sortName, direction: $sortOrder }
        ) {
            items {
                id
                companyID
                departmentIDs
                departmentsString
                positionID
                firstname
                lastname
                fullName
                email
                photo
                active
                roleString
                status
                role
                birthday

                Position {
                    id
                    name
                }

                EmployeeToDepartment {
                    items {
                        Department {
                            ...DepartmentFields
                        }
                    }
                }

                Office {
                    id
                    name
                }

                buddy {
                    birthday
                    active
                    buddyID
                    id
                    email
                    firstname
                    lastname
                    photo
                    fullName
                    Position {
                        name
                        id
                    }
                }

                roleID

                CustomRole {
                    id
                    name
                }

                BookingsByCreatedAt(filter: $filterBookingsByCreatedAt, limit: 1000) @include(if: $loadBookings) {
                    items {
                        spaceID
                        id
                        date
                        Space {
                            id
                            floorPlanFilePath
                            name
                            address
                            workingHoursFrom
                            workingHoursTo
                            officeID
                            companyID
                            Office {
                                id
                                name
                                address
                            }
                        }
                        reservable {
                            name
                            id
                            type
                            x
                            y
                            zonePoints
                        }
                    }
                }
            }
            nextToken
            total
        }
    }
`
/*TODO need fixed if @Roy came back*/
export const SUBSCRIPTION_UPDATE_EMPLOYEE = gql`
    subscription OnUpdateEmployee($companyID: String!) {
        onUpdateEmployee(companyID: $companyID) {
            id
        }
    }
`

export const SUBSCRIPTION_CREATE_EMPLOYEE = gql`
    ${EMPLOYEE}
    ${POSITION}
    ${OFFICE}
    subscription OnCreateEmployee($companyID: String!) {
        onCreateEmployee(companyID: $companyID) {
            ...EmployeeFields
            Position {
                ...PositionFields
            }
            Office {
                ...OfficeFields
            }
        }
    }
`

export const SUBSCRIPTION_DELETE_EMPLOYEE = gql`
    ${EMPLOYEE}
    ${DEPARTMENT}
    ${POSITION}
    ${OFFICE}
    subscription OnDeleteEmployee($companyID: String!) {
        onDeleteEmployee(companyID: $companyID) {
            ...EmployeeFields
            EmployeeToDepartment {
                items {
                    Department {
                        ...DepartmentFields
                    }
                }
            }
            Position {
                ...PositionFields
            }
            Office {
                ...OfficeFields
            }
        }
    }
`
