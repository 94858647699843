import styled from 'styled-components'

export const UploadLogo = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`

export const EditCompany = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`
