import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment/moment'
import { createSelector, createStructuredSelector } from 'reselect'

import { RepeatType as MobileScheduleRepeatType } from '@graphql/autogenerate/schemas'
import { ReservableSpaceScreen } from 'components/organisms/Reservable'
import { SpaceListItem } from 'components/organisms/SpaceListDropDown/types'
import { getAWSFormatDate, getConvertedEngLocaleDate, getHoursFromISODateString } from 'helpers'
import { RootState } from 'store'
import { BookingSubType, BookingType } from 'types/common.types'
import { MainEmployeeData } from 'types/data.types'

import { getMobileSuggestedCalendarTime } from '../helpers'
import { CalendarData, DepartmentDayAggregationEmployee } from '../models'
import { CalendarScheduleBooking, MobileScheduleHomeSwitchType } from '../types'

type CalendarScheduleState = {
    date: string
    year: number
    weekNumber: number
    scheduleBookings: Array<CalendarScheduleBooking>
    bookingStatus: BookingType
    bookingSubStatus: BookingSubType | null
    selectedSpace: SpaceListItem | null
    suggestedSpace: SpaceListItem | null
    selectedSeatID: string | null
    selectedSeat: ReservableSpaceScreen | null
    selectedBookingID: string | null
    editBookingRequestID: string | null
    isSelectedParking: boolean
    suggestedSeatID: string | null
    selectedBookingTime: [string, string]
    suggestedBookingTime: [string, string]
    scheduleTime: [string, string]
    availableTimeForBooking: [string, string]
    bookingRepeatType: MobileScheduleRepeatType
    repeatDaysOfWeek: Array<string> | null
    isSpaceLoading: boolean
    isTeamMatesLoading: boolean
    isEditMode: boolean
    isLoadedHome: boolean
    homeModeSwitch: MobileScheduleHomeSwitchType
    selectedEmployee: MainEmployeeData | null
    selectedMembers: Array<MainEmployeeData>
    titleTeamEventBooking: string
    descriptionTeamEventBooking: string
    isFullDay: boolean
    isSeatAvailable: boolean
    isNotAvailableSeatReason: string
    parkingMapStatus: boolean
    selectedReservableID: null | string
    isSpaceMapLoading: boolean
    parkingReservable: ReservableSpaceScreen[]
    selectedParkingID: string | null
    selectParkingReservable: ReservableSpaceScreen | null
    repeatDate: Array<string>
    analytics: {
        forDays: CalendarData[]
        analyticsDepartment: DepartmentDayAggregationEmployee[]
        loader: boolean
    }
}

export const initialState: CalendarScheduleState = {
    date: getAWSFormatDate(moment()),
    year: moment().year(),
    weekNumber: moment().isoWeek(),
    bookingStatus: BookingType.OFFICE,
    bookingSubStatus: null,
    selectedSpace: null,
    suggestedSpace: null,
    suggestedSeatID: null,
    scheduleBookings: [],
    selectedBookingTime: ['00:00', '24:00'],
    suggestedBookingTime: ['00:00', '24:00'],
    scheduleTime: ['00:00', '24:00'],
    availableTimeForBooking: ['00:00', '24:00'],
    bookingRepeatType: MobileScheduleRepeatType.NEVER,
    repeatDaysOfWeek: null,
    isSpaceLoading: false,
    isTeamMatesLoading: false,
    selectedSeatID: null,
    isSelectedParking: false,
    isEditMode: false,
    selectedBookingID: null,
    isLoadedHome: false,
    editBookingRequestID: null,
    homeModeSwitch: MobileScheduleHomeSwitchType.ME,
    selectedEmployee: null,
    selectedMembers: [],
    titleTeamEventBooking: '',
    descriptionTeamEventBooking: '',
    isFullDay: true,
    isSeatAvailable: true,
    isNotAvailableSeatReason: '',
    selectedSeat: null,
    parkingMapStatus: false,
    selectedReservableID: null,
    isSpaceMapLoading: false,
    parkingReservable: [],
    selectParkingReservable: null,
    selectedParkingID: null,
    repeatDate: [],
    analytics: {
        forDays: [],
        analyticsDepartment: [],
        loader: false,
    },
}
export const initialHomeScreenState: Partial<CalendarScheduleState> = {
    bookingStatus: BookingType.OFFICE,
    bookingSubStatus: null,
    bookingRepeatType: MobileScheduleRepeatType.NEVER,
    repeatDaysOfWeek: null,
    isSpaceLoading: false,
    isEditMode: false,
    editBookingRequestID: null,
    isSelectedParking: false,
    isSeatAvailable: true,
    isNotAvailableSeatReason: '',
}

type SelectWeekNumberType = {
    weekNumber: number
    year: number
}

export const calendarScheduleSlice = createSlice({
    name: 'calendarSchedule',
    initialState,
    reducers: {
        selectDate: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                date: action.payload,
            }
        },

        selectWeekNumber: (state, action: PayloadAction<SelectWeekNumberType>) => {
            return {
                ...state,
                weekNumber: action.payload.weekNumber,
                year: action.payload.year,
            }
        },

        setExistingBookings: (state, action: PayloadAction<Array<CalendarScheduleBooking>>) => {
            const suggestedBookingTime = getMobileSuggestedCalendarTime(
                action.payload,
                state.selectedBookingTime,
                state.availableTimeForBooking,
            )

            return {
                ...state,
                scheduleBookings: action.payload,
                suggestedBookingTime,
                selectedBookingTime: suggestedBookingTime,
            }
        },

        setBookingStatus: (state, action: PayloadAction<BookingType | null>) => {
            const suggestTime = state.bookingStatus === null ? state.suggestedBookingTime : state.selectedBookingTime
            const bookingStatus = action.payload || BookingType.OFFICE
            const selectedSpace = state.selectedSpace

            let selectedTimeStart: string
            let selectedTimeEnd: string

            if (bookingStatus !== BookingType.OFFICE && bookingStatus !== BookingType.TEAM_EVENT) {
                selectedTimeStart = state.scheduleTime[0]
                selectedTimeEnd = state.scheduleTime[1]
            } else {
                const timeStart = selectedSpace?.workingHoursFrom || suggestTime[0]
                const timeEnd = selectedSpace?.workingHoursTo || suggestTime[1]

                selectedTimeStart = timeStart >= suggestTime[0] ? timeStart : suggestTime[0]
                selectedTimeEnd = timeEnd <= suggestTime[1] ? timeEnd : suggestTime[1]
            }

            return {
                ...state,
                bookingStatus: bookingStatus,
                selectedBookingTime: [selectedTimeStart, selectedTimeEnd],
                availableTimeForBooking:
                    selectedSpace && (bookingStatus === BookingType.OFFICE || bookingStatus === BookingType.TEAM_EVENT)
                        ? [selectedSpace.workingHoursFrom, selectedSpace.workingHoursTo]
                        : state.scheduleTime,
                bookingSubStatus: bookingStatus !== BookingType.NOT_AVAILABLE ? null : state.bookingSubStatus,
                bookingRepeatType: MobileScheduleRepeatType.NEVER,
                repeatDaysOfWeek: null,
            }
        },

        setBookingSubStatus: (state, action: PayloadAction<BookingSubType | null>) => {
            return {
                ...state,
                bookingSubStatus: action.payload,
            }
        },

        setSelectedEmployee: (state, action: PayloadAction<MainEmployeeData | null>) => {
            return {
                ...state,
                selectedEmployee: action.payload,
            }
        },

        setEventMembers: (state, action: PayloadAction<Array<MainEmployeeData>>) => {
            return {
                ...state,
                selectedMembers: action.payload,
            }
        },

        setSelectedSpace: (state, action: PayloadAction<SpaceListItem>) => {
            const bookingStatus = state.bookingStatus || BookingType.OFFICE
            const spaceTimeStart = action?.payload?.workingHoursFrom
            const spaceTimeEnd = action?.payload?.workingHoursTo

            const selectedTimeStart =
                spaceTimeStart > state.selectedBookingTime[0] ? spaceTimeStart : state.selectedBookingTime[0]
            const selectedTimeEnd =
                spaceTimeEnd < state.selectedBookingTime[1] ? spaceTimeEnd : state.selectedBookingTime[1]

            const selectedSpace = action.payload

            return {
                ...state,
                selectedSeatID: null,
                selectedSpace,
                selectedBookingTime: [selectedTimeStart, selectedTimeEnd],
                availableTimeForBooking:
                    selectedSpace && bookingStatus === BookingType.OFFICE
                        ? [selectedSpace.workingHoursFrom, selectedSpace.workingHoursTo]
                        : state.scheduleTime,
            }
        },

        setTitleTeamEventBooking: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                titleTeamEventBooking: action.payload,
            }
        },

        setDescriptionTeamEventBooking: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                descriptionTeamEventBooking: action.payload,
            }
        },

        setSelectedSeatID: (state, action: PayloadAction<string | null>) => {
            return {
                ...state,
                selectedSeatID: action.payload,
            }
        },

        setSelectedSeat: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            return {
                ...state,
                selectedSeat: action.payload,
            }
        },

        setSelectedBookingID: (state, action: PayloadAction<string | null>) => {
            return {
                ...state,
                selectedBookingID: action.payload,
            }
        },

        setSpaceLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSpaceLoading: action.payload,
            }
        },

        setTeammatesLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isTeamMatesLoading: action.payload,
            }
        },

        setLoadedHome: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoadedHome: action.payload,
            }
        },

        setHomeModeSwitch: (state, action: PayloadAction<MobileScheduleHomeSwitchType>) => {
            return {
                ...state,
                homeModeSwitch: action.payload,
            }
        },

        setSelectedParking: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSelectedParking: action.payload,
            }
        },

        setSelectedParkingID: (state, action: PayloadAction<string | null>) => {
            return {
                ...state,
                selectedParkingID: action.payload,
            }
        },

        setSelectedBookingTime: (state, action: PayloadAction<[string, string]>) => {
            return {
                ...state,
                selectedBookingTime: action.payload,
            }
        },

        setRepeatDaysOfWeek: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDaysOfWeek: action.payload,
            }
        },

        setBookingRepeatType: (state, action: PayloadAction<MobileScheduleRepeatType>) => {
            return {
                ...state,
                bookingRepeatType: action.payload,
                repeatDaysOfWeek: [getConvertedEngLocaleDate(moment(state.date), 'dddd')],
            }
        },

        setScheduleHours: (state, action: PayloadAction<[string, string]>) => {
            return {
                ...state,
                scheduleTime: action.payload,
                // selectedBookingTime: action.payload,
            }
        },

        setSuggestedSpace: (state, action: PayloadAction<SpaceListItem | null>) => {
            return {
                ...state,
                suggestedSpace: action.payload,
            }
        },

        setSuggestedSeatID: (state, action: PayloadAction<string>) => ({
            ...state,
            suggestedSeatID: action.payload,
        }),

        setEditBooking: (state, action: PayloadAction<CalendarScheduleBooking>) => {
            const startHour = getHoursFromISODateString(action.payload.startTime)
            const endHour = getHoursFromISODateString(action.payload.endTime)
            const selectedSpace = state.selectedSpace
            const bookingStatus = action.payload.bookingType || BookingType.OFFICE

            const selectedBookingTime: [string, string] = [startHour, endHour]
            return {
                ...state,
                bookingStatus: action.payload.bookingType || BookingType.OFFICE,
                bookingSubStatus: action.payload.bookingSubType,
                bookingRepeatType: action.payload.BookingRequest?.repeatType || MobileScheduleRepeatType.NEVER,
                repeatDaysOfWeek: action.payload.BookingRequest?.daysOfWeek || null,
                isSelectedParking: !!action.payload.BookingRequest?.addParkingSlot,
                editBookingRequestID: action.payload.BookingRequest?.id || null,
                availableTimeForBooking:
                    selectedSpace && (bookingStatus === BookingType.OFFICE || bookingStatus === BookingType.TEAM_EVENT)
                        ? [selectedSpace.workingHoursFrom, selectedSpace.workingHoursTo]
                        : state.scheduleTime,
                selectedBookingTime,
                isEditMode: true,
            }
        },

        setIsSeatAvailable: (
            state,
            action: PayloadAction<{ isSeatAvailable: boolean; isNotAvailableSeatReason: string }>,
        ) => ({
            ...state,
            isSeatAvailable: action.payload.isSeatAvailable,
            isNotAvailableSeatReason: action.payload.isNotAvailableSeatReason,
        }),

        resetScreenToHome: (state) => ({
            ...state,
            selectedBookingTime: state.scheduleTime,
            ...initialHomeScreenState,
        }),

        setParkingMapStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                parkingMapStatus: action.payload,
            }
        },

        setSpaceMapLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSpaceMapLoading: action.payload,
            }
        },
        setSelectParkingReservable: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            return {
                ...state,
                selectParkingReservable: action.payload,
            }
        },

        selectRepeatDate: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDate: action.payload,
            }
        },

        setAnalytics: (
            state,
            action: PayloadAction<{
                forDays?: CalendarData[]
                analyticsDepartment?: DepartmentDayAggregationEmployee[]
                loader?: boolean
            }>,
        ) => {
            const analytics: {
                forDays: CalendarData[]
                analyticsDepartment: DepartmentDayAggregationEmployee[]
                loader: boolean
            } = {
                forDays: state.analytics.forDays,
                analyticsDepartment: state.analytics.analyticsDepartment,
                loader: state.analytics.loader,
            }
            if (action.payload.forDays) {
                analytics.forDays = action?.payload?.forDays
            }

            if (action.payload.analyticsDepartment) {
                analytics.analyticsDepartment = action?.payload?.analyticsDepartment
            }
            if (action?.payload?.loader) {
                analytics.loader = action?.payload?.loader
            } else {
                analytics.loader = false
            }

            return {
                ...state,
                analytics: { ...analytics },
            }
        },
    },
})

export const calendarScheduleActions = calendarScheduleSlice.actions
export default calendarScheduleSlice.reducer

export const selectedAnalytics = createStructuredSelector(
    {
        forDays: (state: RootState) => state.calendarSchedule.analytics.forDays,
        analyticsDepartment: (state: RootState) => state.calendarSchedule.analytics.analyticsDepartment,
        loader: (state: RootState) => state.calendarSchedule.analytics.loader,
    },
    createSelector,
)

export const selectedScheduleTime = createSelector(
    (state: RootState) => state.calendarSchedule.scheduleTime,
    (scheduleTime) => scheduleTime,
)
