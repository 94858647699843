import React, { useState, useEffect, ChangeEvent, CSSProperties } from 'react'

import { IconDropdown } from 'assets/icons'
import { getSelectItemKey } from 'helpers'
import { MultiselectItem } from 'types/common.types'

import * as S from './styles'

const isSelectedValue = (listItems: Array<MultiselectItem>, selected: string) =>
    !!listItems.find((item) => (typeof item === 'object' ? item.id === selected : item === selected))

export type Props<V> = {
    listItems: Array<V>
    onChange: (data: string) => void
    onOpen?: () => void
    disableOpen?: boolean
    value?: string
    defaultItem?: string
    styles?: CSSProperties
    width?: number
    placeholder?: string
    IconComponent?: React.ElementType
    DropDownItem?: React.ElementType
    LeftIcon?: React.ElementType
}

const PLACEHOLDER = 'PLACEHOLDER'

//todo: remove material ui (rewrite component)
const DropDown = <V extends MultiselectItem>({
    listItems,
    onChange,
    onOpen,
    disableOpen,
    defaultItem,
    styles,
    width,
    placeholder,
    IconComponent,
    value,
    DropDownItem,
    LeftIcon,
}: Props<V>) => {
    const [selectedItem, setSelectedItem] = useState(defaultItem || getSelectItemKey(listItems[0], 'id'))
    useEffect(() => {
        if (defaultItem !== undefined) {
            setSelectedItem(defaultItem)
        } else {
            setSelectedItem(PLACEHOLDER)
        }
    }, [defaultItem])

    const selectHandler = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
        if (typeof onChange === 'function') {
            setSelectedItem(e.target.value as string)
            onChange(e.target.value as string)
        }
    }

    const renderIconArrow = ({ className }) => (
        <S.ArrowWrapper className={`flex-center ${className}`}>
            <IconDropdown />
        </S.ArrowWrapper>
    )

    const Item = DropDownItem || S.DropDownItem

    return (
        <S.DropDownContainer
            disableOpen={disableOpen}
            width={width}
            isPlaceholder={selectedItem === PLACEHOLDER}
            withLeftIcon={Boolean(LeftIcon)}
        >
            {LeftIcon && (
                <S.LeftIconWrapper>
                    <LeftIcon />
                </S.LeftIconWrapper>
            )}
            <S.ItemsList
                value={isSelectedValue(listItems, selectedItem) ? selectedItem : ''}
                // @ts-ignore
                onChange={selectHandler}
                onOpen={onOpen}
                disableUnderline
                tabIndex={-1}
                style={styles}
                defaultValue={value}
                label={placeholder}
                IconComponent={IconComponent || renderIconArrow}
                withLeftIcon={Boolean(LeftIcon)}
            >
                {placeholder && (
                    <Item value={PLACEHOLDER} disabled>
                        <span className="text-cut">{placeholder}</span>
                    </Item>
                )}
                {listItems.map((item) => {
                    const itemId = getSelectItemKey(item, 'id')
                    const itemName = getSelectItemKey(item)

                    return (
                        <Item key={itemId} value={itemId}>
                            <S.ItemText className="text-cut">{itemName}</S.ItemText>
                        </Item>
                    )
                })}
            </S.ItemsList>
        </S.DropDownContainer>
    )
}

export default DropDown
