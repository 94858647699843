import * as React from "react";
import { forwardRef } from "react";
const SvgCalendarRepeat = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { width: 12, height: 14, viewBox: "0 0 12 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M6.00002 3.33332V0.666656L2.66669 3.99999L6.00002 7.33332V4.66666C8.20669 4.66666 10 6.45999 10 8.66666C10 10.8733 8.20669 12.6667 6.00002 12.6667C3.79335 12.6667 2.00002 10.8733 2.00002 8.66666H0.666687C0.666687 11.6133 3.05335 14 6.00002 14C8.94669 14 11.3334 11.6133 11.3334 8.66666C11.3334 5.71999 8.94669 3.33332 6.00002 3.33332Z", fill: "#333333", fillOpacity: 0.5, style: {
  fill: "#333333",
  fill: "color(display-p3 0.2000 0.2000 0.2000)",
  fillOpacity: 0.5
} }));
const ForwardRef = forwardRef(SvgCalendarRepeat);
export default ForwardRef;
