// import './wdyr'

import React, { StrictMode } from 'react'

import * as Sentry from '@sentry/react'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import 'simplebar-react/dist/simplebar.min.css'
import 'react-datepicker/dist/react-datepicker.css'

import GlobalErrorHandler from 'helpers/GlobalErrorHandler'
import { store } from 'store'

import App from './App'
import './i18n'
import './index.css'
import reportWebVitals from './reportWebVitals'

if (import.meta.env.VITE_SENTRY_ENABLED && import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
    })
}

const clientSideID = import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID

const renderApp = async () => {
    const LDProvider = await asyncWithLDProvider({ clientSideID: clientSideID })

    const container = document.getElementById('root')
    const root = createRoot(container!)

    root.render(
        <StrictMode>
            <Provider store={store}>
                <GlobalErrorHandler>
                    <LDProvider>
                        <App />
                    </LDProvider>
                </GlobalErrorHandler>
            </Provider>
        </StrictMode>,
    )
}

renderApp()

reportWebVitals()
