import React, { useEffect, useState, useMemo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Grid2 from '@mui/material/Grid2'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import { useTranslation } from 'react-i18next'

import { TAddressGoogle } from 'types/common.types'

const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_API_KEY_GEO

import { getStrAddress } from './utils'

interface IProps {
    initAddress?: TAddressGoogle
    initStringAddress?: string
    isFilled?: boolean
    onChange?: (address: TAddressGoogle) => void
    onBlur?: () => void
    onFocus?: () => void
}

function loadScript(src: string, position: HTMLElement | null, id: string) {
    return new Promise<boolean>((resolve, reject) => {
        if (!position) {
            reject(false)
        }

        const script = document.createElement('script')
        script.setAttribute('async', '')
        script.setAttribute('id', id)
        script.src = src

        script.onload = () => resolve(true)
        script.onerror = () => reject(false)

        position && position.appendChild(script)
    })
}

const autocompleteService = { current: null } as any

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}))

interface PlaceType {
    description: string
    structured_formatting: {
        main_text: string
        secondary_text: string
        main_text_matched_substrings: [
            {
                offset: number
                length: number
            },
        ]
    }
    place_id: string
}

function GoogleMapInput({ initAddress, initStringAddress, isFilled = true, onChange, onBlur, onFocus }: IProps) {
    const { t } = useTranslation('translation')
    const classes = useStyles()

    const [value, setValue] = useState<PlaceType | null | undefined>(null)
    const [inputValue, setInputValue] = useState('')
    const [bInputValueChange, setbInputValueChange] = useState(false)
    const [options, setOptions] = useState<PlaceType[]>([])
    const [loaded, setLoaded] = useState(false)

    const fetch = useMemo(
        () =>
            throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
                if (autocompleteService.current !== null) {
                    autocompleteService.current.getPlacePredictions(request, callback)
                }
            }, 200),
        [],
    )

    const loadMapsGoogle = async () => {
        if (typeof window !== 'undefined' && !loaded) {
            if (!document.querySelector('#google-maps')) {
                setLoaded(
                    await loadScript(
                        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`,
                        document.querySelector('head'),
                        'google-maps',
                    ),
                )
            }
        }
    }

    useEffect(() => {
        loadMapsGoogle()
    }, [])

    useEffect(() => {
        if (!bInputValueChange) {
            initAddress && setInputValue(getStrAddress(initAddress))
            initStringAddress && setInputValue(initStringAddress)
        }
    }, [initAddress, initStringAddress])

    useEffect(() => {
        if (!value && !bInputValueChange) {
            if (options[0]) {
                setValue(options[0])
            }
        }
    }, [options])

    useEffect(() => {
        let active = true

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
        }
        if (!autocompleteService.current) {
            return undefined
        }

        if (inputValue === '') {
            if (onChange && bInputValueChange) {
                onChange({})
            }
            return undefined
        }

        fetch({ input: inputValue }, (results?: PlaceType[]) => {
            if (active) {
                let newOptions = [] as PlaceType[]

                if (value) {
                    newOptions = [value]
                    const service = new (window as any).google.maps.places.PlacesService(document.createElement('div'))

                    service.getDetails({ placeId: value.place_id }, (data) => {
                        const addressArr = value.description.split(',').reverse()

                        const address: TAddressGoogle = {
                            country: addressArr[0] || '',
                            city: addressArr[1] || '',
                            address: addressArr[2] || '',
                            lat: data !== null ? data.geometry?.location?.lat() : 0,
                            long: data !== null ? data.geometry?.location?.lng().toString() : '0',
                            str: inputValue,
                        }

                        if (onChange) {
                            onChange(address)
                        }
                    })
                }

                if (results) {
                    newOptions = [...newOptions, ...results]
                }

                setOptions(newOptions)
            }
        })

        return () => {
            active = false
        }
    }, [value, inputValue, fetch, loaded])

    return (
        <Autocomplete
            id="google-map-demo"
            size="small"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue: PlaceType | null) => {
                console.log('onChange', newValue)
                setOptions(newValue ? [newValue, ...options] : options)
                setValue(newValue)
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
                setbInputValueChange(true)
            }}
            onKeyDown={(e) => e.stopPropagation()}
            renderInput={(params) =>
                isFilled ? (
                    <TextField
                        {...params}
                        label={t('Address') as string}
                        autoComplete="off"
                        variant="filled"
                        fullWidth
                        onBlur={onBlur}
                        onFocus={onFocus}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                        }}
                    />
                ) : (
                    <TextField
                        {...params}
                        label={t('Address') as string}
                        autoComplete="off"
                        variant="outlined"
                        fullWidth
                        onBlur={onBlur}
                        onFocus={onFocus}
                    />
                )
            }
            renderOption={(props, option) => {
                const { key, ...optionProps } = props
                const matches = option.structured_formatting.main_text_matched_substrings

                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [match.offset, match.offset + match.length]),
                )
                return (
                    <li key={key} {...optionProps}>
                        <Grid2 container sx={{ alignItems: 'center' }}>
                            <Grid2 sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid2>
                            <Grid2 sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{
                                            fontWeight: part.highlight ? 'fontWeightBold' : 'fontWeightRegular',
                                        }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                {option.structured_formatting.secondary_text ? (
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                ) : null}
                            </Grid2>
                        </Grid2>
                    </li>
                )
            }}
        />
    )
}

export default GoogleMapInput
