import React, { FC, createContext, useState, useContext } from 'react'

import { noop } from 'helpers'

export const PreloaderContext = createContext<{
    showPreloader: (state: boolean) => void
    setValueProgressBar: (value: number) => void
}>({
    showPreloader: noop,
    setValueProgressBar: noop,
})

export const usePreloader = () => useContext(PreloaderContext)
