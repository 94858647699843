import { Feature, FeatureType } from 'types/billing.types'

export const TrialAlertData = {
    title: 'Your trial has been \n successfully activated!',
    hasIcon: true,
    btnType: 'Continue',
}

export const subscriptionFeaturesData: Array<Feature> = [
    {
        title: 'Platforms',
        items: [{ 'Desktop + Mobile (iOS/android)': 'open' }, { 'MS Teams & Slack': 'open' }],
    },
    {
        title: 'Office set-up',
        items: [
            { 'Interactive 3D floor plans': 'open' },
            { 'Resources allocation, attributes and availability': 'open' },
            { 'Time Zones': 'open' },
        ],
    },
    {
        title: 'Workweek scheduling',
        items: [
            { 'Office and remote work scheduling & sharing': 'open' },
            { 'Sync with calendars (Outlook, Google)': 'open' },
            { 'Anonymous mode for users': 'open' },
            { 'Team Office Days': 'open' },
        ],
    },
    {
        title: 'Desk booking',
        items: [
            { 'Hourly, recurring, multiple days bookings': 'open' },
            { 'Preferred seat option ': 'open' },
            { 'Find a colleague & desk by attributes': 'open' },
            { 'Check-in (via Slack, MS Teams, QR-codes)': 'open' },
            { 'Booking via Copilot': 'plus_soon' },
        ],
    },
    {
        title: 'Room booking',
        items: [
            { 'Find and book a room with right attributes': 'open' },
            { 'Bi-directional sync of meeting rooms (Outlook, Google Calendar)': 'open' },
            { 'Rooms check-in': 'soon' },
            { 'Catering options': 'plus' },
            { 'Booking via Copilot': 'plus_soon' },
        ],
    },
    {
        title: 'Parking management',
        items: [{ 'Booking parking spots': 'open' }],
    },
    {
        title: 'Communication',
        items: [
            { 'System notifications & weekly reminders': 'open' },
            { 'Follow/invite colleagues': 'open' },
            { 'Guest management': 'plus_soon' },
            { 'Office events': 'plus_soon' },
        ],
    },
    {
        title: 'Digital office',
        items: [
            { 'SpaceScreen Digital Signage': 'open' },
            { 'QR codes in client`s design': 'plus' },
            { 'Room Display app': 'plus' },
        ],
    },
    {
        title: 'Staff administration',
        items: [{ 'Multiple teams & departments': 'open' }, { 'Roles with customizable access rights': 'plus' }],
    },
    {
        title: 'Company rules',
        items: [{ 'Notifications rules': 'open' }, { 'Check-in rules': 'open' }, { 'Office attendance rules': 'open' }],
    },
    {
        title: 'Data insights',
        items: [
            { 'Workplace statistics': 'open' },
            { 'Attendance statistics': 'open' },
            { 'Weekly Admin Report & Heatmaps': 'plus' },
            { 'Data export capability': 'plus' },
        ],
    },
    {
        title: 'Integrations',
        items: [
            { 'Microsoft Azure AD': 'open' },
            { 'Personio, BambooHR': 'open' },
            { 'SSO (Microsoft AD, Google, Okta, OpenID, SAML)': 'open' },
            { 'User Sync (SCIM, Personio, BambooHR)': 'open' },
            { 'MS multi-tenant': 'plus' },
            { 'Custom integrations': 'plus' },
        ],
    },
    {
        title: 'Support',
        items: [
            {
                'Dedicated Customer Success Manager': 'plus',
            },
        ],
    },
]

export const customFeaturesData: Array<FeatureType> = [
    { 'Unlimited users': 'open' },
    { 'Desk booking': 'open' },
    { 'Check-in feature ': 'soon' },
    { 'Custom floor plans': 'open' },
    { 'Automation rules': 'open' },
    { 'Personio integration': 'open' },
    { 'BambooHR integration': 'open' },
    { 'Microsoft Active Directory': 'soon' },
    { 'Dedicated success manager': 'open' },
    { 'Tailored joined success planning': 'open' },
]

export const subscriptionFeaturesDataByPlan: { [key: string]: Array<Feature> } = {
    'Starter (year)': [
        {
            title: 'Platforms',
            items: [{ 'Desktop + Mobile (iOS/android)': 'open' }, { 'MS Teams & Slack': 'open' }],
        },
        {
            title: 'Office set-up',
            items: [
                { 'Basic floor plans': 'open' },
                { 'Resources allocation, attributes and availability': 'open' },
                { 'Time Zones': 'open' },
            ],
        },
        {
            title: 'Workweek scheduling',
            items: [
                { 'Office and remote work scheduling & sharing': 'open' },
                { 'Sync with calendars (Outlook, Google)': 'open' },
            ],
        },
        {
            title: 'Desk booking',
            items: [
                { 'Hourly, recurring, multiple days bookings': 'open' },
                { 'Preferred seat option ': 'open' },
                { 'Find a colleague & desk by attributes': 'open' },
            ],
        },
        {
            title: 'Room booking',
            items: [{ 'Find and book a room with right attributes': 'open' }],
        },
        {
            title: 'Parking management',
            items: [],
        },
        {
            title: 'Communication',
            items: [{ 'System notifications & weekly reminders': 'open' }],
        },
        {
            title: 'Digital office',
            items: [],
        },
        {
            title: 'Staff administration',
            items: [{ 'Multiple teams & departments': 'open' }, { 'Roles with customizable access rights': 'plus' }],
        },
        {
            title: 'Company rules',
            items: [{ 'Notifications rules': 'open' }],
        },
        {
            title: 'Data insights',
            items: [{ 'Workplace statistics': 'open' }],
        },
        {
            title: 'Integrations',
            items: [],
        },
        {
            title: 'Support',
            items: [
                {
                    'Email support & tutorials': 'plus',
                },
            ],
        },
    ],
    'Pro (year)': [
        {
            title: 'Platforms',
            items: [{ 'Desktop + Mobile (iOS/android)': 'open' }, { 'MS Teams & Slack': 'open' }],
        },
        {
            title: 'Office set-up',
            items: [
                { 'Interactive 3D floor plans': 'plus' },
                { 'Resources allocation, attributes and availability': 'open' },
                { 'Time Zones': 'open' },
            ],
        },
        {
            title: 'Workweek scheduling',
            items: [
                { 'Office and remote work scheduling & sharing': 'open' },
                { 'Sync with calendars (Outlook, Google)': 'open' },
                { 'Anonymous mode for users': 'plus' },
                { 'Team Office Days': 'plus' },
            ],
        },
        {
            title: 'Desk booking',
            items: [
                { 'Hourly, recurring, multiple days bookings': 'open' },
                { 'Preferred seat option ': 'open' },
                { 'Find a colleague & desk by attributes': 'open' },
                { 'Check-in (via Slack, MS Teams, QR-codes)': 'plus' },
            ],
        },
        {
            title: 'Room booking',
            items: [
                { 'Find and book a room with right attributes': 'open' },
                { 'Bi-directional sync of meeting rooms (Outlook, Google Calendar)': 'plus' },
                { 'Rooms check-in': 'plus_soon' },
            ],
        },
        {
            title: 'Parking management',
            items: [{ 'Booking parking spots': 'plus' }],
        },
        {
            title: 'Communication',
            items: [{ 'System notifications & weekly reminders': 'open' }, { 'Follow/invite colleagues': 'plus' }],
        },
        {
            title: 'Digital office',
            items: [{ 'SpaceScreen Digital Signage': 'plus' }, { 'Basic QR-codes': 'plus' }],
        },
        {
            title: 'Staff administration',
            items: [{ 'Multiple teams & departments': 'open' }, { 'Roles with customizable access rights': 'open' }],
        },
        {
            title: 'Company rules',
            items: [
                { 'Notifications rules': 'open' },
                { 'Check-in rules': 'plus' },
                { 'Office attendance rules': 'plus' },
            ],
        },
        {
            title: 'Data insights',
            items: [{ 'Workplace statistics': 'open' }, { 'Attendance statistics': 'plus' }],
        },
        {
            title: 'Integrations',
            items: [
                { 'Microsoft Azure AD': 'plus' },
                { 'Personio, BambooHR': 'plus' },
                { 'SSO (Microsoft AD, Google, Okta, OpenID, SAML)': 'plus' },
                { 'User Sync (SCIM, Personio, BambooHR)': 'plus' },
            ],
        },
        {
            title: 'Support',
            items: [
                {
                    'Dedicated Customer Success Manager': 'plus',
                },
            ],
        },
    ],
    'Starter (month)': [
        {
            title: 'Platforms',
            items: [{ 'Desktop + Mobile (iOS/android)': 'open' }, { 'MS Teams & Slack': 'open' }],
        },
        {
            title: 'Office set-up',
            items: [
                { 'Basic floor plans': 'open' },
                { 'Resources allocation, attributes and availability': 'open' },
                { 'Time Zones': 'open' },
            ],
        },
        {
            title: 'Workweek scheduling',
            items: [
                { 'Office and remote work scheduling & sharing': 'open' },
                { 'Sync with calendars (Outlook, Google)': 'open' },
            ],
        },
        {
            title: 'Desk booking',
            items: [
                { 'Hourly, recurring, multiple days bookings': 'open' },
                { 'Preferred seat option ': 'open' },
                { 'Find a colleague & desk by attributes': 'open' },
            ],
        },
        {
            title: 'Room booking',
            items: [{ 'Find and book a room with right attributes': 'open' }],
        },
        {
            title: 'Parking management',
            items: [],
        },
        {
            title: 'Communication',
            items: [{ 'System notifications & weekly reminders': 'open' }],
        },
        {
            title: 'Digital office',
            items: [],
        },
        {
            title: 'Staff administration',
            items: [{ 'Multiple teams & departments': 'open' }, { 'Roles with customizable access rights': 'plus' }],
        },
        {
            title: 'Company rules',
            items: [{ 'Notifications rules': 'open' }],
        },
        {
            title: 'Data insights',
            items: [{ 'Workplace statistics': 'open' }],
        },
        {
            title: 'Integrations',
            items: [],
        },
        {
            title: 'Support',
            items: [
                {
                    'Email support & tutorials': 'plus',
                },
            ],
        },
    ],
    'Pro (month)': [
        {
            title: 'Platforms',
            items: [{ 'Desktop + Mobile (iOS/android)': 'open' }, { 'MS Teams & Slack': 'open' }],
        },
        {
            title: 'Office set-up',
            items: [
                { 'Interactive 3D floor plans': 'plus' },
                { 'Resources allocation, attributes and availability': 'open' },
                { 'Time Zones': 'open' },
            ],
        },
        {
            title: 'Workweek scheduling',
            items: [
                { 'Office and remote work scheduling & sharing': 'open' },
                { 'Sync with calendars (Outlook, Google)': 'open' },
                { 'Anonymous mode for users': 'plus' },
                { 'Team Office Days': 'plus' },
            ],
        },
        {
            title: 'Desk booking',
            items: [
                { 'Hourly, recurring, multiple days bookings': 'open' },
                { 'Preferred seat option ': 'open' },
                { 'Find a colleague & desk by attributes': 'open' },
                { 'Check-in (via Slack, MS Teams, QR-codes)': 'plus' },
            ],
        },
        {
            title: 'Room booking',
            items: [
                { 'Find and book a room with right attributes': 'open' },
                { 'Bi-directional sync of meeting rooms (Outlook, Google Calendar)': 'plus' },
                { 'Rooms check-in': 'plus_soon' },
            ],
        },
        {
            title: 'Parking management',
            items: [{ 'Booking parking spots': 'plus' }],
        },
        {
            title: 'Communication',
            items: [{ 'System notifications & weekly reminders': 'open' }, { 'Follow/invite colleagues': 'plus' }],
        },
        {
            title: 'Digital office',
            items: [{ 'SpaceScreen Digital Signage': 'plus' }, { 'Basic QR-codes': 'plus' }],
        },
        {
            title: 'Staff administration',
            items: [{ 'Multiple teams & departments': 'open' }, { 'Roles with customizable access rights': 'open' }],
        },
        {
            title: 'Company rules',
            items: [
                { 'Notifications rules': 'open' },
                { 'Check-in rules': 'plus' },
                { 'Office attendance rules': 'plus' },
            ],
        },
        {
            title: 'Data insights',
            items: [{ 'Workplace statistics': 'open' }, { 'Attendance statistics': 'plus' }],
        },
        {
            title: 'Integrations',
            items: [
                { 'Microsoft Azure AD': 'plus' },
                { 'Personio, BambooHR': 'plus' },
                { 'SSO (Microsoft AD, Google, Okta, OpenID, SAML)': 'plus' },
                { 'User Sync (SCIM, Personio, BambooHR)': 'plus' },
            ],
        },
        {
            title: 'Support',
            items: [
                {
                    'Dedicated Customer Success Manager': 'plus',
                },
            ],
        },
    ],
    Custom: [
        {
            title: 'Platforms',
            items: [{ 'Desktop + Mobile (iOS/android)': 'open' }, { 'MS Teams & Slack': 'open' }],
        },
        {
            title: 'Office set-up',
            items: [
                { 'Basic floor plans': 'open' },
                { 'Resources allocation, attributes and availability': 'open' },
                { 'Time Zones': 'open' },
            ],
        },
        {
            title: 'Workweek scheduling',
            items: [
                { 'Office and remote work scheduling & sharing': 'open' },
                { 'Sync with calendars (Outlook, Google)': 'open' },
                { 'Anonymous mode for users': 'open' },
                { 'Team Office Days': 'open' },
            ],
        },
    ],
}
