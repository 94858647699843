import * as React from "react";
import { forwardRef } from "react";
const SvgAdd = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 20, height: 20, viewBox: "0 0 20 20", fill: "none", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M17.3251 8.40001C16.4417 4.35001 12.4417 1.79168 8.40005 2.67501C4.35839 3.55834 1.79172 7.55834 2.67505 11.6083C3.55839 15.6583 7.55839 18.2167 11.6084 17.3333C15.6584 16.45 18.2167 12.45 17.3334 8.40001H17.3251ZM14.1667 10.8417H10.8334V14.1667C10.8334 14.625 10.4584 15 10.0001 15C9.54172 15 9.16672 14.625 9.16672 14.1667V10.8417H5.83335C5.37502 10.8417 5.00002 10.4667 5.00002 10.0083C5.00002 9.55001 5.37502 9.17501 5.83335 9.17501H9.16672V5.83334C9.16672 5.37501 9.54172 5.00001 10.0001 5.00001C10.4584 5.00001 10.8334 5.37501 10.8334 5.83334V9.17501H14.1667C14.625 9.17501 15 9.55001 15 10.0083C15 10.4667 14.625 10.8417 14.1667 10.8417Z", fill: "#0016D8", style: {
  fill: "#0016D8",
  fill: "color(display-p3 0.0000 0.0866 0.8458)",
  fillOpacity: 1
} }));
const ForwardRef = forwardRef(SvgAdd);
export default ForwardRef;
