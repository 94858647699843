import { useEffect, useState } from 'react'

import axios from 'axios'
import { useParams } from 'react-router-dom'

import logo from 'assets/common/logo-yoffix.svg?url'
import { ProgressBar } from 'components/atoms'
import AuthFooter from 'components/atoms/AuthFooter'
import { TCompany } from 'types/data.types'

import * as S from './styles'

const apiUrl = import.meta.env.VITE_API_URL

const VisitorsUnauthorized = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const { action, id } = useParams()
    const [isCompanyLoaded, setIsCompanyLoaded] = useState(false)
    const [company, setCompany] = useState<TCompany | null>(null)

    useEffect(() => {
        ;(async () => {
            if (action && id) {
                setIsLoading(true)

                await axios.post(`${apiUrl}/visitors/${action}`, {
                    guestID: id,
                })

                setIsSuccess(true)
                setIsLoading(false)
            }
        })()
    }, [action, id])

    useEffect(() => {
        ;(async () => {
            if (id) {
                const data = await axios.get(`${apiUrl}/visitors/company-info?guestID=${id}`)

                setCompany(data.data.company)
                setIsCompanyLoaded(true)
            }
        })()
    }, [id])

    return (
        <S.PageContainer>
            <S.LoginFormContainer>
                <S.FirstRow>
                    <S.LogoContainer>
                        <S.Logo src={logo} alt="Yoffix logo" />
                    </S.LogoContainer>
                </S.FirstRow>
                <div>
                    <S.WelcomeText>
                        Welcome to <br />
                        {isCompanyLoaded && company ? company.title : 'Yoffix'}
                        {isCompanyLoaded && !company && 'Yoffix'}
                    </S.WelcomeText>
                    {!isLoading && isSuccess && action === 'confirm' && (
                        <S.Text style={{ marginTop: 16 }}>
                            Thank you for confirming your visit. You're all set! We look forward to seeing you soon!
                        </S.Text>
                    )}
                    {!isLoading && isSuccess && action === 'decline' && (
                        <S.Text style={{ marginTop: 16 }}>
                            Thank you for letting us know. We're sorry you can't make it. We hope to see you soon!
                        </S.Text>
                    )}
                    {!isLoading && !isSuccess && (
                        <S.Text style={{ marginTop: 16 }}>
                            We are unable to confirm your visit. Please try again later.
                        </S.Text>
                    )}
                    {isLoading && (
                        <S.Text style={{ marginTop: 16 }}>
                            We are confirming your visit. Please wait for a moment.
                        </S.Text>
                    )}
                </div>
                {isLoading && <ProgressBar />}
                <AuthFooter style={{ marginTop: 26 }} />
            </S.LoginFormContainer>
        </S.PageContainer>
    )
}

export default VisitorsUnauthorized
