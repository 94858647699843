import { FC, useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import { ButtonFilled } from 'styles/buttons-styles'

import * as S from './styles'
import { ProgressBar } from '../../../../components/atoms'

type WizardContentProps = {
    handleSave: () => void
    isLoading?: boolean
}

export const WizardContent: FC<WizardContentProps> = ({ handleSave: handleSaveFromProps, isLoading }) => {
    const { t } = useTranslation(['translation'])
    const handleSave = useCallback(() => {
        handleSaveFromProps()
    }, [handleSaveFromProps])

    return (
        <S.WizardContent>
            <S.Text>
                To add another employee you need a subscription. You can take advantage of <br /> a 14-day trial.
            </S.Text>
            <ButtonFilled width="100%" onClick={handleSave}>
                {t('Next step', { ns: 'translation' })}
            </ButtonFilled>
            <>{isLoading && <ProgressBar />}</>
        </S.WizardContent>
    )
}
