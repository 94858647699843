import React, { FC, useState, useEffect } from 'react'

import ReactDOM from 'react-dom'
import { useNavigate, useLocation } from 'react-router-dom'

import BigSwitch from 'components/atoms/BigSwitch'
import { PlanStatusType } from 'context/billing/billing.context.types'
import { customFeaturesData } from 'context/billing/billing_content'
import {
    SubscriptionsPlan,
    BillingSubscriptionStatus,
    SubscriptionsPlanForCompany,
    TrialType,
    TARIFF,
    BillingType,
} from 'types/billing.types'
import { MANAGER_ROUTES } from 'types/routes'

import { usePortal } from './helpers'
import * as S from './styles'
import BillingCard from '../BillingCard'

type Props = {
    open: boolean
    onClose?: () => void
    subPlans: Array<SubscriptionsPlan>
    planStatus: PlanStatusType
    changePlan: (subPlanId: string, status: BillingSubscriptionStatus | null) => Promise<void>
    plansForCompany: Array<SubscriptionsPlanForCompany | TrialType>
    userTariff: TARIFF
}

const ViewModes = [
    {
        value: BillingType.MONTH,
        label: 'Monthly',
    },
    {
        value: BillingType.YEAR,
        label: 'Yearly',
    },
]

const subscriptionsPlanId = (import.meta.env.VITE_PADDLE_PLAN_IDS || '').split(',')
const deprecatedSubscriptionsPlanId = (import.meta.env.VITE_PADDLE_PLAN_DEPRECATED_IDS || '').split(',')

const SubscribePaywall: FC<Props> = ({
    open,
    onClose,
    subPlans,
    changePlan,
    plansForCompany,
    userTariff,
    planStatus,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [selectedBillingType, setSelectedBillingType] = useState<string>(BillingType.MONTH)
    const container = usePortal(open)

    useEffect(() => {
        if (userTariff === TARIFF.FREE && location.pathname === MANAGER_ROUTES.SUBSCRIPTIONS) {
            navigate(-1)
        }
    }, [userTariff, location.pathname])

    const handleSelectBillingType = (mode: string) => {
        setSelectedBillingType(mode)
    }

    const currentPlan = plansForCompany.find((sub) => subscriptionsPlanId.includes(sub.subscriptionPlanId))

    const filteredSubPlans = subPlans.filter(({ id, billingType }) => {
        return (
            !(
                deprecatedSubscriptionsPlanId.includes(id) &&
                plansForCompany.find((item) => item.subscriptionPlanId !== id)
            ) && billingType === selectedBillingType
        )
    })

    const children = (
        <S.SubscribePaywallShadowWrapper>
            <S.SubscribePaywallContentRoot>
                <S.SubscribePaywallRelativeWrapp>
                    {typeof onClose === 'function' && <S.SubscribePaywallCloseBtn onClick={onClose} />}
                    <S.SubscribePaywallInsideWrapp>
                        <S.SubscribePaywallTitleWrapper>
                            <S.SubscribePaywallTitle>Subscribe</S.SubscribePaywallTitle>
                            <S.SubscribePaywallDescription>
                                To continue using yoffix please upgrade your subscription.
                            </S.SubscribePaywallDescription>
                        </S.SubscribePaywallTitleWrapper>
                        <S.BigSwitchWrapper>
                            <BigSwitch
                                options={ViewModes}
                                onSelect={handleSelectBillingType}
                                selected={selectedBillingType}
                            ></BigSwitch>
                        </S.BigSwitchWrapper>
                        {filteredSubPlans.length > 0 && (
                            <div className="df m-auto">
                                {filteredSubPlans.map((plan) => (
                                    <BillingCard
                                        key={plan.id}
                                        title={plan.name}
                                        billingType={plan.billingType}
                                        price={plan.recurringPrice[0].amount}
                                        features={customFeaturesData}
                                        onClick={(status) => changePlan(plan.id, status)}
                                        subscriptionPlan={plansForCompany.find(
                                            (sub) => sub.subscriptionPlanId === plan.id,
                                        )}
                                        isLoading={plan.id === planStatus.planId && planStatus.loader}
                                    />
                                ))}
                                <BillingCard
                                    billingType={BillingType.MONTH}
                                    title="Enterprise"
                                    price="Custom"
                                    features={customFeaturesData}
                                />
                            </div>
                        )}
                    </S.SubscribePaywallInsideWrapp>
                </S.SubscribePaywallRelativeWrapp>
            </S.SubscribePaywallContentRoot>
        </S.SubscribePaywallShadowWrapper>
    )

    return open && container ? ReactDOM.createPortal(children, container) : null
}

export default SubscribePaywall
