import * as React from "react";
import { forwardRef } from "react";
const SvgRepeat = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { width: 16, height: 15, viewBox: "0 0 16 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { id: "repeat" }, /* @__PURE__ */ React.createElement("path", { id: "Vector", fillRule: "evenodd", clipRule: "evenodd", d: "M2.95551 6.38734C2.95551 6.65712 2.74754 6.86509 2.47775 6.86509C2.20234 6.86509 2 6.65712 2 6.38734V5.78594C2 4.26275 3.07354 3.3241 4.72038 3.3241H9.01454V2.02011C9.01454 1.78967 9.14941 1.66602 9.37987 1.66602C9.48107 1.66602 9.58784 1.69974 9.66654 1.76719L11.7911 3.53206C11.9822 3.68944 11.971 3.91988 11.7911 4.06602L9.66654 5.82528C9.58784 5.89273 9.48107 5.93207 9.37987 5.93207C9.14941 5.93207 9.01454 5.8028 9.01454 5.57797V4.25713H4.63045C3.61312 4.25713 2.95551 4.88664 2.95551 5.85338V6.38734ZM13.0445 7.58919C13.0445 7.31379 13.2468 7.11143 13.5222 7.11143C13.792 7.11143 14 7.31379 14 7.58919V8.19059C14 9.71379 12.9264 10.6524 11.274 10.6524H6.97984V11.9677C6.97984 12.1925 6.85058 12.3218 6.62012 12.3218C6.51333 12.3218 6.41216 12.2824 6.32785 12.2149L4.20325 10.4501C4.01777 10.2983 4.02339 10.0735 4.20325 9.9161L6.32785 8.15687C6.41216 8.08944 6.51333 8.05567 6.62012 8.05567C6.85058 8.05567 6.97984 8.17932 6.97984 8.40978V9.71379H11.3639C12.3869 9.71379 13.0445 9.08989 13.0445 8.12316V7.58919Z", fill: "#333333", fillOpacity: 0.5, style: {
  fill: "#333333",
  fill: "color(display-p3 0.2000 0.2000 0.2000)",
  fillOpacity: 0.5
} })));
const ForwardRef = forwardRef(SvgRepeat);
export default ForwardRef;
