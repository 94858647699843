import { createContext, useContext } from 'react'

import { noop, noopAsync } from 'helpers'
import { BillingSubscriptionStatus, SubscriptionsPlan } from 'types/billing.types'

import { defaultCheckout, defaultPlanStatus } from './billing.actions'
import { CheckoutDataType, CommonPlanType, PlanStatusType } from './billing.context.types'

type BillingContextType = {
    checkout: CheckoutDataType
    subPlans: Array<SubscriptionsPlan>
    plansForCompany: Array<CommonPlanType>
    currentPlan: CommonPlanType | null
    planStatus: PlanStatusType
    changePlan: (subPlanId: string, status: BillingSubscriptionStatus | null) => Promise<void>
    changeCheckoutUrl: (data: CheckoutDataType) => void
    changeWindow: (open: boolean) => void
}

export const BillingContext = createContext<BillingContextType>({
    checkout: defaultCheckout,
    subPlans: [],
    plansForCompany: [],
    currentPlan: null,
    planStatus: defaultPlanStatus,
    changePlan: noopAsync,
    changeCheckoutUrl: noop,
    changeWindow: noop,
})

export const useBilling = () => useContext(BillingContext)
