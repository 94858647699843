import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import 'moment/locale/de.js'
import 'moment/locale/es.js'
import 'moment/locale/fr.js'

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        lng: undefined,
        react: {
            useSuspense: true,
        },
    })

export default i18n
