import { useEffect, useCallback } from 'react'

import { useMutation } from '@apollo/client'

import { MUTATION_UPDATE_COMPANY } from '@graphql/mutations'
import { useAppDispatch, useImagesS3 } from 'hooks'
import { loaderActions } from 'store/slices/loader'
import { messageActions } from 'store/slices/message'
import { TPhotoLoaded } from 'types/common.types'
import { TCompany, TInputCompany } from 'types/data.types'

const COMPANY_LOGO_FOLDER = 'company-logo'

export const useCompanyEdit = (companyID: string) => {
    const dispatch = useAppDispatch()
    const { uploadImageS3 } = useImagesS3()

    const [updateCompany, { loading: updateLoading }] = useMutation<{ updateCompany: TCompany }, { input: TCompany }>(
        MUTATION_UPDATE_COMPANY,
        {
            onError: (err) => dispatch(messageActions.messageShown({ text: err.message, severity: 'error' })),
        },
    )

    useEffect(() => {
        dispatch(loaderActions.loaderShow(updateLoading))
    }, [updateLoading])

    const saveImage = async (logo: TPhotoLoaded) => {
        const path = companyID + '/' + COMPANY_LOGO_FOLDER + '/' + logo.fileName

        await uploadImageS3(path, logo.file as File)
        return path
    }

    const saveUpdateCompany = useCallback(
        async (
            data: Partial<Omit<TInputCompany, 'companyLogo' | 'workingTime' | 'timeZone'>>,
            logo: string | TPhotoLoaded | null | undefined,
            addressCompany?: string,
        ) => {
            let imgSrc: string | null = null

            if (typeof logo === 'string') {
                imgSrc = logo
            }

            if (typeof logo === 'object' && logo !== null) {
                imgSrc = await saveImage(logo)
            }

            await updateCompany({
                variables: {
                    // @TODO necessary add timeZone and workingTime
                    // @ts-ignore
                    input: {
                        id: companyID,
                        title: data.title,
                        phoneNumber: data.phoneNumber,
                        country: '',
                        city: '',
                        address: addressCompany,
                        companyLogo: imgSrc,
                    },
                },
            })
        },
        [saveImage, updateCompany, companyID],
    )

    return { saveUpdateCompany }
}
