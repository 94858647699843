import { gql } from '@apollo/client'

import { CALENDAR_EVENT, CALENDAR_EVENT_REQUEST } from '@graphql/fragments/calendarEvent'

export const QUERY_GET_CALENDAR_EVENT_WITH_DETAILS = gql`
    ${CALENDAR_EVENT}
    query GetCalendarEventWithDetails($eventId: ID!) {
        getCalendarEvents(id: $eventId) {
            ...CalendarEventFullFields
        }
    }
`

export const QUERY_LIST_CALENDAR_EVENTS_BY_EMPLOYEE_AND_DATE = gql`
    ${CALENDAR_EVENT_REQUEST}
    query ListCalendarEventsByEmployeeAndMoment(
        $employeeID: ID!
        $year: Int
        $week: Int
        $filter: ModelCalendarEventEmployeeFilterInput
    ) {
        listCalendarEventEmployeesByEmployeeAndWeekAndYear(
            employeeID: $employeeID
            yearIsoWeekNumber: { beginsWith: { year: $year, isoWeekNumber: $week } }
            filter: $filter
        ) {
            items {
                CalendarEvent {
                    title
                    createdAt
                    owner
                    companyID
                    id
                    dayOfMonth
                    daysOfWeek
                    description
                    endTime
                    startTime
                    status
                    onlineMeetingUrl
                    isPrivate
                    employeesIDs
                    organiserEmployeeID
                    Reservable {
                        id
                        Space {
                            name
                            companyID
                            id
                            address
                            Office {
                                name
                                address
                                id
                            }
                        }
                        companyID
                        name
                        availabilityType
                        type
                        x
                        y
                    }
                    Employees {
                        items {
                            id
                            companyID
                            employeeFullName
                            weekNumber
                            year
                            createdAt
                            owner
                        }
                    }
                    CalendarEventRequest {
                        ...CalendarEventRequestFields
                    }
                }
            }
        }
    }
`
