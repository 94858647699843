import { FC, Fragment } from 'react'

import * as S from './styles'

type TProps = {
    activeStep: number
}

const steps = ['Company settings', 'Microsoft Permissions', 'Import users', 'Ready!']
export const Steps: FC<TProps> = ({ activeStep }) => {
    return (
        <S.Steps>
            {steps.map((step, index) => (
                <Fragment key={index}>
                    <S.Step key={index} active={activeStep === index + 1} completed={activeStep > index + 1}>
                        <span className="number">{index + 1}</span>
                        <span className="text">{step}</span>
                    </S.Step>
                    {index !== steps.length - 1 && (
                        <S.StepLine
                            started={activeStep === index + 1}
                            ended={activeStep === index + 2 || activeStep === steps.length}
                        />
                    )}
                </Fragment>
            ))}
        </S.Steps>
    )
}
