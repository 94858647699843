import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import axios from 'axios'

import { QUERY_GET_S3_LINK } from '@graphql/queries'
import { ES3OperationType } from 'types/data.types'

const useImagesS3 = () => {
    const client = useApolloClient()

    const uploadImageS3 = useCallback(async (path: string, file: File) => {
        const res = await client.query<{ getS3SignedLink: string }, { operationType: ES3OperationType; path: string }>({
            query: QUERY_GET_S3_LINK,
            variables: {
                operationType: ES3OperationType.PUT_S3_OBJECT,
                path: 'private/' + path,
            },
        })

        await axios.put(res.data.getS3SignedLink, file, { headers: { 'Content-Type': file.type } })
    }, [])

    return { uploadImageS3 }
}

export default useImagesS3
