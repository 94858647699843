import moment from 'moment/moment'

import { getHoursFromISODateString } from 'helpers'

import { MobileRoomBooking } from './types'

export const isExistRoomBookingInSelectedTime = (
    bookings: Array<MobileRoomBooking>,
    selectedTime: [string, string],
    editedRoomBookingID: string | null,
) => {
    return bookings.some((booking) => {
        const selectedStart = moment(selectedTime[0], 'HH:mm')
        const selectedEnd = moment(selectedTime[1], 'HH:mm')

        const startBooking = moment(getHoursFromISODateString(booking.startTime), 'HH:mm')
        const endBooking = moment(getHoursFromISODateString(booking.endTime), 'HH:mm')

        const isBetween =
            selectedStart.isBetween(startBooking, endBooking) || selectedEnd.isBetween(startBooking, endBooking)

        const isInclude = selectedStart.isSameOrBefore(startBooking) && selectedEnd.isSameOrAfter(endBooking)

        return (isBetween || isInclude) && !(editedRoomBookingID && editedRoomBookingID === booking.id)
    })
}

export const getMobileSuggestedRoomTime = (bookings: Array<MobileRoomBooking>, scheduleTime: [string, string]) => {
    let startSuggestedHour = scheduleTime[0]
    let endSuggestedHour = scheduleTime[1]

    for (const booking of bookings) {
        const startTime = getHoursFromISODateString(booking.startTime)
        const endTime = getHoursFromISODateString(booking.endTime)

        if (startTime > startSuggestedHour) {
            endSuggestedHour = startTime
            break
        }

        if (endTime < scheduleTime[1]) {
            startSuggestedHour = endTime
        }
    }

    return [startSuggestedHour, endSuggestedHour]
}
