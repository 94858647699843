import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { createAsyncThunk } from '@reduxjs/toolkit'

import {
    Reservable,
    type Booking,
    type CalendarEvents,
    ListBookingsByEmployeeAndMomentQuery,
    ListBookingsByEmployeeAndMomentQueryVariables,
    ListCalendarEventsByEmployeeAndMomentQuery,
    ListCalendarEventsByEmployeeAndMomentQueryVariables,
    GetCalendarEventWithDetailsQuery,
    GetCalendarEventWithDetailsQueryVariables,
} from '@graphql/autogenerate/schemas'
import {
    QUERY_GET_BOOKING_BY_ID,
    QUERY_LIST_SCHEDULE_BOOKINGS_BY_EMPLOYEE,
    QUERY_RESERVABLE_WITH_SCHEDULE,
    QUERY_PARKING_WITH_SCHEDULE,
} from '@graphql/queries/bookings/queries'
import {
    QUERY_GET_CALENDAR_EVENT_WITH_DETAILS,
    QUERY_LIST_CALENDAR_EVENTS_BY_EMPLOYEE_AND_DATE,
} from '@graphql/queries/calendarEvent/queries'
import { GET_ROOM_EVENTS_FOR_MANY_DAYS_BY_RESERVABLE_ID } from 'containers/SpaceScreen/pages/SpaceScreenBooking/graphql'
import { getErrorMessage } from 'helpers'
import { messageActions } from 'store/slices/message'

export const fetchCurrentBookingById = createAsyncThunk<Booking, { bookingID: string }, { extra: any }>(
    'editBooking/fetchCurrentBookingById',
    async ({ bookingID }, thunkAPI) => {
        const { client } = thunkAPI.extra
        try {
            const response = await client.query({
                query: QUERY_GET_BOOKING_BY_ID,
                variables: {
                    bookingID,
                },
                fetchPolicy: 'no-cache',
            })
            return response.data.getBooking
        } catch (err) {
            thunkAPI.dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
            return thunkAPI.rejectWithValue(getErrorMessage(err))
        }
    },
)

export const fetchReservableById = createAsyncThunk<Reservable, { reservableID: string; filter: any }, { extra: any }>(
    'editBooking/fetchReservableById',
    async ({ reservableID, filter }, thunkAPI) => {
        const { client } = thunkAPI.extra
        try {
            const response = await client.query({
                query: QUERY_RESERVABLE_WITH_SCHEDULE,
                variables: {
                    id: reservableID,
                    ...filter,
                },
                fetchPolicy: 'no-cache',
            })
            return response.data.getReservable
        } catch (err) {
            thunkAPI.dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
            return thunkAPI.rejectWithValue(getErrorMessage(err))
        }
    },
)

export const fetchParkingById = createAsyncThunk<Reservable, { reservableID: string; filter: any }, { extra: any }>(
    'editBooking/fetchParkingById',
    async ({ reservableID, filter }, thunkAPI) => {
        const { client } = thunkAPI.extra
        try {
            const response = await client.query({
                query: QUERY_PARKING_WITH_SCHEDULE,
                variables: {
                    id: reservableID,
                    ...filter,
                },
                fetchPolicy: 'no-cache',
            })
            return response.data.getReservable
        } catch (err) {
            thunkAPI.dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
            return thunkAPI.rejectWithValue(getErrorMessage(err))
        }
    },
)

export const fetchReservableRoomById = createAsyncThunk<
    CalendarEvents[],
    { reservableID: string; filter: any },
    { extra: any }
>('editBooking/fetchReservableRoomById', async ({ reservableID, filter }, thunkAPI) => {
    const { client } = thunkAPI.extra
    try {
        const response = await client.query({
            query: GET_ROOM_EVENTS_FOR_MANY_DAYS_BY_RESERVABLE_ID,
            variables: {
                reservableID,
                ...filter,
            },
            fetchPolicy: 'no-cache',
        })
        return response.data.listCalendarEventsByReservable.items
    } catch (err) {
        thunkAPI.dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
        return thunkAPI.rejectWithValue(getErrorMessage(err))
    }
})

export const fetchEmployeeScheduleByEmployeeId = createAsyncThunk<
    NonNullable<ListBookingsByEmployeeAndMomentQuery['listBookingsByEmployeeAndStartTime']>['items'],
    ListBookingsByEmployeeAndMomentQueryVariables,
    { extra: { client: ApolloClient<NormalizedCacheObject> } }
>('editBooking/fetchEmployeeScheduleByEmployeeId', async ({ employeeID, filter, startTime }, thunkAPI) => {
    const { client } = thunkAPI.extra
    try {
        const response = await client.query<
            ListBookingsByEmployeeAndMomentQuery,
            ListBookingsByEmployeeAndMomentQueryVariables
        >({
            query: QUERY_LIST_SCHEDULE_BOOKINGS_BY_EMPLOYEE,
            variables: {
                startTime,
                employeeID,
                filter,
            },
            fetchPolicy: 'network-only',
        })
        return response.data?.listBookingsByEmployeeAndStartTime?.items || []
    } catch (err) {
        thunkAPI.dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
        return thunkAPI.rejectWithValue(getErrorMessage(err))
    }
})

export const fetchCalendarEventsByEmployeeAndDate = createAsyncThunk<
    NonNullable<
        ListCalendarEventsByEmployeeAndMomentQuery['listCalendarEventEmployeesByEmployeeAndWeekAndYear']
    >['items'],
    ListCalendarEventsByEmployeeAndMomentQueryVariables,
    { extra: { client: ApolloClient<NormalizedCacheObject> } }
>('editBooking/fetchEmployeeCalendarEvents', async ({ employeeID, filter, year, week }, thunkAPI) => {
    const { client } = thunkAPI.extra
    try {
        const response = await client.query<
            ListCalendarEventsByEmployeeAndMomentQuery,
            ListCalendarEventsByEmployeeAndMomentQueryVariables
        >({
            query: QUERY_LIST_CALENDAR_EVENTS_BY_EMPLOYEE_AND_DATE,
            variables: {
                week,
                year,
                employeeID,
                filter,
            },
            fetchPolicy: 'network-only',
        })

        return response.data?.listCalendarEventEmployeesByEmployeeAndWeekAndYear?.items || []
    } catch (err) {
        thunkAPI.dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
        return thunkAPI.rejectWithValue(getErrorMessage(err))
    }
})

export const fetchCalendarEventById = createAsyncThunk<
    GetCalendarEventWithDetailsQuery['getCalendarEvents'],
    GetCalendarEventWithDetailsQueryVariables,
    { extra: { client: ApolloClient<NormalizedCacheObject> } }
>('editBooking/fetchCalendarEventById', async ({ eventId }, thunkAPI) => {
    const { client } = thunkAPI.extra
    try {
        const response = await client.query<
            GetCalendarEventWithDetailsQuery,
            GetCalendarEventWithDetailsQueryVariables
        >({
            query: QUERY_GET_CALENDAR_EVENT_WITH_DETAILS,
            variables: {
                eventId,
            },
            fetchPolicy: 'no-cache',
        })
        return response.data.getCalendarEvents
    } catch (err) {
        thunkAPI.dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
        return thunkAPI.rejectWithValue(getErrorMessage(err))
    }
})
