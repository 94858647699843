import { FC, useState, useEffect } from 'react'

import axios from 'axios'
import { useLocation } from 'react-router-dom'

import { ProgressBar } from 'components/atoms'
import { getQueryParams } from 'helpers'

const apiUrl = import.meta.env.VITE_API_URL

export const MicrosoftLandingPage: FC = () => {
    const [isLoading, setIsLoading] = useState(false)

    const location = useLocation()
    const { token } = getQueryParams(location.search, ['token'])

    if (token) {
        localStorage.setItem('saas-token', token)
    }

    useEffect(() => {
        ;(async () => {
            if (token) {
                setIsLoading(true)
                const response = await axios.post(`${apiUrl}/microsoft-saas/user-identity`, {
                    authorizationToken: token.replace(/\+/g, '%2B'),
                })

                window.location.href = response.data.location
            }
        })()
    }, [token])

    return (
        <div>
            <h1>Welcome to Yoffix!</h1>
            {isLoading && <ProgressBar />}
        </div>
    )
}
