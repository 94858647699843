import React, { FC } from 'react'

import { TAG_TYPES } from 'components/atoms/Tag/index'

import { TagContainer, TagAmountWrapp, TagText } from './styles'

type Props = {
    text: string
    amount?: number
    type: TAG_TYPES
}

const Tag: FC<Props> = ({ text, amount, type }) => {
    return (
        <TagContainer style={{ backgroundColor: type }}>
            {amount && <TagAmountWrapp>{amount}</TagAmountWrapp>}
            <TagText>{text}</TagText>
        </TagContainer>
    )
}

export default Tag
