import React, { createContext, useState, FC, ReactNode, useContext } from 'react'

import type { Catering, MenuItem } from '@graphql/autogenerate/schemas'

import { CateringContext } from './index'

export interface MenuItemWithQuantity extends MenuItem {
    quantity?: number
}
interface CateringProviderProps {
    children: ReactNode
}

export const CateringProvider: FC<CateringProviderProps> = ({ children }) => {
    const [currentCatering, setCurrentCatering] = useState<Catering | null>(null)

    const [selectMenuItems, setSelectMenuItems] = useState<MenuItemWithQuantity[]>([])

    return (
        <CateringContext.Provider value={{ currentCatering, setCurrentCatering, selectMenuItems, setSelectMenuItems }}>
            {children}
        </CateringContext.Provider>
    )
}
