import MenuItem from '@mui/material/MenuItem'
import Select, { SelectProps } from '@mui/material/Select'
import styled from 'styled-components'

export const DropDownContainer = styled.div<{
    disableOpen?: boolean
    width?: number
    isPlaceholder: boolean
    withLeftIcon?: boolean
}>`
    height: 100%;
    border-radius: 8px;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    width: ${(props) => (props?.width ? `${props?.width}px` : '100%')};
    display: flex;

    pointer-events: ${(props) => (props.disableOpen ? 'none' : 'auto')};

    .MuiInputBase-root {
        color: ${(props) => (props.isPlaceholder ? '#929395' : '#333333')};
    }

    .custom-dropdown {
        display: flex;
        height: 100%;
        width: 100%;

        .MuiSelect-select {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 24px 0 16px;
            //margin-right: 8px;
            ${(props) => props.withLeftIcon && 'padding-left: 0;'}

            &:focus {
                background-color: transparent;
            }
        }
    }
`

export const ArrowWrapper = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 6px;
    margin-right: 8px;
    cursor: pointer;

    &.MuiSelect-iconOpen {
        transform: rotate(180deg);
    }

    svg {
        fill-opacity: 0.5;
    }
`

type ItemListProps = SelectProps & {
    withLeftIcon?: boolean
}

export const ItemsList = styled(({ className, style, withLeftIcon, ...props }: ItemListProps) => (
    <Select
        {...props}
        className="custom-dropdown"
        variant="standard"
        MenuProps={{
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: withLeftIcon ? 40 : 'left',
            },
            // getContentAnchorEl: null,
            classes: { paper: className },
            PaperProps: {
                style: {
                    boxShadow: 'none',
                    borderRadius: 12,
                    marginTop: 5,
                    ...style,
                },
            },
        }}
    />
))`
    max-height: 221px;
    font-size: 14px;
    color: #333333;

    .MuiMenuItem-root:focus,
    .MuiMenuItem-root:hover,
    .Mui-selected {
        background: rgba(51, 51, 51, 0.05);
    }
`

export const DropDownItem = styled(MenuItem)`
    padding: 0 16px;
    height: 36px;
    list-style-type: none;
    display: flex;
    align-items: center;
`

export const ItemText = styled.span`
    font-size: 14px;
    color: #333333;
`

export const LeftIconWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;

    svg {
        color: rgba(51, 51, 51, 0.5);
    }
`
