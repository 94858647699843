import React, { useEffect } from 'react'

import { ApolloProvider } from '@apollo/client'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { app } from '@microsoft/teams-js'
import * as Sentry from '@sentry/react'
import { Amplify, Auth as AmplifyAuth } from 'aws-amplify'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useNavigate, useLocation, BrowserRouter as Router } from 'react-router-dom'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import { ThemeProvider as ThemeProviderStyled } from 'styled-components'

import { createClient } from '@graphql/client-new'
import { Loader } from 'components/atoms'
import Message from 'components/atoms/Message'
import { useAuth, AuthProvider } from 'context/auth'
import { MsTeamsProvider, useMsTeams } from 'context/ms-teams'
import { PreloaderProvider } from 'context/preloader'
import { ResizeProvider } from 'context/resize'
import { initAnalytics } from 'helpers/analytics'
import { usePaddle, useAutoLogout, useAppDispatch } from 'hooks'
import EmployeeTemplate from 'templates/employee'
import ManagerTemplate from 'templates/manager'
import { EUserGroup } from 'types/user.types'

import Auth from './containers/Auth'
import theme from './theme'

const domain = window.location.hostname

AmplifyAuth.configure({
    region: import.meta.env.VITE_COGNITO_REGION,
    userPoolId: import.meta.env.VITE_USER_POOLS_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOLS_WEB_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
        domain,
        path: '/',
        expires: 365,
        sameSite: 'strict',
        secure: false,
    },
})

Amplify.configure({
    API: {
        aws_cognito_region: import.meta.env.VITE_COGNITO_REGION, // (required) - Region where Amazon Cognito project was created
        aws_user_pools_id: import.meta.env.VITE_USER_POOLS_ID, // (optional) -  Amazon Cognito User Pool ID
        aws_user_pools_web_client_id: import.meta.env.VITE_USER_POOLS_WEB_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
        aws_cognito_identity_pool_id: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID, // (optional) - Amazon Cognito Identity Pool ID
        // aws_mandatory_sign_in: 'enable', // (optional) - Users are not allowed to get the aws credentials unless they are signed in
        endpoints: [
            {
                name: 'integration-calendar',
                endpoint: import.meta.env.VITE_API_URL,
            },
            {
                name: 'saas-subscription',
                endpoint: import.meta.env.VITE_API_URL,
            },
            {
                name: 'list-microsoft-users',
                endpoint: import.meta.env.VITE_API_URL,
            },
            {
                name: 'demo-account',
                endpoint: import.meta.env.VITE_API_URL,
            },
            {
                name: 'checkin-service',
                endpoint: import.meta.env.VITE_API_URL,
            },
        ],
    },
})

const apolloClient = createClient({ connectToDevTools: import.meta.env.VITE_NODE_ENV === 'development' })

const App = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const ldClient = useLDClient()
    const { isAuth, userGroup, userAuth } = useAuth()
    const { boot, update } = useIntercom()
    const { inTeams } = useMsTeams()

    usePaddle()
    useAutoLogout()

    const disableIntercom =
        isAuth && userAuth?.companyId === '84ec97ad-8bd6-457e-a9cf-5b779675def6' && userGroup !== EUserGroup.MANAGER

    if (!inTeams && !disableIntercom) {
        boot()
    }

    useEffect(() => {
        initAnalytics(inTeams)

        if (!inTeams && !disableIntercom && isAuth) {
            update({
                customLauncherSelector: '#intercom-launcher',
                hideDefaultLauncher: true,
                customAttributes: {
                    userRole: userGroup,
                },
            })
        }

        if (isAuth && userAuth) {
            try {
                Sentry.setUser({ id: userAuth.id, companyId: userAuth.companyId })
            } catch (err) {
                console.error('Error setting Sentry user', err)
            }

            if (inTeams) {
                const urlParams = new URLSearchParams(window.location.search)
                console.log('[YOFFIX] App', inTeams, urlParams)
                ;(async () => {
                    if (window.location.pathname === '/initial-setup') {
                        return
                    }

                    const redirectUrl = urlParams.get('redirect_uri') || 'calendar'

                    console.log('[Yoffix]', redirectUrl)

                    app.notifyAppLoaded()
                    app.notifySuccess()

                    navigate(`/${redirectUrl}`)
                })()
            } else {
                ldClient?.identify(
                    {
                        key: userAuth.id,
                        custom: {
                            companyId: userAuth.companyId,
                        },
                    },
                    undefined,
                    (err, flags) => {
                        console.log('LD flags', flags)
                        console.log('LD err', err)
                    },
                )

                const startPathname = localStorage.getItem('pathname')

                if (startPathname) {
                    localStorage.removeItem('pathname')
                    navigate(startPathname)
                }
            }
        } else {
            // Save the current location to redirect after login
            const { pathname, search } = location

            localStorage.setItem('pathname', `${pathname}${search}`)
        }
    }, [isAuth, inTeams])

    return (
        <>
            {isAuth && userGroup === EUserGroup.SUPER_ADMIN && <ManagerTemplate />}
            {isAuth && userGroup === EUserGroup.MANAGER && <ManagerTemplate />}
            {isAuth && userGroup === EUserGroup.EMPLOYEE && <EmployeeTemplate />}
            {isAuth && userGroup === EUserGroup.TEAM_LEAD && <EmployeeTemplate />}
            {!isAuth && <Auth />}
        </>
    )
}

const AppContainer = () => {
    const dispatch = useAppDispatch()

    return (
        <MsTeamsProvider>
            <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID}>
                <Router>
                    <PreloaderProvider>
                        <ResizeProvider>
                            <AuthProvider dispatch={dispatch}>
                                <ThemeProviderStyled theme={theme}>
                                    <ThemeProvider theme={theme}>
                                        <ApolloProvider client={apolloClient}>
                                            <CssBaseline />
                                            <Message />
                                            <Loader />
                                            <App />
                                        </ApolloProvider>
                                    </ThemeProvider>
                                </ThemeProviderStyled>
                            </AuthProvider>
                        </ResizeProvider>
                    </PreloaderProvider>
                </Router>
            </IntercomProvider>
        </MsTeamsProvider>
    )
}

export default AppContainer
