import { gql } from '@apollo/client'

import { MAIN_EMPLOYEE } from '@graphql/dataQuery'

export const QUERY_SCHEDULE_SPACE_DATA = gql`
    ${MAIN_EMPLOYEE}
    query ListReservablesWithBookingsBySpaceAndTypeV2(
        $spaceID: ID!
        $dateFilter: ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput
        $filter: ModelBookingFilterInput
        $filterByUser: ModelReservableFilterInput
        $type: ModelStringKeyConditionInput
        $parkingFilter: ModelBookingReservableFilterInput
        $loadBookingsForSeats: Boolean = true
        $loadBookingsForParking: Boolean = false
    ) {
        listReservablesBySpaceAndType(spaceID: $spaceID, filter: $filterByUser, type: $type, limit: 1000) {
            items {
                id
                name
                x
                y
                type
                availabilityType
                maxAmountOfPeople
                zonePoints
                color

                Employee {
                    ...MainEmployeeFields
                }

                Department {
                    id
                    name
                }

                Space {
                    id
                    name
                    address
                    workingHoursFrom
                    workingHoursTo
                    officeID
                    Office {
                        id
                        name
                        address
                    }
                }

                Tags {
                    items {
                        Tag {
                            id
                            name
                        }
                    }
                }

                ReservableToDepartments {
                    items {
                        Department {
                            id
                            name
                        }
                    }
                }

                ReservableToEmployees {
                    items {
                        employee {
                            id
                            fullName
                            departmentIDs
                            shareAttendance
                        }
                    }
                }

                BookingsByDateAndDayOfWeek(dateDayOfWeek: $dateFilter, filter: $filter, limit: 1000)
                    @include(if: $loadBookingsForSeats) {
                    items {
                        id
                        startTime
                        endTime
                        isFullDay
                        isBlocked
                        employeeID
                        date
                        createdAt
                        Employee {
                            ...MainEmployeeFields
                        }
                    }
                }

                BookingReservablesByCreatedAt(filter: $parkingFilter, limit: 1000)
                    @include(if: $loadBookingsForParking) {
                    items {
                        booking {
                            startTime
                            endTime
                            isFullDay
                            isBlocked
                            employeeID
                            Employee {
                                ...MainEmployeeFields
                            }
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_SCHEDULE_OFFICES = gql`
    query ListOffices($companyID: ID!, $employeeID: ID!, $date: AWSDate) {
        listOfficesByCompanyAndOrder(companyID: $companyID, limit: 1000) {
            items {
                id
                workingFrom
                workingTo
                SpaceRows {
                    items {
                        id
                        Spaces {
                            items {
                                id
                                name
                                isAvailableToEmployees(employeeIDs: [$employeeID], date: $date)
                                floorPlanFilePath
                                workingHoursFrom
                                workingHoursTo
                                totalSeats
                                officeID
                                Office {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_SEAT_PICKER_SUGGESTED_SEATS = gql`
    query GetSuggestedSeatsByEmployeeAndDate($employeeID: ID!, $date: String!) {
        getEmployee(id: $employeeID) {
            SuggestedSeats(date: { eq: $date }) {
                items {
                    id
                    createdAt
                    updatedAt
                    spaceID
                    Reservable {
                        id
                        spaceID
                        name
                        Space {
                            id
                            floorPlanFilePath
                            workingHoursFrom
                            workingHoursTo
                            name
                            officeID
                            Office {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`

const BOOKING = gql`
    ${MAIN_EMPLOYEE}
    fragment BookingFieldsForCalendarV2 on Booking {
        id
        startTime
        endTime
        reservableID
        isBlocked
        isFullDay
        bookingType
        bookingSubType
        isTeamEvent
        checkInStatus

        Employee {
            ...MainEmployeeFields
        }

        Space {
            id
            name
            floorPlanFilePath
            workingHoursFrom
            workingHoursTo
            officeID
            Office {
                id
                name
            }
        }

        reservable {
            id
            name
            type
            x
            y
            zonePoints
            color

            Space {
                id
                name

                Office {
                    id
                    name
                }
            }
        }

        BookingRequest {
            id
            repeatType
            addParkingSlot
            daysOfWeek
            ParkingSlot {
                id
                name
                type
                y
                x
                Space {
                    id
                    name
                    floorPlanFilePath
                    workingHoursFrom
                    workingHoursTo
                    officeID
                    Office {
                        id
                        name
                    }
                }
            }
        }

        reservables {
            items {
                id
                reservableID
                reservable {
                    id
                    name
                    Space {
                        id
                        name
                        floorPlanFilePath
                        workingHoursFrom
                        workingHoursTo
                        officeID
                        Office {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_LIST_SCHEDULE_BOOKINGS_BY_EMPLOYEE = gql`
    ${BOOKING}
    query ListBookingsByEmployeeAndMoment($employeeID: ID!, $startTime: String, $filter: ModelBookingFilterInput) {
        listBookingsByEmployeeAndStartTime(employeeID: $employeeID, startTime: { ge: $startTime }, filter: $filter) {
            items {
                ...BookingFieldsForCalendarV2
            }
        }
    }
`

export const QUERY_GET_BOOKING_BY_ID = gql`
    ${BOOKING}
    query GetBookingByIDWithTeamEventRequest($bookingID: ID!) {
        getBooking(id: $bookingID) {
            ...BookingFieldsForCalendarV2
            TeamEventRequest {
                owner
                id
                companyID
                Space {
                    companyID
                    id
                    address
                }
                id
                startTime
                endTime
                reservableID
                repeatType
                daysOfWeek
                Reservable {
                    id
                    type
                    x
                    y
                    zonePoints
                    color
                }
            }
        }
    }
`

export const QUERY_SCHEDULE_SPACE_BY_ID_DATA_SUBSCRIBE = gql`
    ${MAIN_EMPLOYEE}
    query getReservableByIDWithBookingsBySpace(
        $id: ID!
        $employee: [EmployeesWithDepartments]!
        $dates: [AWSDate]!
        $bookingRepeatType: RepeatType
        $daysOfWeek: [String]
        $startTime: String
        $endTime: String
        $dateFilter: ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput
        $filter: ModelBookingFilterInput
        $parkingFilter: ModelBookingReservableFilterInput
    ) {
        getReservable(id: $id) {
            id
            name
            x
            y
            type
            availabilityType
            maxAmountOfPeople
            zonePoints
            color
            Employee {
                ...MainEmployeeFields
            }

            Department {
                name
            }

            Tags {
                items {
                    Tag {
                        id
                        name
                    }
                }
            }

            ReservableToDepartments {
                items {
                    Department {
                        id
                        name
                    }
                }
            }

            availabilityByDatesAndTime(
                bookingRepeatType: $bookingRepeatType
                employees: $employee
                dates: $dates
                daysOfWeek: $daysOfWeek
                startTime: $startTime
                endTime: $endTime
            )

            BookingReservablesByCreatedAt(filter: $parkingFilter, limit: 1000) {
                items {
                    booking {
                        bookingType
                        bookingSubType
                        startTime
                        endTime
                        isFullDay
                        isBlocked
                        employeeID
                        reservable {
                            type
                            id
                            name
                        }
                        Employee {
                            ...MainEmployeeFields
                        }
                    }
                }
            }

            BookingsByDateAndDayOfWeek(limit: 1000, dateDayOfWeek: $dateFilter, filter: $filter) {
                items {
                    id
                    startTime
                    endTime
                    isFullDay
                    isBlocked
                    employeeID
                    bookingType
                    bookingSubType
                    isBlocked
                    Employee {
                        ...MainEmployeeFields
                    }
                    Space {
                        Office {
                            name
                        }
                    }
                    BookingRequest {
                        id
                        repeatType
                        daysOfWeek
                        addParkingSlot
                        ParkingSlot {
                            id
                            name
                            type
                            y
                            x
                            type
                            Space {
                                id
                                name
                                Office {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_RESERVABLE_WITH_SCHEDULE = gql`
    ${MAIN_EMPLOYEE}
    query getReservableByIdWithBookings(
        $id: ID!
        $dateFilter: ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput
        $filter: ModelBookingFilterInput
    ) {
        getReservable(id: $id) {
            id
            name
            x
            y
            type
            availabilityType
            maxAmountOfPeople
            zonePoints
            color

            BookingsByDateAndDayOfWeek(limit: 1000, dateDayOfWeek: $dateFilter, filter: $filter) {
                items {
                    id
                    startTime
                    endTime
                    isFullDay
                    isBlocked
                    employeeID
                    bookingType
                    bookingSubType
                    isBlocked
                    Employee {
                        ...MainEmployeeFields
                    }
                    Space {
                        Office {
                            name
                        }
                    }
                    BookingRequest {
                        id
                        repeatType
                        daysOfWeek
                        addParkingSlot
                        ParkingSlot {
                            id
                            name
                            type
                            y
                            x
                            type
                            Space {
                                id
                                name
                                Office {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_PARKING_WITH_SCHEDULE = gql`
    ${MAIN_EMPLOYEE}
    query getReservableByIdWithBookingsAndAvailability($id: ID!, $parkingFilter: ModelBookingReservableFilterInput) {
        getReservable(id: $id) {
            id
            name
            x
            y
            type
            availabilityType
            maxAmountOfPeople
            zonePoints
            color

            BookingReservablesByCreatedAt(filter: $parkingFilter, limit: 1000) {
                items {
                    booking {
                        bookingType
                        bookingSubType
                        startTime
                        endTime
                        isFullDay
                        isBlocked
                        employeeID
                        reservable {
                            type
                            id
                            name
                        }
                        Employee {
                            ...MainEmployeeFields
                        }
                    }
                }
            }
        }
    }
`
