import { createContext, useContext } from 'react'

import { DeviceSize } from 'types'

export const ResizeContext = createContext<{
    device: DeviceSize | null
    isMobile: boolean
    isDesktop: boolean
    height: number
}>({
    device: null,
    isDesktop: false,
    isMobile: false,
    height: 0,
})

export const useResizeDevice = () => useContext(ResizeContext)
