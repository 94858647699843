import moment from 'moment/moment'

import { LimitByDepartment, statusesList } from 'containers/Calendar/screens/DesktopCalendar/helpers'
import {
    getHoursFromISODateString,
    getHoursFromString,
    getHourStringFromNumber,
    getRangeMinutes,
    getSelectItemKey,
} from 'helpers'
import { BookingSubType, BookingType, MultiselectItem, NextToken } from 'types/common.types'
import { EmployeeStatus, EReservableType } from 'types/data.types'

import { CalendarScheduleBooking, StatusesFilter } from './types'

const subStatuses: (BookingSubType | BookingType)[] = [
    BookingSubType.VACATION,
    BookingSubType.SICK_LEAVE,
    BookingSubType.BUSINESS_TRIP,
]

export const getHourFromDate = (date: string) => +getHoursFromISODateString(date).split(':')[0]

export const getRangeHours = (from: string, to: string) => {
    const startHour = getHourFromDate(from)
    const endHour = getHourFromDate(to)
    const hoursAmount = endHour - startHour

    return [startHour, endHour, hoursAmount]
}

export const isOverlappedWithOthersMyBookings = (
    bookings: Array<{ startTime: string; endTime: string }>,
    selectedTime: [string, string],
    bookingType?: string,
) => {
    if (bookingType === BookingType.APPROVED) {
        return false
    }

    return bookings.some((booking) => {
        const selectedStart = moment(selectedTime[0], 'HH:mm')
        const selectedEnd = moment(selectedTime[1], 'HH:mm')

        const startBooking = moment(getHoursFromISODateString(booking.startTime), 'HH:mm')
        const endBooking = moment(getHoursFromISODateString(booking.endTime), 'HH:mm')

        const isBetween =
            selectedStart.isBetween(startBooking, endBooking) || selectedEnd.isBetween(startBooking, endBooking)

        const isInclude = selectedStart.isSameOrBefore(startBooking) && selectedEnd.isSameOrAfter(endBooking)

        return isBetween || isInclude
    })
}

export const isOverlappedWithMyCalendarEvents = (
    bookings: Array<{ startTime: string; endTime: string }>,
    selectedTime: [string, string],
    date: string, // Expected format: 'YYYY-MM-DD'
) => {
    // Parse selected times in UTC to ignore time zone differences and drop seconds.
    const selectedStart = moment.utc(`${date} ${selectedTime[0]}`, 'YYYY-MM-DD HH:mm').seconds(0)
    const selectedEnd = moment.utc(`${date} ${selectedTime[1]}`, 'YYYY-MM-DD HH:mm').seconds(0)

    return bookings.some(({ startTime, endTime }) => {
        // Parse booking times in UTC and reset seconds to zero.
        const startBooking = moment.utc(startTime).seconds(0)
        const endBooking = moment.utc(endTime).seconds(0)

        // Check if the booking occurs on the same day as the selected date (in UTC).
        // Using 'day' granularity ensures comparison just by date.
        if (!startBooking.isSame(selectedStart, 'day') && !endBooking.isSame(selectedStart, 'day')) {
            return false
        }

        // Check for overlap using standard interval comparison:
        // Two intervals [A, B] and [C, D] overlap if A < D and B > C.
        return selectedStart.isBefore(endBooking) && selectedEnd.isAfter(startBooking)
    })
}

export const getMobileSuggestedScheduleHours = (
    bookings: Array<CalendarScheduleBooking>,
    scheduleTime: [string, string],
) => {
    const [start, end] = getHoursFromString(scheduleTime[0], scheduleTime[1])
    const amount = end - start

    const hoursData = Array.from({ length: amount }, (_, index) => start + index)
    let startSuggestedHour: string | null = null
    let endSuggestedHour: string | null = null

    for (const hour of hoursData) {
        const isBooked = bookings.some((booking) => {
            const startBooking = getHourFromDate(booking.startTime)
            const endBooking = getHourFromDate(booking.endTime)

            return hour >= startBooking && hour < endBooking
        })

        if (!isBooked) {
            if (startSuggestedHour === null) {
                startSuggestedHour = getHourStringFromNumber(hour)
            } else {
                endSuggestedHour = getHourStringFromNumber(hour + 1)
            }
        } else {
            if (startSuggestedHour !== null) {
                endSuggestedHour = getHourStringFromNumber(hour)
                break
            }
        }
    }

    if (startSuggestedHour === null || endSuggestedHour === null) {
        return scheduleTime
    }

    return [startSuggestedHour, endSuggestedHour] as [string, string]
}

export const getTypeBookingByReservableType = (booking: CalendarScheduleBooking, isCanSeeSubstatus = false) => {
    if (booking.bookingSubType && isCanSeeSubstatus) {
        return booking.bookingSubType
    } else if (booking.bookingType) {
        return booking.bookingType
    } else if (!booking.bookingSubType && !booking.bookingType && booking.reservable) {
        //  deprecated code
        const typeBooking = booking.reservable.type

        let type: BookingType | BookingSubType

        switch (typeBooking) {
            case EReservableType.SEAT:
                type = booking.isTeamEvent ? BookingType.TEAM_EVENT : BookingType.OFFICE
                break
            case EReservableType.ROOM:
                type = BookingType.ROOM
                break
            case EReservableType.HOME:
                type = BookingType.REMOTE
                break
            case EReservableType.BUSINESS_TRIP:
                type = BookingSubType.BUSINESS_TRIP
                break
            case EReservableType.VACATION:
                type = BookingSubType.VACATION
                break
            case EReservableType.SICK_LEAVE:
                type = BookingSubType.SICK_LEAVE
                break
            case EReservableType.AWAY:
                type = BookingType.NOT_AVAILABLE
                break
            default:
                type = BookingType.NOT_AVAILABLE
                break
        }

        if (subStatuses.includes(type)) {
            return isCanSeeSubstatus ? type : BookingType.NOT_AVAILABLE
        }

        return type
    } else {
        return BookingType.NOT_AVAILABLE
    }
}

export const getBookingTypeTitleByBooking = (booking: CalendarScheduleBooking, isAdmin = false) => {
    const type = getTypeBookingByReservableType(booking, isAdmin)

    let title = ''
    let spaceName = ''
    let seatName: string | undefined

    switch (type) {
        case BookingType.OFFICE:
        case BookingType.TEAM_EVENT: {
            if (booking.Space) {
                title = booking.Space.Office.name
                spaceName = booking.Space.name
                seatName = booking.reservable?.name
            }
            break
        }
        case BookingType.ROOM: {
            if (booking?.reservable?.Space) {
                title = booking?.reservable?.Space?.Office?.name
                spaceName = booking?.reservable?.Space?.name
                seatName = booking.reservable?.name
            }
            break
        }
        case BookingType.REMOTE: {
            title = 'Remote'
            break
        }
        case BookingType.NOT_AVAILABLE: {
            title = 'Not available'
            break
        }
        case BookingSubType.SICK_LEAVE: {
            title = 'Sick'
            break
        }
        case BookingSubType.VACATION: {
            title = 'Vacation'
            break
        }
        case BookingSubType.BUSINESS_TRIP: {
            title = 'Travel'
            break
        }
        default: {
            title = 'Not available'
            break
        }
    }

    return {
        type,
        title,
        spaceName,
        seatName,
    }
}

export const getParkingTitleByBooking = (booking: CalendarScheduleBooking) => {
    return {
        name: booking.BookingRequest?.ParkingSlot?.name,
        spaceName: booking.Space?.name,
    }
}

export const getScheduleFromBookings = (
    booking: CalendarScheduleBooking,
    officeAmount: number,
    startHourOffice: number,
) => {
    const start = getHourFromDate(booking.startTime) - startHourOffice
    const startHour = start < 0 ? 0 : start

    const [a, b, end] = getRangeHours(booking.startTime, booking.endTime)
    const hoursAmount = end > officeAmount ? officeAmount : end

    const { type } = getBookingTypeTitleByBooking(booking)

    const stringHour = getHoursFromISODateString(booking.startTime) + ' - ' + getHoursFromISODateString(booking.endTime)

    return {
        type,
        stringHour,
        startHour,
        hoursAmount,
    }
}

const getStatusesFilters = (statuses) => {
    if (!statuses) {
        return undefined
    }

    let filters: StatusesFilter = {}

    /* we can find in array statuses only REGISTRED or other status */
    statuses.forEach((status) => {
        if (status.id === EmployeeStatus.REGISTRED) {
            filters = { eq: EmployeeStatus.REGISTRED }
        } else {
            filters = { ne: EmployeeStatus.REGISTRED }
        }
    })

    return filters
}

type Condition = {
    eq?: string
    ne?: string | boolean
    wildcard?: string
    in?: string[]
}

type ConditionEntry = {
    [key: string]: Condition
}

type OrCondition = {
    or: ConditionGroup[]
}

type AndCondition = {
    and: ConditionEntry[]
}

type ConditionGroup = ConditionEntry | AndCondition | OrCondition

type Filter = {
    id?: Condition
    not?: {
        id?: Condition
    }
    active?: Condition
    and?: ConditionGroup[]
    or?: ConditionGroup[]
}

type AndFilterArray = Filter[]

export const setRequestVariablesInSearchEmployees = ({
    weekNumber,
    year,
    startWeekDate,
    employeeId,
    favouriteOfficeId,
    filterByNameAndEmail,
    departmentData,
    officeData,
    limit,
    nextToken,
    statuses,
    companyID,
    isManager,
    date,
    excludedEmployeeIDs,
}: {
    filterByNameAndEmail?: string
    limit?: number
    nextToken?: NextToken
    companyID?: string
    officeData?: Array<MultiselectItem>
    departmentData?: Array<MultiselectItem>
    isManager?: boolean
    date?: string
    excludedEmployeeIDs?: string[]
    weekNumber?: number
    year?: number
    startWeekDate?: string
    employeeId: string
    favouriteOfficeId?: string
    statuses?: Array<MultiselectItem>
}) => {
    let and: AndFilterArray = [
        { id: { ne: employeeId } },
        ...(excludedEmployeeIDs || []).map((id) => ({ id: { ne: id } })),
        { active: { ne: false } },
    ]

    if (departmentData) {
        and = [
            ...and,
            {
                or: departmentData.map((item) => {
                    const departmentId = getSelectItemKey(item, 'id')
                    return { departmentIDsString: { wildcard: `*${departmentId.replace(/-/g, '')}*` } }
                }),
            },
        ]
    }

    if (officeData) {
        and = [...and, { or: officeData.map((item) => ({ favouriteOfficeID: { eq: getSelectItemKey(item, 'id') } })) }]
    }

    if (filterByNameAndEmail) {
        const searchString = filterByNameAndEmail.toLowerCase().replace('@', '').split(' ')
        and = [
            ...and,
            {
                or: [
                    { and: searchString.map((text) => ({ fullNameLowerCase: { wildcard: `*${text}*` } })) },
                    { and: searchString.map((text) => ({ emailForSearch: { wildcard: `*${text}*` } })) },
                ],
            },
        ]
    }

    let filterByStatuses = undefined as MultiselectItem[] | undefined

    if (!isManager) {
        filterByStatuses = [statusesList[0]]
    } else if (!filterByNameAndEmail) {
        filterByStatuses = statuses || undefined
    }

    return {
        weekNumber,
        date,
        year: year || moment(startWeekDate).year(),
        limit: limit || LimitByDepartment,
        nextToken,
        filter: {
            companyID: { eq: companyID },
            favouriteOfficeID: !favouriteOfficeId ? undefined : { eq: favouriteOfficeId },
            statusString: !filterByStatuses ? undefined : getStatusesFilters(filterByStatuses),
            and,
        },
    }
}

export const setRequestVariablesInSearchFavoritesColleagues = ({
    filterByNameAndEmail,
    weekNumber,
    year,
    startWeekDate,
    employeeId,
    limit,
    nextToken,
    companyID,
    date,
    myFavouriteColleagueIDs,
    excludedEmployeeIDs,
}: {
    filterByNameAndEmail?: string
    limit?: number
    nextToken?: NextToken
    companyID?: string
    officeData?: Array<MultiselectItem>
    departmentData?: Array<MultiselectItem>
    isManager?: boolean
    date?: string
    myFavouriteColleagueIDs?: string[]
    weekNumber?: number
    year?: number
    startWeekDate?: string
    employeeId: string
    favouriteOfficeId?: string
    statuses?: Array<MultiselectItem>
    excludedEmployeeIDs?: string[]
}) => {
    let and: AndFilterArray = [
        { id: { ne: employeeId } },
        ...(excludedEmployeeIDs || []).map((id) => ({ id: { ne: id } })),
        { or: (myFavouriteColleagueIDs || []).map((id) => ({ id: { eq: id } })) },
        { active: { ne: false } },
    ]

    if (filterByNameAndEmail) {
        const searchString = filterByNameAndEmail.toLowerCase().replace('@', '').split(' ')
        and = [
            ...and,
            {
                or: [
                    { and: searchString.map((text) => ({ fullNameLowerCase: { wildcard: `*${text}*` } })) },
                    { and: searchString.map((text) => ({ emailForSearch: { wildcard: `*${text}*` } })) },
                ],
            },
        ]
    }

    return {
        weekNumber,
        date,
        year: year || moment(startWeekDate).year(),
        limit: limit || LimitByDepartment,
        nextToken,
        filter: {
            companyID: { eq: companyID },
            statusString: { eq: EmployeeStatus.REGISTRED },
            and,
        },
    }
}

export const getCheckFullCalendarTime = (
    bookings: Array<CalendarScheduleBooking>,
    scheduleTime: [string, string],
): boolean => {
    const scheduleMinutes = getRangeMinutes(scheduleTime[0], scheduleTime[1])

    let bookingsMinutes = 0

    for (const booking of bookings) {
        const startTime = getHoursFromISODateString(booking.startTime)
        const endTime = getHoursFromISODateString(booking.endTime)

        bookingsMinutes += getRangeMinutes(startTime, endTime)
    }

    return bookingsMinutes >= scheduleMinutes
}

export const getMobileSuggestedCalendarTime = (
    bookings: Array<CalendarScheduleBooking>,
    scheduleTime: [string, string],
    availableHours: [string, string],
): [string, string] => {
    let startSuggestedHour = availableHours[0]
    let endSuggestedHour = availableHours[1]

    if (!getCheckFullCalendarTime(bookings, availableHours)) {
        for (const booking of bookings) {
            const startTime = getHoursFromISODateString(booking.startTime)
            const endTime = getHoursFromISODateString(booking.endTime)

            if (startTime > startSuggestedHour) {
                endSuggestedHour = startTime
                break
            }

            if (endTime < availableHours[1]) {
                startSuggestedHour = endTime
            }
        }
    }

    return [startSuggestedHour, endSuggestedHour]
}

export const getStringTimeByBooking = (booking: CalendarScheduleBooking) =>
    getHoursFromISODateString(booking.startTime) + ' - ' + getHoursFromISODateString(booking.endTime)

export const getAwsTimeFromString = (date: string, time: string) => `${date}T${time}:00.000Z`
