import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { orderBy } from 'lodash'
import { createSelector, createStructuredSelector } from 'reselect'

import { Department } from '@graphql/autogenerate/schemas'
import { QUERY_LIST_DEPARTMENTS, QUERY_LIST_SPACES_ALL, QUERY_LIST_POSITIONS } from '@graphql/queries'
import {
    SUBSCRIPTION_CREATE_DEPARTMENT,
    SUBSCRIPTION_CREATE_POSITION,
    SUBSCRIPTION_CREATE_RESERVABLE,
    SUBSCRIPTION_CREATE_SPACE,
    SUBSCRIPTION_DELETE_DEPARTMENT,
    SUBSCRIPTION_DELETE_POSITION,
    SUBSCRIPTION_DELETE_RESERVABLE,
    SUBSCRIPTION_DELETE_SPACE,
    SUBSCRIPTION_UPDATE_DEPARTMENT,
    SUBSCRIPTION_UPDATE_POSITION,
    SUBSCRIPTION_UPDATE_RESERVABLE,
    SUBSCRIPTION_UPDATE_SPACE,
} from '@graphql/subscriptions'
import { useAuth } from 'context/auth'
import { useReconnectingSubscription } from 'helpers/useReconnectingSubscription'
import { useAppDispatch, useAppSelector } from 'hooks'
import { RootState } from 'store'
import {
    setSpaces as setSpacesAction,
    setDepartments as setDepartmentsAction,
    setPositions as setPositionsAction,
} from 'store/slices/company'
import { messageActions } from 'store/slices/message'
import { TDepartment, TPosition, TReservable, TSpace } from 'types/data.types'

const memoizedEmployee = createStructuredSelector(
    {
        departmentIDs: (state: RootState) => state.user.departmentIDs,
        id: (state: RootState) => state.user.id,
    },
    createSelector,
)

const combineData = createStructuredSelector(
    {
        spaces: (state: RootState) => state.company.spaces,
        departments: (state: RootState) => state.company.departments,
        positions: (state: RootState) => state.company.positions,
    },
    createSelector,
)

export const useData = () => {
    const { userAuth, signOut } = useAuth()
    const dispatch = useAppDispatch()

    const [skipSpaces, setSkipSpaces] = useState(false)
    const [skipDepartments, setSkipDepartments] = useState(false)
    const [skipPositions, setSkipPositions] = useState(false)

    const setSpaces = (spaces: Array<TSpace>) => {
        dispatch(setSpacesAction(spaces))
    }

    const setDepartments = (departments: Array<TDepartment | Department>) => {
        dispatch(setDepartmentsAction(departments))
    }

    const setPositions = (positions: Array<TPosition>) => {
        dispatch(setPositionsAction(positions))
    }

    const { spaces, departments, positions } = useAppSelector(combineData)

    const employee = useAppSelector(memoizedEmployee)

    const { data: dataDepartments, refetch: reloadDepartments } = useQuery<{
        listDepartmentsByCompanyAndName: { items: Array<Department> }
    }>(QUERY_LIST_DEPARTMENTS, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        errorPolicy: 'ignore',
        skip: skipDepartments || !userAuth?.companyId,
    })

    const { data: dataPositions } = useQuery<{ listPositionsByCompanyAndName: { items: Array<TPosition> } }>(
        QUERY_LIST_POSITIONS,
        {
            variables: {
                companyID: userAuth?.companyId || '',
            },
            skip: skipPositions || !userAuth?.companyId,
        },
    )

    useQuery<{ listSpacesByCompanyAndName: { items: Array<TSpace> } }>(QUERY_LIST_SPACES_ALL, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: skipSpaces,
        errorPolicy: 'ignore',
        onCompleted: (data) => {
            setSkipSpaces(true)
            setSpaces(data.listSpacesByCompanyAndName.items.filter((item) => item.Office !== null))
            if (data.listSpacesByCompanyAndName.items.length === 0) {
                dispatch(
                    messageActions.messageShown({
                        text: 'No spaces found. Please create an space to continue.',
                        severity: 'warning',
                    }),
                )
            }
        },
    })

    //
    // ---- Subscriptions DEPARTMENTS----
    //
    const { data: dataCreateDepartment } = useReconnectingSubscription<
        { onCreateDepartment: TDepartment },
        { companyID: string }
    >(SUBSCRIPTION_CREATE_DEPARTMENT, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })

    const { data: dataUpdateDepartment } = useReconnectingSubscription<
        { onUpdateDepartment: TDepartment },
        { companyID: string }
    >(SUBSCRIPTION_UPDATE_DEPARTMENT, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    const { data: dataDeleteDepartment } = useReconnectingSubscription<
        { onDeleteDepartment: TDepartment },
        { companyID: string }
    >(SUBSCRIPTION_DELETE_DEPARTMENT, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    //
    // ---- Subscriptions POSITIONS----
    //
    const { data: dataCreatePosition } = useReconnectingSubscription<
        { onCreatePosition: TPosition },
        { companyID: string }
    >(SUBSCRIPTION_CREATE_POSITION, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    const { data: dataUpdatePosition } = useReconnectingSubscription<
        { onUpdatePosition: TPosition },
        { companyID: string }
    >(SUBSCRIPTION_UPDATE_POSITION, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    const { data: dataDeletePosition } = useReconnectingSubscription<
        { onDeletePosition: TPosition },
        { companyID: string }
    >(SUBSCRIPTION_DELETE_POSITION, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    //
    // ---- Subscriptions Spaces----
    //
    const { data: dataCreateSpace } = useReconnectingSubscription<{ onCreateSpace: TSpace }, { companyID: string }>(
        SUBSCRIPTION_CREATE_SPACE,
        {
            variables: {
                companyID: userAuth?.companyId || '',
            },
            skip: !userAuth?.companyId,
        },
    )
    const { data: dataUpdateSpace } = useReconnectingSubscription<{ onUpdateSpace: TSpace }, { companyID: string }>(
        SUBSCRIPTION_UPDATE_SPACE,
        {
            variables: {
                companyID: userAuth?.companyId || '',
            },
            skip: !userAuth?.companyId,
        },
    )
    const { data: dataDeleteSpace } = useReconnectingSubscription<{ onDeleteSpace: TSpace }, { companyID: string }>(
        SUBSCRIPTION_DELETE_SPACE,
        {
            variables: {
                companyID: userAuth?.companyId || '',
            },
            skip: !userAuth?.companyId,
            onData: () => dispatch(messageActions.messageShown({ text: 'Office has deleted!', severity: 'success' })),
        },
    )

    //
    // ---- Subscriptions Reservables----
    //
    const { data: dataCreateReservable } = useReconnectingSubscription<
        { onCreateReservable: TReservable },
        { companyID: string }
    >(SUBSCRIPTION_CREATE_RESERVABLE, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })

    const { data: dataUpdateReservable } = useReconnectingSubscription<
        { onUpdateReservable: TReservable },
        { companyID: string }
    >(SUBSCRIPTION_UPDATE_RESERVABLE, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })

    const { data: dataDeleteReservable } = useReconnectingSubscription<
        { onDeleteReservable: TReservable },
        { companyID: string }
    >(SUBSCRIPTION_DELETE_RESERVABLE, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })

    //
    // ---- useEffects Departments----
    //
    useEffect(() => {
        if (dataDepartments) {
            setSkipDepartments(true)
            setDepartments(dataDepartments.listDepartmentsByCompanyAndName.items)
        }
    }, [dataDepartments])

    useEffect(() => {
        if (dataCreateDepartment) {
            setDepartments(orderBy([...(departments || []), dataCreateDepartment.onCreateDepartment], 'name'))
        }
    }, [dataCreateDepartment])

    useEffect(() => {
        if (dataUpdateDepartment) {
            setDepartments(
                orderBy(
                    (departments || []).map((dep) =>
                        dep.id === dataUpdateDepartment.onUpdateDepartment.id
                            ? dataUpdateDepartment.onUpdateDepartment
                            : dep,
                    ),
                    'name',
                ),
            )
        }
    }, [dataUpdateDepartment])

    useEffect(() => {
        if (dataDeleteDepartment) {
            setDepartments((departments || []).filter((dep) => dep.id !== dataDeleteDepartment.onDeleteDepartment.id))
        }
    }, [dataDeleteDepartment])

    //
    // ---- UseEfects Positions----
    //
    useEffect(() => {
        if (dataPositions) {
            setSkipPositions(true)
            setPositions(dataPositions.listPositionsByCompanyAndName.items)
        }
    }, [dataPositions])

    useEffect(() => {
        if (dataCreatePosition) {
            setPositions(orderBy([...positions, dataCreatePosition.onCreatePosition], 'name'))
        }
    }, [dataCreatePosition])

    useEffect(() => {
        if (dataUpdatePosition) {
            setPositions(
                orderBy(
                    positions.map((pos) =>
                        pos.id === dataUpdatePosition.onUpdatePosition.id ? dataUpdatePosition.onUpdatePosition : pos,
                    ),
                    'name',
                ),
            )
        }
    }, [dataUpdatePosition])

    useEffect(() => {
        if (dataDeletePosition) {
            setPositions(positions.filter((pos) => pos.id !== dataDeletePosition.onDeletePosition.id))
        }
    }, [dataDeletePosition])

    //
    // ---- useEffects Spaces----
    //

    useEffect(() => {
        if (dataCreateSpace) {
            setSpaces(orderBy([...spaces, dataCreateSpace.onCreateSpace], 'name'))
        }
    }, [dataCreateSpace])

    useEffect(() => {
        if (dataUpdateSpace) {
            setSpaces(
                orderBy(
                    spaces.map((space) =>
                        space.id === dataUpdateSpace.onUpdateSpace.id ? dataUpdateSpace.onUpdateSpace : space,
                    ),
                    'name',
                ),
            )
        }
    }, [dataUpdateSpace])

    useEffect(() => {
        if (dataDeleteSpace) {
            setSpaces(spaces.filter((space) => space.id !== dataDeleteSpace.onDeleteSpace?.id))
        }
    }, [dataDeleteSpace])

    //
    // ---- useEffects Reservable ----
    //

    useEffect(() => {
        const reservable = dataCreateReservable?.onCreateReservable
        if (reservable) {
            setSpaces(
                spaces.map(
                    (space): TSpace =>
                        space.id === reservable.spaceID &&
                        !space.Reservables?.items.find((item) => item.id === reservable.id)
                            ? {
                                  ...space,
                                  Reservables: {
                                      items: space.Reservables
                                          ? [...space.Reservables.items, reservable]
                                          : [reservable],
                                  },
                              }
                            : space,
                ),
            )
        }
    }, [dataCreateReservable])

    useEffect(() => {
        if (dataUpdateReservable) {
            setSpaces(
                spaces.map(
                    (space): TSpace =>
                        space.id === dataUpdateReservable.onUpdateReservable.spaceID
                            ? {
                                  ...space,
                                  Reservables: {
                                      items: space.Reservables
                                          ? space.Reservables?.items.map((res) =>
                                                res.id === dataUpdateReservable.onUpdateReservable.id
                                                    ? dataUpdateReservable.onUpdateReservable
                                                    : res,
                                            )
                                          : [],
                                  },
                              }
                            : space,
                ),
            )
        }
    }, [dataUpdateReservable])

    useEffect(() => {
        if (dataDeleteReservable) {
            setSpaces(
                spaces.map(
                    (space): TSpace =>
                        space.id === dataDeleteReservable.onDeleteReservable.spaceID
                            ? {
                                  ...space,
                                  Reservables: {
                                      items: space.Reservables
                                          ? space.Reservables.items.filter(
                                                (res) => res.id !== dataDeleteReservable.onDeleteReservable.id,
                                            )
                                          : [],
                                  },
                              }
                            : space,
                ),
            )
        }
    }, [dataDeleteReservable])

    useEffect(() => {
        reloadDepartments().finally()
    }, [userAuth?.companyId, employee?.departmentIDs])

    return {
        spaces,
        departments,
        positions,
    }
}
