import { WebSocketLink } from '@apollo/client/link/ws'

import { UUIDOperationIdSubscriptionClient } from './UUIDOperationIdSubscriptionClient'
import { createAppSyncGraphQLOperationAdapter } from './appSyncGraphQLOperationAdapter'

const APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS = 5 * 60 * 1000

export const createAppSyncSubscriptionWebsocketLink = ({ appSyncApiUrl, getJwtToken }) => {
    const appSyncApiHost = new URL(appSyncApiUrl).host
    const getAppSyncAuthorizationInfo = async () => ({
        host: appSyncApiHost,
        Authorization: await getJwtToken(),
    })

    const wsLink = new WebSocketLink(
        new UUIDOperationIdSubscriptionClient(
            `wss://${appSyncApiHost.replace('appsync-api', 'appsync-realtime-api')}/graphql`,
            {
                timeout: APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS,
                reconnect: true,
                lazy: true,
                connectionCallback(error, result) {
                    if (error) {
                        console.log('WS-CONNECTION-CALLBACK-ERROR', error)
                    }
                },
            },
            // We want to avoid expired authorization information being used but SubscriptionClient synchronously
            // instantiates websockets (on connection/reconnection) so the best we can do is schedule an async refresh
            // and suffer failed connection attempts until a fresh token has been retrieved
            getAppSyncAuthorizationInfo,
            // @ts-ignore
        ).use([createAppSyncGraphQLOperationAdapter(getAppSyncAuthorizationInfo)]),
    )

    return wsLink
}
