import { createContext, useContext } from 'use-context-selector'

import { noop } from 'helpers'
import { TARIFF } from 'types/billing.types'
import { TCompany, TDepartment, TPosition, TSpace } from 'types/data.types'

export const CompanyContext = createContext<{
    loading?: boolean
    company?: TCompany
    update: () => void
    spaces: TSpace[]
    departments: TDepartment[] | null
    positions: TPosition[]
    changeTariff: (tariff: TARIFF) => void
}>({
    update: noop,
    spaces: [],
    departments: [],
    positions: [],
    changeTariff: noop,
})

export const useCompanyData = () => useContext(CompanyContext)
