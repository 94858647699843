import * as React from "react";
import { forwardRef } from "react";
const SvgSendPassword = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Icons", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 32 32", style: {
  enableBackground: "new 0 0 32 32"
}, xmlSpace: "preserve", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M21,8.2V5c0-2.8-2.2-5-5-5s-5,2.2-5,5v3.2C9.8,8.6,9,9.7,9,11v4c0,1.7,1.3,3,3,3h8c1.7,0,3-1.3,3-3v-4 C23,9.7,22.2,8.6,21,8.2z M17,14c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2c0-0.6,0.4-1,1-1s1,0.4,1,1V14z M19,8h-6V5c0-1.7,1.3-3,3-3 s3,1.3,3,3V8z" }), /* @__PURE__ */ React.createElement("path", { d: "M18.5,23.1L17,23.6V22c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.6l-1.5-0.5c-0.5-0.2-1.1,0.1-1.3,0.6c-0.2,0.5,0.1,1.1,0.6,1.3l1.5,0.5 l-1,1.3c-0.3,0.4-0.2,1.1,0.2,1.4c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4l1-1.3l1,1.3c0.2,0.3,0.5,0.4,0.8,0.4 c0.2,0,0.4-0.1,0.6-0.2c0.4-0.3,0.5-1,0.2-1.4l-1-1.3l1.5-0.5c0.5-0.2,0.8-0.7,0.6-1.3C19.6,23.2,19.1,23,18.5,23.1z" }), /* @__PURE__ */ React.createElement("path", { d: "M29.8,23.8c-0.2-0.5-0.7-0.8-1.3-0.6L27,23.6V22c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.6l-1.5-0.5c-0.5-0.2-1.1,0.1-1.3,0.6 c-0.2,0.5,0.1,1.1,0.6,1.3l1.5,0.5l-1,1.3c-0.3,0.4-0.2,1.1,0.2,1.4c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4l1-1.3l1,1.3 c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2c0.4-0.3,0.5-1,0.2-1.4l-1-1.3l1.5-0.5C29.7,24.9,30,24.3,29.8,23.8z" }), /* @__PURE__ */ React.createElement("path", { d: "M8.5,23.1L7,23.6V22c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.6l-1.5-0.5c-0.5-0.2-1.1,0.1-1.3,0.6C2,24.3,2.3,24.9,2.8,25l1.5,0.5 l-1,1.3c-0.3,0.4-0.2,1.1,0.2,1.4c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4l1-1.3L7,28c0.2,0.3,0.5,0.4,0.8,0.4 c0.2,0,0.4-0.1,0.6-0.2c0.4-0.3,0.5-1,0.2-1.4l-1-1.3L9.2,25c0.5-0.2,0.8-0.7,0.6-1.3C9.6,23.2,9.1,23,8.5,23.1z" }));
const ForwardRef = forwardRef(SvgSendPassword);
export default ForwardRef;
