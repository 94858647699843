import styled, { css } from 'styled-components'

export const ImportUsers = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 12px 32px;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${(props) => props.theme.color.secondaryWhite};
    height: 100%;
    //width: 100%;
    border-radius: 24px;
    padding: 32px 16px;
    position: relative;
`

export const Header = styled.div`
    display: flex;
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
    color: ${(props) => props.theme.color.black};
    opacity: 0.8;
    margin-left: 12px;
    margin-bottom: 24px;
`

export const Divider = styled.div`
    width: 1px;
    background-color: rgba(51, 51, 51, 0.25);
    opacity: 0.5;
    margin: 24px 54px;
    display: flex;
    align-items: center;
    position: relative;

    & svg {
        position: absolute;
        left: -10px;
        margin: 30px 0;
        z-index: 10;
        background: rgb(227, 232, 238);
        height: 44px;
    }
`

export const IconStyle = {
    color: '#333333',
    width: 18,
    height: 18,
}

export const SearchBox = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    background-color: rgba(255, 255, 255, 0.9);
    height: 48px;
    border-radius: 16px;
    padding: 0 8px;
`

export const DropDownContainer = styled.div<{ disabled?: boolean; isOpen: boolean }>`
    font-size: 12px;
    color: #333333;
    cursor: pointer;
    position: relative;
    display: flex;

    ${(props) =>
        props.disabled &&
        css`
            color: rgba(51, 51, 51, 0.5);
            pointer-events: none;
        `}

    .filters-box {
        opacity: ${(props) => (props.isOpen ? 1 : 0)};
        visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
    }
`

export const DropDownInterfaceWrapper = styled.div`
    display: flex;
    margin: auto;
    align-items: center;
    height: 32px;
    line-height: 18px;
`

export const ArrowWrapper = styled.div<{ disabled?: boolean }>`
    width: 24px;
    height: 24px;
    border-radius: 6px;
    position: relative;

    svg {
        fill-opacity: 0.5;
    }

    ${(props) =>
        props.disabled &&
        css`
            svg {
                fill: rgba(51, 51, 51, 0.2);
            }
            pointer-events: none;
        `}

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }
`

export const ListUsersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow-y: hidden;
`

export const ListUsers = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;

    .simplebar-track.simplebar-vertical {
        right: -10px;
    }
`

export const UserItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 71px;
    padding: 14px 0;
    cursor: pointer;
    transition: all 0.3s;

    &:not(:last-child) {
        border-bottom: 1px solid rgba(51, 51, 51, 0.05);
    }
`

export const UserInfo = styled.div`
    overflow: hidden;
`

export const UserName = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-left: 8px;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const UserEmail = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: rgba(51, 51, 51, 0.5);
    margin-left: 8px;
    text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
`

const ActionIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 10px;
    margin-left: 8px;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 16px;
`

export const PlusIcon = styled(ActionIcon)`
    border: 1px ${(props) => props.theme.color.activeSpacePanel} solid;
    color: ${(props) => props.theme.color.activeSpacePanel};

    &:hover {
        background-color: rgba(51, 51, 51, 0.2);
    }
`

export const MinusIcon = styled(ActionIcon)`
    border: 1px ${(props) => props.theme.color.coral} solid;
    color: ${(props) => props.theme.color.coral};

    &:hover {
        background-color: rgba(51, 51, 51, 0.2);
    }
`

export const Text = styled.p`
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    opacity: 0.5;
`

export const WizardContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`
