import * as React from "react";
import { forwardRef } from "react";
const SvgGuestAvatar = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { width: 36, height: 36, viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 18, cy: 18, r: 18, fill: "#333333", fillOpacity: 0.1, style: {
  fill: "#333333",
  fill: "color(display-p3 0.2000 0.2000 0.2000)",
  fillOpacity: 0.1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21.3333 13.8333C21.3333 15.675 19.8416 17.1667 17.9999 17.1667C16.1583 17.1667 14.6666 15.675 14.6666 13.8333C14.6666 11.9917 16.1583 10.5 17.9999 10.5C19.8416 10.5 21.3333 11.9917 21.3333 13.8333ZM24.6362 23.6319C24.6362 23.6319 24.7449 23.2193 24.5511 22.3097C24.202 20.6711 22.8301 19.3902 21.075 19.0643C20.2295 18.9073 19.1098 18.8327 17.9998 18.8333C16.8899 18.8327 15.7704 18.9073 14.9248 19.0643C13.1697 19.3902 11.7978 20.6711 11.4487 22.3097C11.2549 23.2193 11.3637 23.6319 11.3637 23.6319C11.3637 24.8469 14.6816 25.4544 17.9998 25.5C21.318 25.4544 24.6362 24.8469 24.6362 23.6319Z", fill: "#333333", fillOpacity: 0.5, style: {
  fill: "#333333",
  fill: "color(display-p3 0.2000 0.2000 0.2000)",
  fillOpacity: 0.5
} }));
const ForwardRef = forwardRef(SvgGuestAvatar);
export default ForwardRef;
