import React, { FC, useEffect, useReducer } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import orderBy from 'lodash/orderBy'
import { useNavigate, useLocation } from 'react-router-dom'

import { MUTATION_START_TRIAL } from '@graphql/mutations'
import {
    QUERY_CHECKOUT_PAYLINK_URL,
    QUERY_GET_SUBSCRIPTIONS_FOR_COMPANY,
    QUERY_LIST_SUBSCRIPTIONS_PLANS,
} from '@graphql/queries'
import { SUBSCRIPTION_BILLING_UPDATE, SUBSCRIPTION_COMPANY_TARIFF_UPDATE } from '@graphql/subscriptions'
import PaddleCheckout from 'components/atoms/PaddleCheckout'
import SubscribePaywall from 'containers/Settings/components/Billing/components/SubscribePaywall'
import { useCustomAlert } from 'context/alert/alertContext'
import { useReconnectingSubscription } from 'helpers/useReconnectingSubscription'
import { CheckoutReturnUrl } from 'shared/constants'
import { BillingSubscriptionStatus, SubscriptionsPlan, SubscriptionsPlanForCompany, TARIFF } from 'types/billing.types'
import { MANAGER_ROUTES, ROUTES } from 'types/routes'
import { EUserGroup } from 'types/user.types'

import { BillingContext } from './billing'
import { defaultCheckout, initialState, billingReducer, billingActions } from './billing.actions'
import { CheckoutDataType, CommonPlanType } from './billing.context.types'
import { TrialAlertData } from './billing_content'

const subscriptionsPlanId = (import.meta.env.VITE_PADDLE_PLAN_IDS || '').split(',')
const deprecatedSubscriptionsPlanId = (import.meta.env.VITE_PADDLE_PLAN_DEPRECATED_IDS || '').split(',')

const dataAlertAfterUpdateSubscription = {
    title: 'Your subscription has been\n successfully activated!',
    hasIcon: true,
    btnType: 'Continue',
}

type Props = {
    userGroup: EUserGroup
    tariff: TARIFF
    companyId: string
    email: string
    refreshJWTToken: () => Promise<void>
    changeTariff: (tariff: TARIFF) => void
    children: React.ReactNode
}

export const BillingProviderCTX: FC<Props> = ({
    children,
    userGroup,
    tariff,
    companyId,
    email,
    refreshJWTToken,
    changeTariff,
}) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const { changeAlertData } = useCustomAlert()

    const [{ checkout, isOpenPaywall, plansForCompany, subPlans, currentPlan, planStatus }, dispatch] = useReducer(
        billingReducer,
        initialState,
    )

    const { refetch: getUrlForCheckout } = useQuery<
        { getPaylinkUrlForCheckout: { url: string } },
        { planID: number; returnUrl: string }
    >(QUERY_CHECKOUT_PAYLINK_URL, {
        skip: true,
        onError: (err) => {
            console.log('ERROR: checkout url', err)
        },
    })

    useQuery<{ listSubscriptionsPlans: { plans: Array<SubscriptionsPlan> } }>(QUERY_LIST_SUBSCRIPTIONS_PLANS, {
        skip: subPlans.length > 0,
        onCompleted: ({ listSubscriptionsPlans: { plans } }) => {
            dispatch(
                billingActions.getSubscriptionPlans(
                    orderBy(
                        plans
                            .filter(
                                (plan) =>
                                    subscriptionsPlanId.includes(plan.id) ||
                                    plansForCompany.find((planCompany) => planCompany.subscriptionPlanId === plan.id),
                            )
                            .sort(
                                (a, b) =>
                                    Number(a?.recurringPrice?.[0]?.amount) - Number(b?.recurringPrice?.[0].amount),
                            ),
                        'name',
                        'desc',
                    ),
                ),
            )
        },
        onError: (err) => {
            console.log('ERROR: list of subscription plans', err)
        },
    })

    const { refetch } = useQuery<{ getSubscriptionsForCompany: { items: Array<SubscriptionsPlanForCompany> } }>(
        QUERY_GET_SUBSCRIPTIONS_FOR_COMPANY,
        {
            skip: plansForCompany.length > 0,
            onCompleted: ({ getSubscriptionsForCompany: { items } }) => {
                if (items.length > 0) {
                    const sortedPlans = orderBy(items, 'subscribedAt', 'desc')
                    const currPlan =
                        (sortedPlans.find((plan) =>
                            subscriptionsPlanId.concat(deprecatedSubscriptionsPlanId).includes(plan.subscriptionPlanId),
                        ) as CommonPlanType) || null
                    dispatch(billingActions.setPlansForCompany({ plans: sortedPlans, currPlan }))
                }
            },
            onError: (err) => {
                console.log('ERROR: list of subscriptions for company', err)
            },
        },
    )

    const [startTrial] = useMutation<{ startTrial: string }, { subscriptionPlanId: string }>(MUTATION_START_TRIAL, {
        onCompleted: async () => {
            !isOpenPaywall && changeAlertData(TrialAlertData)
        },
        onError: (err) => {
            console.log('ERROR: start trial', err)
        },
    })

    useReconnectingSubscription<
        { onCompanyTariffUpdate: { companyId: string; tariff: TARIFF } },
        { companyId: string }
    >(SUBSCRIPTION_COMPANY_TARIFF_UPDATE, {
        variables: { companyId },
        onData: async ({ data: { data } }) => {
            if (data && data.onCompanyTariffUpdate.companyId === companyId) {
                changeTariff(data.onCompanyTariffUpdate.tariff)
                await refreshJWTToken()

                const plansData = await refetch()

                if (plansData !== undefined) {
                    const plan = orderBy(plansData.data.getSubscriptionsForCompany.items, 'subscribedAt', 'desc')[0]
                    updateSubscriptionPlan(plan, data.onCompanyTariffUpdate.tariff)
                }
            }
        },
    })

    useReconnectingSubscription<{ onUpdateBillingSubscription: SubscriptionsPlanForCompany }, { companyId: string }>(
        SUBSCRIPTION_BILLING_UPDATE,
        {
            variables: { companyId },
            onData: ({ data: { data } }) => {
                if (data === undefined) {
                    return
                }
                updateSubscriptionPlan(data.onUpdateBillingSubscription)
            },
        },
    )

    useEffect(() => {
        if (isOpenPaywall && tariff === TARIFF.TRIAL) {
            changeAlertData(TrialAlertData)
            dispatch(billingActions.changePaywallDisplay(false))
        }
    }, [isOpenPaywall, tariff])

    useEffect(() => {
        if (tariff === TARIFF.TRIAL_EXPIRED || tariff === TARIFF.SUBSCRIPTION_EXPIRED) {
            if (userGroup === EUserGroup.MANAGER && pathname !== MANAGER_ROUTES.SUBSCRIPTIONS) {
                navigate(MANAGER_ROUTES.SUBSCRIPTIONS)
                localStorage.setItem('page_before_subscriptions', pathname)
            }
        }
    }, [tariff, userGroup, pathname])

    function updateSubscriptionPlan(newestPlan: SubscriptionsPlanForCompany, currentTariff?: TARIFF) {
        if (newestPlan === undefined) {
            dispatch(billingActions.setPlansForCompany({ plans: [], currPlan: null }))
            return
        }

        const subPlansForCompanyCopy = [...plansForCompany]
        const prevPlanIndex = subPlansForCompanyCopy.findIndex(
            (plan) => plan.subscriptionPlanId === newestPlan.subscriptionPlanId,
        )

        if (prevPlanIndex !== -1) {
            subPlansForCompanyCopy[prevPlanIndex] = newestPlan
            dispatch(billingActions.setPlansForCompany({ plans: subPlansForCompanyCopy, currPlan: newestPlan }))
        } else {
            dispatch(
                billingActions.setPlansForCompany({
                    plans: [newestPlan, ...subPlansForCompanyCopy],
                    currPlan: newestPlan,
                }),
            )
        }

        if (newestPlan.status === 'ACTIVE') {
            if (currentTariff === TARIFF.STANDARD && pathname === MANAGER_ROUTES.SUBSCRIPTIONS) {
                const prevPage = localStorage.getItem('page_before_subscriptions') || ROUTES.HOME
                //MANAGER_ROUTES.DASHBOARD
                navigate(prevPage === ROUTES.HOME ? ROUTES.CALENDAR : prevPage)
            }

            if (newestPlan.subscriptionId === (currentPlan as SubscriptionsPlanForCompany)?.subscriptionId) {
                return
            }
            changeAlertData(dataAlertAfterUpdateSubscription)
        }
    }

    const changePlan = async (subPlanId: string, status: BillingSubscriptionStatus | null) => {
        const subscribedPlan = plansForCompany.find((item) => item.subscriptionPlanId === subPlanId)
        const newPlanStatus = { planId: subPlanId, loader: true }

        switch (status) {
            case 'ACTIVE':
                if (!subscribedPlan) {
                    return
                }
                dispatch(
                    billingActions.changeCheckoutWithPlanStatus({
                        checkout: {
                            url: (subscribedPlan as SubscriptionsPlanForCompany).cancelUrl,
                            type: 'cancel',
                        },
                        planStatus: newPlanStatus,
                    }),
                )
                break
            case 'TRIAL':
            case 'EXTERNAL_CANCELLED':
            case 'TRIAL_EXPIRED':
                const { data: dataCheckout } = await getUrlForCheckout({
                    planID: Number(subPlanId),
                    returnUrl: CheckoutReturnUrl,
                })

                dispatch(
                    billingActions.changeCheckoutWithPlanStatus({
                        checkout: {
                            url: dataCheckout ? dataCheckout.getPaylinkUrlForCheckout.url : null,
                            type: 'pay',
                        },
                        planStatus: newPlanStatus,
                    }),
                )
                break
            default:
                dispatch(billingActions.changePlanStatus(newPlanStatus))
                await startTrial({ variables: { subscriptionPlanId: subPlanId } })
                break
        }
    }

    const changeCheckoutUrl = (data: CheckoutDataType) => dispatch(billingActions.changeCheckout(data))

    const checkoutHandler = (cancel?: boolean) => {
        if (cancel) {
            return dispatch(billingActions.changeCheckout(defaultCheckout))
        }
    }

    const changeWindow = (open: boolean) => dispatch(billingActions.changePaywallDisplay(open))

    return (
        <BillingContext.Provider
            value={{
                checkout,
                currentPlan,
                plansForCompany,
                subPlans,
                planStatus,
                changePlan,
                changeCheckoutUrl,
                changeWindow,
            }}
        >
            {children}
            {checkout.url !== null && (
                <PaddleCheckout email={email} override={checkout.url} type={checkout.type} callback={checkoutHandler} />
            )}
            <SubscribePaywall
                open={isOpenPaywall}
                onClose={() => changeWindow(false)}
                subPlans={subPlans}
                planStatus={planStatus}
                changePlan={changePlan}
                plansForCompany={plansForCompany}
                userTariff={tariff}
            />
        </BillingContext.Provider>
    )
}

BillingProviderCTX.displayName = 'BillingProviderCTX'
