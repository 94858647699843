import { FC, useCallback } from 'react'

import SimpleBar from 'simplebar-react'

// import { useLoadUsers } from './useLoadUsers'
import * as S from '../../styles'
import { MicrosoftUser } from '../../types'

type YoffixUsersProps = {
    importedUsers: MicrosoftUser[]
    removeUser: (user: MicrosoftUser) => void
}
export const YoffixUsers: FC<YoffixUsersProps> = ({ importedUsers, removeUser }) => {
    const removeUserHandler = useCallback(
        (user) => {
            removeUser(user)
        },
        [removeUser],
    )

    return (
        <S.Column>
            <S.Header>Yoffix</S.Header>
            <S.ListUsersWrapper>
                <S.ListUsers>
                    <SimpleBar
                        style={{ maxHeight: '100%', marginRight: 10 }}
                        autoHide={true}
                        className="custom-vertical-scroll"
                    >
                        {importedUsers.map((user) => (
                            <S.UserItem key={user.id}>
                                <S.UserInfo>
                                    <S.UserName>{user.displayName}</S.UserName>
                                    <S.UserEmail>{user.mail}</S.UserEmail>
                                </S.UserInfo>
                                <S.MinusIcon onClick={() => removeUserHandler(user)}>&#8722;</S.MinusIcon>
                            </S.UserItem>
                        ))}
                    </SimpleBar>
                </S.ListUsers>
            </S.ListUsersWrapper>
        </S.Column>
    )
}
