import React from 'react'

import Star from 'assets/auth/Capterra/Star.svg?url'
import StarFill from 'assets/auth/Capterra/StarFill.svg?url'

import { CapterraStarsContainer } from './styles'

const CapterraStars = () => (
    <CapterraStarsContainer>
        <img src={Star} loading="lazy" alt="Capterra star" />
        <img src={Star} loading="lazy" alt="Capterra star" />
        <img src={Star} loading="lazy" alt="Capterra star" />
        <img src={Star} loading="lazy" alt="Capterra star" />
        <img src={StarFill} loading="lazy" alt="Capterra star" />
    </CapterraStarsContainer>
)

export default CapterraStars
