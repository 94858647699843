import { HorizonOfPlanningTimeUnit } from '@graphql/autogenerate/schemas'
import { ERulesHorizon } from 'types/data.types'

const MONTH_COUNT = 31
const WEEK_COUNT = 7

export type HorizonUnitTypes = 'Days' | 'Weeks' | 'Months'

export const getHorizonDay = (time: number, horizonUnit: ERulesHorizon | HorizonOfPlanningTimeUnit) => {
    switch (horizonUnit) {
        case ERulesHorizon.DAY:
            return time
        case ERulesHorizon.WEEK:
            return time * WEEK_COUNT
        case ERulesHorizon.MONTH:
            return time * MONTH_COUNT
        default:
            return 0
    }
}

export const getKeyFromValue = (value: ERulesHorizon | HorizonOfPlanningTimeUnit): HorizonUnitTypes => {
    switch (value) {
        case ERulesHorizon.DAY:
            return 'Days'
        case ERulesHorizon.WEEK:
            return 'Weeks'
        case ERulesHorizon.MONTH:
            return 'Months'
        default:
            return 'Days'
    }
}
