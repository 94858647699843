import * as React from "react";
import { forwardRef } from "react";
const SvgCalendarMeeting = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { width: 12, height: 12, viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M10.4959 3.06666C10.2971 3.06666 10.2 3.22784 10.2 3.42666V6.46666C10.2 7.57123 9.30457 8.46666 8.2 8.46666H2.9C2.62386 8.46666 2.4 8.69051 2.4 8.96666V9.66666C2.4 9.99666 2.67 10.2667 3 10.2667H8.77157C9.30201 10.2667 9.81071 10.4774 10.1858 10.8524L11.1464 11.8131C11.4614 12.1281 12 11.905 12 11.4595V4.50666C12 3.71137 11.2912 3.06666 10.4959 3.06666ZM7.00103 7.26666C8.1056 7.26666 9 6.37123 9 5.26666V2.66665C9 1.56209 8.10457 0.666657 7 0.666657L2 0.666657C0.895431 0.666657 0 1.56209 0 2.66666V8.45955C0 8.905 0.538571 9.12809 0.853553 8.8131L1.81421 7.85244C2.18929 7.47737 2.69667 7.26666 3.2271 7.26666C4.18673 7.26666 5.74514 7.26666 7.00103 7.26666Z", fill: "#333333", fillOpacity: 0.8, style: {
  fill: "#333333",
  fill: "color(display-p3 0.2000 0.2000 0.2000)",
  fillOpacity: 0.8
} }));
const ForwardRef = forwardRef(SvgCalendarMeeting);
export default ForwardRef;
