import logo from 'assets/common/logo-yoffix.svg?url'

import * as S from '../styles'

export const InitialRightColumn = () => {
    return (
        <>
            <a href="https://yoffix.com">
                <S.AuthRouteLogo src={logo} alt="Yoffix logo" />
            </a>
        </>
    )
}
