import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Guest } from '@graphql/autogenerate/schemas'

type GuestsState = {
    guests: Guest[]
    total: number
    date: string
    loading: boolean
    officeID: string
    error: string | null
}

export const initialState: GuestsState = {
    guests: [],
    total: 0,
    loading: false,
    date: new Date().toISOString(),
    officeID: '',
    error: null,
}

const guestsSlice = createSlice({
    name: 'guests',
    initialState,
    reducers: {
        setGuests(state, action: PayloadAction<Guest[]>) {
            state.guests = action.payload
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        setDate(state, action: PayloadAction<string>) {
            state.date = action.payload
        },
        setTotal(state, action: PayloadAction<number>) {
            state.total = action.payload
        },
        setOfficeID(state, action: PayloadAction<string>) {
            state.officeID = action.payload
        },
        setError(state, action: PayloadAction<string | null>) {
            state.error = action.payload
        },
    },
})

export const guestsActions = guestsSlice.actions
export default guestsSlice.reducer
