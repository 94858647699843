import React from 'react'

import i18n from 'i18next'

import * as S from './styles'

export const LanguageSwitcher = () => {
    const [language, setLanguage] = React.useState('en')

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng)
        setLanguage(lng)
    }
    return (
        <S.LanguageSwitcherRoot>
            <S.LanguageSwitcherButton isActive={language === 'en'} onClick={() => changeLanguage('en')}>
                En
            </S.LanguageSwitcherButton>
            <S.Divider>|</S.Divider>
            <S.LanguageSwitcherButton isActive={language === 'de'} onClick={() => changeLanguage('de')}>
                De
            </S.LanguageSwitcherButton>
            <S.Divider>|</S.Divider>
            <S.LanguageSwitcherButton isActive={language === 'fr'} onClick={() => changeLanguage('fr')}>
                Fr
            </S.LanguageSwitcherButton>
            <S.Divider>|</S.Divider>
            <S.LanguageSwitcherButton isActive={language === 'es'} onClick={() => changeLanguage('es')}>
                Es
            </S.LanguageSwitcherButton>
        </S.LanguageSwitcherRoot>
    )
}
