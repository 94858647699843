import React, { FC } from 'react'

import { useCompanyData } from 'src/context/company/company'

import { TARIFF } from 'types/billing.types'

import { BillingProviderCTX } from './BillingProviderCTX'
import { useAuth } from '../auth'

export const BillingProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const { userAuth, userGroup, refreshJWTToken } = useAuth()
    const { company, changeTariff } = useCompanyData()

    if (!userAuth || company === undefined) {
        return null
    }

    return (
        <BillingProviderCTX
            companyId={company.id}
            userGroup={userGroup}
            tariff={TARIFF.FREE}
            email={userAuth.email}
            refreshJWTToken={refreshJWTToken}
            changeTariff={changeTariff}
        >
            {children}
        </BillingProviderCTX>
    )
}

BillingProvider.displayName = 'BillingProvider'
