import React, { FC, Suspense, useEffect, useState } from 'react'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useContextSelector } from 'use-context-selector'

import Header from 'components/atoms/Header'
import InfromationBlock from 'components/atoms/InfromationBlock'
import InitialSetup from 'containers/InitialSetup'
import { useResizeDevice } from 'context'
import { useAuth } from 'context/auth'
import { CompanyContext } from 'context/company/company'
import { useMsTeams } from 'context/ms-teams/msTeams'
import { useAppSelector } from 'hooks'
import { AUTH_ROUTES, MANAGER_ROUTES, ROUTES } from 'types/routes'
import { EUserGroup } from 'types/user.types'

import { WrapperBackground, WrapperMain, WrapperRoot } from './styles'
import Sidebar from '../Sidebar'

/* containers */
const Employees = React.lazy(() => import('containers/Employees'))
const Settings = React.lazy(() => import('containers/Settings'))
const RoomsPageV2 = React.lazy(() => import('src/containers/RoomsPage'))
const RoomsPageMobileV2 = React.lazy(() => import('src/containers/RoomsPageMobile'))
const ResourcePage = React.lazy(() => import('containers/Resources'))
const Profile = React.lazy(() => import('containers/Profile'))
const Dashboard = React.lazy(() => import('containers/Dashboard'))
const DashboardForTeamLead = React.lazy(() => import('containers/DashboardForTeamLead'))
const Subscription = React.lazy(() => import('containers/Subscription'))
const OfficeBuilder = React.lazy(() => import('containers/OfficeBuilder'))
const SpaceScreen = React.lazy(() => import('containers/SpaceScreen'))
const Calendar = React.lazy(() => import('containers/Calendar'))
const ResourceManagement = React.lazy(() => import('containers/ResourceManagement'))
const GuestManagement = React.lazy(() => import('src/containers/VisitorsManagement'))
const AdminPanel = React.lazy(() => import('containers/AdminPanel'))
const CompanyManagement = React.lazy(() => import('containers/CompanyManagement'))

const routesWithCustomHeader = [
    ROUTES.CALENDAR,
    ROUTES.ROOMS,
    ROUTES.RESOURCES,
    ROUTES.SETTINGS,
    ROUTES.PROFILE,
    ROUTES.SPACE_SCREEN,
    ROUTES.SCHEDULE,
    ROUTES.GUESTS,
    MANAGER_ROUTES.OFFICES,
    MANAGER_ROUTES.EMPLOYEES,
    MANAGER_ROUTES.ADMIN_PANEL,
    MANAGER_ROUTES.DASHBOARD,
    MANAGER_ROUTES.RESOURCE_MANAGEMENT,
    MANAGER_ROUTES.COMPANY_MANAGEMENT,
]

const Wrapper: FC = () => {
    const { pathname, state } = useLocation()
    const { roomManagement, useOnlySpaceScreen } = useFlags()
    const { update, hide } = useIntercom()
    const { userGroup } = useAuth()
    const company = useContextSelector(CompanyContext, (value) => value.company)

    const { isMobile, height } = useResizeDevice()
    const { inTeams } = useMsTeams()

    const [wrapperContainer, setWrapperContainer] = useState<HTMLDivElement | null>(null)

    console.log('userGroup', userGroup)
    const isSuperAdmin = userGroup === EUserGroup.SUPER_ADMIN
    const isManager = userGroup === EUserGroup.MANAGER
    const isEmployee = userGroup === EUserGroup.EMPLOYEE
    const isTeamLead = userGroup === EUserGroup.TEAM_LEAD

    const hasCustomHeader = routesWithCustomHeader.includes(('/' + pathname.split('/')[1]) as ROUTES)

    const { email: userEmail, firstname: userName } = useAppSelector((rootState) => rootState.user)

    const Rooms = !isMobile ? RoomsPageV2 : RoomsPageMobileV2

    useEffect(() => {
        if (company?.id && company?.title) {
            update({
                email: userEmail,
                name: userName,
                company: {
                    companyId: company.id,
                    name: company.title,
                },
            })
        }
    }, [company?.id, company?.title, userName, userEmail])

    useEffect(() => {
        if (wrapperContainer) {
            const vh = height * 0.01
            wrapperContainer.style.setProperty('--vh', `${vh}px`)
        }
    }, [height, wrapperContainer])

    if (pathname === ROUTES.INITIAL_SETUP) {
        return <InitialSetup />
    }

    let mainPage = ROUTES.CALENDAR

    if (roomManagement) {
        mainPage = ROUTES.ROOMS
    }

    if (useOnlySpaceScreen) {
        mainPage = ROUTES.SPACE_SCREEN
    }

    return (
        <>
            <WrapperBackground isMobile={isMobile} />

            <WrapperRoot onClick={hide} ref={setWrapperContainer}>
                {!hasCustomHeader && <Header />}
                <WrapperMain hasCustomHeader={!hasCustomHeader} hasSidebar={!inTeams} isMobile={isMobile}>
                    <Suspense fallback={null}>
                        {isTeamLead && (
                            <Routes>
                                {isMobile ? (
                                    <Route
                                        path={`${MANAGER_ROUTES.DASHBOARD}/*`}
                                        element={<Navigate to={ROUTES.CALENDAR} />}
                                    />
                                ) : (
                                    <Route path={`${MANAGER_ROUTES.DASHBOARD}/*`} element={<DashboardForTeamLead />} />
                                )}
                                <Route path={`${ROUTES.CALENDAR}/*`} element={<Calendar />} />
                                <Route path={`${ROUTES.SPACE_SCREEN}/*`} element={<SpaceScreen />} />
                                <Route path={ROUTES.PROFILE} element={<Profile />} />
                                <Route path={`${ROUTES.ROOMS}/*`} element={<Rooms />} />
                                <Route path={`${ROUTES.RESOURCES}/*`} element={<ResourcePage />} />
                                <Route path={`${MANAGER_ROUTES.EMPLOYEES}/*`} element={<Employees />} />
                                {pathname !== AUTH_ROUTES.LOGOUT && (
                                    <Route path="*" element={<Navigate to={mainPage} />} />
                                )}
                            </Routes>
                        )}
                        {isEmployee && (
                            <Routes>
                                <Route path={`${ROUTES.CALENDAR}/*`} element={<Calendar />} />
                                <Route path={`${ROUTES.SPACE_SCREEN}/*`} element={<SpaceScreen />} />
                                <Route path={ROUTES.PROFILE} element={<Profile />} />
                                <Route path={`${ROUTES.ROOMS}/*`} element={<Rooms />} />
                                {pathname !== AUTH_ROUTES.LOGOUT && (
                                    <Route path="*" element={<Navigate to={mainPage} />} />
                                )}
                            </Routes>
                        )}
                        {isManager && (
                            <Routes>
                                {isMobile ? (
                                    <Route
                                        path={`${MANAGER_ROUTES.DASHBOARD}/*`}
                                        element={<Navigate to={ROUTES.CALENDAR} />}
                                    />
                                ) : (
                                    <Route path={`${MANAGER_ROUTES.DASHBOARD}/*`} element={<Dashboard />} />
                                )}
                                <Route path={`${MANAGER_ROUTES.EMPLOYEES}/*`} element={<Employees />} />
                                <Route path={MANAGER_ROUTES.OFFICES} element={<OfficeBuilder />} />
                                <Route
                                    path={`${MANAGER_ROUTES.RESOURCE_MANAGEMENT}/*`}
                                    element={<ResourceManagement />}
                                />
                                <Route path={`${ROUTES.SETTINGS}/*`} element={<Settings />} />
                                <Route path={`${ROUTES.CALENDAR}/*`} element={<Calendar />} />
                                <Route path={`${ROUTES.SPACE_SCREEN}/*`} element={<SpaceScreen />} />
                                <Route path={ROUTES.PROFILE} element={<Profile />} />
                                <Route path={MANAGER_ROUTES.SUBSCRIPTIONS} element={<Subscription />} />
                                <Route path={`${ROUTES.ROOMS}/*`} element={<Rooms />} />
                                <Route path={`${ROUTES.RESOURCES}/*`} element={<ResourcePage />} />
                                <Route path={`${ROUTES.GUESTS}/*`} element={<GuestManagement />} />
                                <Route path={`${MANAGER_ROUTES.ADMIN_PANEL}/*`} element={<AdminPanel />} />
                                {state && <Route element={<Navigate to={state} />} />}
                                {pathname !== AUTH_ROUTES.LOGOUT && (
                                    <Route path="*" element={<Navigate to={mainPage} />} />
                                )}
                            </Routes>
                        )}
                        {isSuperAdmin && (
                            <Routes>
                                <Route path={MANAGER_ROUTES.COMPANY_MANAGEMENT} element={<CompanyManagement />} />
                                {isMobile ? (
                                    <Route
                                        path={`${MANAGER_ROUTES.DASHBOARD}/*`}
                                        element={<Navigate to={ROUTES.CALENDAR} />}
                                    />
                                ) : (
                                    <Route path={`${MANAGER_ROUTES.DASHBOARD}/*`} element={<Dashboard />} />
                                )}
                                <Route path={`${MANAGER_ROUTES.EMPLOYEES}/*`} element={<Employees />} />
                                <Route path={MANAGER_ROUTES.OFFICES} element={<OfficeBuilder />} />
                                <Route
                                    path={`${MANAGER_ROUTES.RESOURCE_MANAGEMENT}/*`}
                                    element={<ResourceManagement />}
                                />
                                <Route path={`${ROUTES.SETTINGS}/*`} element={<Settings />} />
                                <Route path={`${ROUTES.CALENDAR}/*`} element={<Calendar />} />
                                <Route path={`${ROUTES.SPACE_SCREEN}/*`} element={<SpaceScreen />} />
                                <Route path={ROUTES.PROFILE} element={<Profile />} />
                                <Route path={MANAGER_ROUTES.SUBSCRIPTIONS} element={<Subscription />} />
                                <Route path={`${ROUTES.ROOMS}/*`} element={<Rooms />} />
                                <Route path={`${ROUTES.RESOURCES}/*`} element={<ResourcePage />} />
                                <Route path={`${ROUTES.GUESTS}/*`} element={<GuestManagement />} />
                                <Route path={`${MANAGER_ROUTES.ADMIN_PANEL}/*`} element={<AdminPanel />} />
                                {state && <Route element={<Navigate to={state} />} />}
                                {pathname !== AUTH_ROUTES.LOGOUT && (
                                    <Route path="*" element={<Navigate to={mainPage} />} />
                                )}
                            </Routes>
                        )}
                    </Suspense>
                </WrapperMain>
                {company && <InfromationBlock isManager={isManager} company={company} />}
                {!inTeams && <Sidebar pathname={pathname} />}
            </WrapperRoot>
        </>
    )
}

export default Wrapper
