import { FormControl } from '@material-ui/core'
import styled from 'styled-components'

export const HeaderDescription = styled.h3`
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
    margin-top: 0;
    margin-bottom: 24px;
    color: rgba(51, 51, 51, 1);
`

export const CompanyEditWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 720px;
    margin-bottom: 20px;
`

export const DetailsHeader = styled.h3`
    margin: 40px 0 16px;
    font-size: 18px;
    font-weight: 500;
`
export const DetailsBlock = styled.div`
    width: 500px;
`

export const SpaceInterfaceFormControl = styled(FormControl)`
    margin-bottom: 12px;
    & .MuiFormControl-root {
        & .MuiInputBase-root {
            border-radius: 12px;
            height: 56px;
            background: rgba(227, 232, 238, 0.5);
            font-size: 14px;
        }
    }
    & .MuiFormLabel-root {
        font-size: 14px;
        color: rgba(51, 51, 51, 0.5);
        padding-left: 5px;
    }
`
