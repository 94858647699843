import React, { FC, memo } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation, Link } from 'react-router-dom'

import { SettingsSVG } from 'assets/common'
import { useAuth, useResizeDevice } from 'context'
import { useMsTeams } from 'context/ms-teams/msTeams'
import { getConvertedDate } from 'helpers'
import { MANAGER_ROUTES } from 'types/routes'
import { EUserGroup } from 'types/user.types'

import * as S from './styles'

type Props = {
    title?: string
    date?: Date | moment.Moment | string
    className?: string
    children?: React.ReactNode
    isShowAdminPanelLink?: boolean
}

const titles = {
    settings: 'Settings',
    profile: 'User profile',
    employees: 'Our employees',
    spaces: 'Our working spaces',
    capacity: 'Capacity',
    reports: 'Onboarding report',
    rules: 'Rules and automation',
    dashboard: 'Dashboard',
    'dashboard/attendance': 'Dashboard',
    covid: 'COVID tools',
}

//todo: maybe add datepicker
const Header: FC<Props> = memo(({ date, title, children, className, isShowAdminPanelLink }) => {
    const { userGroup } = useAuth()
    const isManager = userGroup === EUserGroup.MANAGER
    const { t } = useTranslation('translation')
    const location = useLocation()
    const { isMobile } = useResizeDevice()
    const { inTeams } = useMsTeams()
    let currentTitle = title

    if (currentTitle === undefined) {
        currentTitle = titles[location.pathname.replace('/', '') as keyof typeof titles]
    }

    return (
        <S.HeaderRoot className={className}>
            <S.LeftSideWrapper>
                <S.CalendarHeaderTitleWrapp className="header-title">
                    <S.HeaderTitle>{t(currentTitle)}</S.HeaderTitle>
                </S.CalendarHeaderTitleWrapp>
                <S.HeaderInfoWrapper>
                    {children}
                    {date && (
                        <S.HeaderDateInfo>
                            <S.HeaderDate>{getConvertedDate(date, 'MMMM YYYY')}</S.HeaderDate>
                        </S.HeaderDateInfo>
                    )}
                </S.HeaderInfoWrapper>
            </S.LeftSideWrapper>
            {isShowAdminPanelLink && !isMobile && inTeams && isManager && (
                <div>
                    <Link to={MANAGER_ROUTES.ADMIN_PANEL}>
                        <S.AdminPanelLink>
                            <SettingsSVG fill="#0019FF" fillOpacity={1} /> Admin panel
                        </S.AdminPanelLink>
                    </Link>
                </div>
            )}
        </S.HeaderRoot>
    )
})

Header.displayName = 'Header'
export default Header
