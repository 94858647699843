import { ActionType, ResourceType } from '@graphql/autogenerate/schemas'
import { CalendarSVG, EmployeesSVG, SpacesSVG } from 'assets/common'
import { DashboardSVG, GuestSVG, ResourcesSVG, RoomsSVG, SpaceScreenSVG } from 'assets/navigation'

import { SidebarLinks } from './types'

export const sidebarLinks: SidebarLinks = {
    dashboard: {
        page: 'Dashboard',
        icon: <DashboardSVG />,
    },
    spaces: {
        page: 'Spaces',
        icon: <SpaceScreenSVG />,
    },
    calendar: {
        page: 'Calendar',
        icon: <CalendarSVG />,
    },
    rooms: {
        page: 'Rooms',
        icon: <RoomsSVG />,
    },
    resources: {
        page: 'Resources',
        icon: <ResourcesSVG />,
    },
    offices: {
        page: 'Offices',
        icon: <SpacesSVG />,
    },
    employees: {
        page: 'Employees',
        icon: <EmployeesSVG fill="#ffffff" fillOpacity={0.3} />,
    },
    guests: {
        page: 'Visitors',
        icon: <GuestSVG />,
    },
}

export const employeeSidebarLinks: SidebarLinks = {
    ...sidebarLinks,
    dashboard: null,
    employees: null,
    offices: null,
    guests: null,
}

export const teamLeadSidebarLinks: SidebarLinks = {
    ...sidebarLinks,
    guests: null,
}

export const sidebarResourceMap: Record<string, { resourceType: ResourceType | null; requiredAction?: ActionType }> = {
    dashboard: { resourceType: ResourceType.DASHBOARD, requiredAction: ActionType.VIEW },
    spaces: { resourceType: null },
    calendar: { resourceType: null },
    rooms: { resourceType: null },
    resources: { resourceType: null },
    offices: { resourceType: ResourceType.OFFICE, requiredAction: ActionType.VIEW },
    employees: { resourceType: ResourceType.EMPLOYEE, requiredAction: ActionType.VIEW },
}
