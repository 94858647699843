import styled, { css } from 'styled-components'

export const DropDownContainer = styled.div<{ disabled?: boolean }>`
    font-size: 12px;
    color: ${(props) => props.theme.color.black};
    cursor: pointer;
    position: relative;
    display: flex;

    ${(props) =>
        props.disabled &&
        css`
            color: rgba(51, 51, 51, 0.5);
            pointer-events: none;
        `}
`

export const DropDownInterfaceWrapper = styled.div`
    display: flex;
    margin: auto;
    align-items: center;
    height: 32px;
    line-height: 18px;
`

export const ArrowWrapper = styled.div<{ disabled?: boolean }>`
    width: 24px;
    height: 24px;
    border-radius: 6px;
    position: relative;

    svg {
        fill-opacity: 0.5;
    }

    ${(props) =>
        props.disabled &&
        css`
            svg {
                fill: rgba(51, 51, 51, 0.2);
            }
            pointer-events: none;
        `}

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }
`
export const ItemsBox = styled.div<{ isMobile: boolean }>`
    display: flex;
    border-radius: 8px;
    background-color: #fcfdfd;
    font-size: 12px;
    color: ${(props) => props.theme.color.black};
    padding: 16px 8px 0;
    overflow: hidden;
    margin: 0;
    transition:
        opacity 0.3s,
        padding 0.25s;
    z-index: 10;
    box-shadow: 0 2px 27px rgba(51, 51, 51, 0.15);

    ${(props) =>
        props.isMobile &&
        css`
            position: fixed;
            top: 5px;
            left: 5px;
            width: calc(100% - 10px);
            height: calc(100% - 10px);

            z-index: 2000;
            flex-direction: column;
        `}

    .simplebar-track.simplebar-vertical {
        right: 4px;
    }
`

export const CloseBtnWrap = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
`

export const FiltersColumn = styled.div<{ isMobile?: boolean }>`
    width: 170px;

    &:not(:last-child) {
        margin-right: 30px;
    }

    ${(props) =>
        props.isMobile &&
        css`
            width: 100%;

            &:not(:last-child) {
                margin-right: 0;
                margin-bottom: 30px;
            }
        `}
`

export const ItemsList = styled.ul`
    width: 100%;
    padding: 0;

    & .custom-vertical-scroll {
        width: 100%;
    }
`

export const ItemsScrollList = styled.div<{ isMobile?: boolean }>`
    max-height: 209px;
    width: 100%;
    overflow: auto;

    ${(props) =>
        props.isMobile &&
        css`
            max-height: none;
        `}
`

export const FiltersListHeader = styled.div`
    display: flex;
`

export const FilterTitle = styled.h3`
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    padding: 0 0 8px 10px;
`

export const FiltersListHeaderHint = styled.span`
    margin-left: 6px;
    font-weight: 400;
    color: rgba(51, 51, 51, 0.5);
    font-size: 12px;
`

export const DropDownItem = styled.li`
    padding: 7px 15px 7px 10px;
    min-height: 32px;
    list-style-type: none;
    display: flex;
    align-items: center;

    &.select-all {
        position: relative;
        margin-bottom: 6px;

        &::after {
            content: ' ';
            position: absolute;
            display: block;
            height: 1px;
            left: 10px;
            right: 15px;
            bottom: -6px;
            background-color: #e3e8ee;
        }
    }

    &:hover {
        background-color: rgba(51, 51, 51, 0.05);
    }

    .MuiFormControlLabel-label {
        font-size: inherit;
        margin-left: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .MuiIconButton-label,
    .checkbox-frame {
        width: 12px;
        height: 12px;
    }

    .checkbox-root {
        padding-left: 20px;

        span {
            width: 12px;
            height: 12px;
            border-radius: 3px;
            top: 9px;

            svg {
                width: 8px;
                height: 8px;
            }
        }
    }
`

export const TeamsCount = styled.div`
    color: rgba(255, 255, 255, 0.9);
    font-weight: 500;
    min-width: 16px;
    height: 16px;
    border-radius: 49px;
    background-color: ${(props) => props.theme.color.blueButton};
    position: absolute;
    padding: 0 5px;
    left: calc(100% / 2);
    top: -8px;
`

export const IconStyle = {
    color: '#333333',
    width: 20,
    height: 20,
}
