import { FC, useRef, useState } from 'react'

import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { CustomInput, ProgressBar } from 'components/atoms'
import { DescriptionAuth, TitleAuth } from 'containers/Auth/styles'
import { useAppDispatch } from 'hooks'
import { messageActions } from 'store/slices/message'
import { ButtonFilled, ButtonString } from 'styles/buttons-styles'
import { AUTH_ROUTES } from 'types/routes'

const resolveURL =
    import.meta.env.VITE_NODE_ENV === 'development'
        ? 'https://api.beta.yoffix.com/sso/resolve'
        : 'https://api.app.yoffix.com/sso/resolve'

const LoginSSO: FC = () => {
    const { t } = useTranslation('translation')
    const dispatch = useAppDispatch()

    const [emailInput, setEmailInput] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const formRef = useRef<HTMLFormElement | null>(null)

    const handleSsoSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const url = await axios.post<{ loginUrl: string }>(resolveURL, {
                email: emailInput,
            })

            if (url.data.loginUrl.length > 0 && formRef.current) {
                formRef.current.setAttribute('action', url.data.loginUrl)
                formRef.current.submit()
            }
        } catch (error) {
            setIsLoading(false)
            dispatch(
                messageActions.messageShown({
                    text: t('SSO is not enabled for the provided email address'),
                    severity: 'error',
                }),
            )
        }
    }

    return (
        <>
            <TitleAuth>{t('Single Sign On')}</TitleAuth>
            <DescriptionAuth className="margin_bottom_40">
                {t('Please sign in using your SSO credentials.')}
            </DescriptionAuth>

            <form ref={formRef} method="POST" onSubmit={handleSsoSubmit}>
                <CustomInput
                    name="email"
                    type="email"
                    label={t('Email')}
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                />

                <ButtonFilled className="margin_bottom_16 margin_top_16" width="100%" type="submit">
                    {t('Log in')}
                </ButtonFilled>
            </form>

            <Link to={AUTH_ROUTES.LOGIN}>
                <ButtonString className="login_small">{t('Log in without SSO')}</ButtonString>
            </Link>

            {isLoading && <ProgressBar />}
        </>
    )
}

export default LoginSSO
