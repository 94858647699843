import React from 'react'

import Onence from 'assets/auth/TrustedBy/1nce.svg?url'
import Allianz from 'assets/auth/TrustedBy/Allianz.svg?url'
import AnkorStore from 'assets/auth/TrustedBy/AnkorStore.svg?url'
import Howden from 'assets/auth/TrustedBy/Howden.svg?url'
import Salto from 'assets/auth/TrustedBy/Salto.svg?url'
import Zolar from 'assets/auth/TrustedBy/Zolar.svg?url'

import { TrustedByContainer, TrustedByLogosContainer, TrustedByText } from './styles'

const TrustedBy = () => {
    return (
        <TrustedByContainer>
            <TrustedByText>Trusted by</TrustedByText>
            <TrustedByLogosContainer>
                <img src={Howden} alt="Howden" loading="lazy" />
                <img src={Salto} alt="Salto" loading="lazy" />
                <img src={AnkorStore} alt="Ankor store" loading="lazy" />
                <img src={Zolar} alt="Zolar" loading="lazy" />
                <img src={Onence} alt="1nce" loading="lazy" />
                <img src={Allianz} alt="Allianz" loading="lazy" />
            </TrustedByLogosContainer>
        </TrustedByContainer>
    )
}

export default TrustedBy
