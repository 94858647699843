import * as React from "react";
import { forwardRef } from "react";
const SvgArrow = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { width: 16, height: 17, viewBox: "0 0 16 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { id: "icons / 16 / arrow" }, /* @__PURE__ */ React.createElement("path", { id: "Vector", d: "M13 8.51L6.99 14.52L5.576 13.106L10.176 8.506L5.576 3.906L6.99 2.5L13 8.51Z", fill: "#333333", fillOpacity: 0.5, style: {
  fill: "#333333",
  fill: "color(display-p3 0.2000 0.2000 0.2000)",
  fillOpacity: 0.5
} })));
const ForwardRef = forwardRef(SvgArrow);
export default ForwardRef;
