import React, { useMemo } from 'react'

import { Assessment } from './safetyAssessment.types'
import {
    Container,
    Strength,
    StrengthTitle,
    StrengthAssessment,
    StrengthLine,
    StrengthLineItem,
    StrengthText,
} from './styles'
interface SafetyAssessmentProps {
    value: string
    isValid: [boolean, boolean, boolean]
}

const AssessmentColorsMap = {
    [Assessment.Weak]: 'rgba(255, 104, 104, 1)',
    [Assessment.Okey]: 'rgba(255, 216, 77, 1)',
    [Assessment.Strong]: 'rgba(27, 214, 101, 1)',
}

const SafetyAssessment = ({ value, isValid }: SafetyAssessmentProps) => {
    const isStrengthOkey = isValid.some(Boolean)
    const isStrengthStrong = isValid.every(Boolean)
    const valueExist = Boolean(value)

    const assessment = useMemo(() => {
        if (isStrengthStrong) {
            return Assessment.Strong
        }

        if (isStrengthOkey) {
            return Assessment.Okey
        }

        return Assessment.Weak
    }, [isStrengthStrong, isStrengthOkey, isValid])

    const assessmentColors = valueExist ? AssessmentColorsMap[assessment] : 'rgb(227, 232, 238)'

    return (
        <Container>
            <Strength>
                <StrengthText>
                    <StrengthTitle color={assessmentColors}>Strength:</StrengthTitle>
                    <StrengthAssessment color={assessmentColors}>{assessment}</StrengthAssessment>
                </StrengthText>

                <StrengthLine assessment={assessment}>
                    <StrengthLineItem color={assessmentColors} />
                    <StrengthLineItem color={isStrengthOkey || isStrengthStrong ? assessmentColors : undefined} />
                    <StrengthLineItem color={isStrengthStrong ? assessmentColors : undefined} />
                </StrengthLine>
            </Strength>
        </Container>
    )
}

export default SafetyAssessment
