import React, { Component, ErrorInfo, FC } from 'react'

import axios from 'axios'

import { useAuth } from 'context'
import { useAppDispatch } from 'hooks'
import { AppDispatch } from 'store'
import { messageActions } from 'store/slices/message'

import { getErrorMessage } from './utils'

type Props = {
    dispatch: AppDispatch
    companyId?: string
    employeeId?: string
    children: React.ReactNode
}

class ErrorBoundary extends Component<Props, { hasError: boolean }> {
    state = { hasError: false }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    async componentDidCatch(error: Error, info: ErrorInfo) {
        const { dispatch, companyId, employeeId } = this.props
        const url =
            import.meta.env.VITE_NODE_ENV === 'development'
                ? 'https://nrkvslfks0.execute-api.eu-central-1.amazonaws.com/dev/log'
                : 'https://h284tnnf5h.execute-api.eu-central-1.amazonaws.com/prod/log'

        const errorStack = error.stack || 'No stack trace available'
        const componentStack = info.componentStack

        try {
            await axios.post(url, {
                level: 'ERROR',
                companyId,
                employeeId,
                error: error.toString(),
                errorStack,
                componentStack,
            })
        } catch (err) {
            dispatch(
                messageActions.messageShown({
                    text: getErrorMessage(err),
                    severity: 'error',
                }),
            )
        }

        dispatch(
            messageActions.messageShown({
                text: `${error.toString()} - ${componentStack}`,
                severity: 'error',
            }),
        )
        console.error('ErrorBoundary caught an error:', error, info)
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>
        }
        return this.props.children
    }
}

const GlobalErrorHandler: FC<{ children: React.ReactNode }> = ({ children }) => {
    const dispatch = useAppDispatch()
    const { userAuth } = useAuth()

    return (
        <ErrorBoundary dispatch={dispatch} companyId={userAuth?.companyId} employeeId={userAuth?.employeeId}>
            {children}
        </ErrorBoundary>
    )
}

export default GlobalErrorHandler
