import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'

import { GetCompanyAndEmployeeQuery, GetCompanyAndEmployeeQueryVariables } from '@graphql/autogenerate/schemas'
import { QUERY_GET_COMPANY } from '@graphql/queries'
import { RootState } from 'store'
import { TCompany, TEmployee, TSpace, TDepartment, TPosition } from 'types/data.types'

// Async thunk to fetch company data
export const fetchCompanyData = createAsyncThunk<
    { getCompany: TCompany; getEmployee: TEmployee },
    { companyId: string; employeeId: string },
    { extra: { client: ApolloClient<NormalizedCacheObject> } }
>(
    'company/fetchCompanyData',
    async ({ companyId, employeeId }: { companyId: string; employeeId: string }, thunkAPI) => {
        const { client } = thunkAPI.extra
        try {
            const { data } = await client.query<GetCompanyAndEmployeeQuery, GetCompanyAndEmployeeQueryVariables>({
                query: QUERY_GET_COMPANY,
                variables: { id: companyId, employeeId },
            })
            return {
                getCompany: data.getCompany as TCompany,
                getEmployee: data.getEmployee as TEmployee,
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    },
)

type TCompanyState = {
    company: TCompany | undefined
    employees: TEmployee[]
    spaces: TSpace[]
    departments: TDepartment[]
    positions: TPosition[]
    loading: boolean
    error: string | null
}

const initialState: TCompanyState = {
    company: undefined,
    employees: [],
    spaces: [],
    departments: [],
    positions: [],
    loading: false,
    error: null,
}

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setCompany: (state, action) => {
            state.company = action.payload
        },
        updateCompanyTariff: (state, action) => {
            if (state.company) {
                state.company.tariff = action.payload
            }
        },
        updateCompanyRules: (state, action) => {
            if (state.company) {
                state.company.Rules = action.payload
            }
        },
        setEmployees: (state, action) => {
            state.employees = action.payload
        },
        setSpaces: (state, action) => {
            state.spaces = action.payload
        },
        setDepartments: (state, action) => {
            state.departments = action.payload
        },
        setPositions: (state, action) => {
            state.positions = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanyData.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(fetchCompanyData.fulfilled, (state, action) => {
                state.loading = false
                if (action.payload) {
                    state.company = action.payload.getCompany
                    state.employees = action.payload.getEmployee ? [action.payload.getEmployee] : []
                }
            })
            .addCase(fetchCompanyData.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload as string
            })
    },
})

export const {
    setCompany,
    updateCompanyTariff,
    updateCompanyRules,
    setEmployees,
    setSpaces,
    setDepartments,
    setPositions,
} = companySlice.actions

export default companySlice.reducer

// Selectors using Reselect
export const selectCompany = createSelector(
    (state: RootState) => state.company.company,
    (company) => company,
)

export const selectCompanyRules = createSelector(
    (state: RootState) => state.company.company?.Rules,
    (rules) => rules,
)

export const selectCompanySpaces = createSelector(
    (state: RootState) => state.company.spaces,
    (spaces) => spaces,
)
