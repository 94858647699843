import styled, { css } from 'styled-components'

export const Steps = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 28px;
`

export const Step = styled.div<{ active?: boolean; completed?: boolean }>`
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.color.greyBackground};
    color: ${(props) => props.theme.color.secondaryBlack};
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    width: 28px;
    height: 28px;
    position: relative;
    text-align: center;

    & .number {
        text-align: center;
    }

    & .text {
        opacity: 0;
        display: none;
    }

    ${(props) =>
        props.completed &&
        css`
            background: ${props.theme.color.blueButton};
            color: ${props.theme.color.white};
        `}

    ${(props) =>
        props.active &&
        css`
            background: ${props.theme.color.blueButton};
            color: ${props.theme.color.white};
            width: auto;

            & .text {
                opacity: 1;
                display: block;
            }

            & .number {
                margin-right: 12px;
            }
        `}
`

export const StepLine = styled.div<{ started?: boolean; ended?: boolean }>`
    width: 24px;
    height: 2px;
    position: relative;
    background: ${(props) => props.theme.color.greyBackground};

    ${(props) =>
        props.started &&
        css`
            background: linear-gradient(90deg, #e3e8ee 0%, #0019ff 100%);
            transform: matrix(-1, 0, 0, 1, 0, 0);
        `}

    ${(props) =>
        props.ended &&
        css`
            background: ${props.theme.color.blueButton};
        `}
`
