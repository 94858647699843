import { useEffect } from 'react'

const usePaddle = () => {
    useEffect(() => {
        const Paddle = window.Paddle
        if (Paddle !== undefined) {
            if (import.meta.env.VITE_NODE_ENV === 'development') {
                Paddle.Environment.set('sandbox')
            }

            Paddle.Setup({ vendor: +import.meta.env.VITE_PADDLE_VENDOR_ID })
        }
    }, [])
}

export default usePaddle
