import { createContext, useContext } from 'react'

// Helper Functions and Constants

import { noop, noopAsync } from 'helpers'
import { EUserGroup } from 'types/user.types'

import { TAuthContext } from './auth.types'

// ==============================
// Context Setup
// ==============================

export const AuthContext = createContext<TAuthContext>({
    userAuth: null,
    isAuth: false,
    userGroup: EUserGroup.EMPLOYEE,
    signIn: noop,
    signOut: noopAsync,
    signUp: noop,
    completeNewPassword: noopAsync,
    updateUserAttribute: noop,
    forgotUserPassword: noop,
    forgotUserPasswordSubmit: noopAsync,
    refreshJWTToken: noopAsync,
})

AuthContext.displayName = 'AuthContext'

// ==============================
// Custom Hook for Auth Context
// ==============================

const useAuth = (): TAuthContext => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}

export { useAuth }
