import { FC, useCallback, useState } from 'react'

import { ArrowLeftArrowRightSVG } from 'assets/common'

import MicrosoftUsers from './components/MicrosoftUsers'
import YoffixUsers from './components/YoffixUsers'
import * as S from './styles'
import { MicrosoftUser } from './types'

type ImportUsersProps = {
    setImportedUsers: (users: Array<MicrosoftUser>) => void
}
export const ImportUsers: FC<ImportUsersProps> = ({ setImportedUsers: setImportedUsersFromProps }) => {
    const [importedUsers, setImportedUsers] = useState<MicrosoftUser[]>([])

    const addUserHandler = useCallback((user) => {
        setImportedUsers((prevState) => {
            const newSet = [...prevState, user]

            setImportedUsersFromProps(newSet)

            return newSet
        })
    }, [])

    const removeUserHandler = useCallback((user) => {
        setImportedUsers((prevState) => {
            const newSet = prevState.filter((item) => item.id !== user.id)

            setImportedUsersFromProps(newSet)

            return newSet
        })
    }, [])

    return (
        <S.ImportUsers>
            <MicrosoftUsers addUser={addUserHandler} />
            <S.Divider>
                <ArrowLeftArrowRightSVG />
            </S.Divider>
            <YoffixUsers importedUsers={importedUsers} removeUser={removeUserHandler} />
        </S.ImportUsers>
    )
}
