import * as React from "react";
import { forwardRef } from "react";
const SvgRoom = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { id: "icons / 24 / room" }, /* @__PURE__ */ React.createElement("path", { id: "Vector", fillRule: "evenodd", clipRule: "evenodd", d: "M6 4.5C6 4.22386 6.22386 4 6.5 4H17.5C17.7761 4 18 4.22386 18 4.5V21.5C18 21.7761 18.2239 22 18.5 22H19.5C19.7761 22 20 21.7761 20 21.5V4.5C20 3.11929 18.8807 2 17.5 2H6.5C5.11929 2 4 3.11929 4 4.5V21.5C4 21.7761 4.22386 22 4.5 22H5.5C5.77614 22 6 21.7761 6 21.5V4.5ZM16.5 5C16.7761 5 17 5.22386 17 5.5V21.5C17 21.7761 16.7761 22 16.5 22H7.5C7.22386 22 7 21.7761 7 21.5V5.5C7 5.22386 7.22386 5 7.5 5H16.5ZM16 13C16 13.5523 15.5523 14 15 14C14.4477 14 14 13.5523 14 13C14 12.4477 14.4477 12 15 12C15.5523 12 16 12.4477 16 13Z", fill: "#333333", fillOpacity: 0.8, style: {
  fill: "#333333",
  fill: "color(display-p3 0.2000 0.2000 0.2000)",
  fillOpacity: 0.8
} })));
const ForwardRef = forwardRef(SvgRoom);
export default ForwardRef;
