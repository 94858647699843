import { useCallback, useState } from 'react'

import axios from 'axios'
import { useTranslation } from 'react-i18next'

import { JwtTokenName } from 'shared/constants'
import { messageActions } from 'store/slices/message'
import { ButtonFilled } from 'styles/buttons-styles'

import { ProgressBar } from '../../../components/atoms'
import { useAppDispatch } from '../../../hooks'
import * as S from '../styles'

const CONSENT_URL = import.meta.env.VITE_SAAS_CONSTENT_URL!

export const AdminConsent = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation(['translation'])
    const [isLoading, setIsLoading] = useState(false)
    const token = localStorage.getItem(JwtTokenName)

    const handleButtonClick = useCallback(async () => {
        if (token) {
            setIsLoading(true)

            try {
                const response = await axios.get<{ location: string }>(CONSENT_URL, {
                    headers: { Authorization: token },
                })

                window.location.href = response.data.location
            } catch (error) {
                setIsLoading(false)
                if (axios.isAxiosError(error)) {
                    dispatch(
                        messageActions.messageShown({
                            text: error.response?.data,
                            severity: 'error',
                        }),
                    )
                } else {
                    dispatch(
                        messageActions.messageShown({
                            text: 'An unexpected error occurred',
                            severity: 'error',
                        }),
                    )
                }
            }
        }
    }, [token])

    return (
        <S.WizardContent>
            <div>
                <h2>Provide Admin Consent for Microsoft Services</h2>
                <p>
                    To fully enable all features of Yoffix within your Microsoft environment, we require administrative
                    consent to access specific Microsoft services on behalf of your organization. <br />
                    This step ensures seamless integration with Microsoft services such as Active Directory (AD),
                    Microsoft Calendar, and others, allowing Yoffix to:{' '}
                    <ul style={{ padding: 12 }}>
                        <li>
                            Access and Manage User Profiles: Read and update user profiles within your organization to
                            enhance user management and experience.
                        </li>
                        <li>
                            Manage Calendars and Events: Integrate with Microsoft Calendar to read and write calendar
                            events, facilitating better schedule management.
                        </li>
                    </ul>
                </p>
            </div>

            <ButtonFilled width="100%" onClick={handleButtonClick}>
                {t('Grant Admin Consent', { ns: 'translation' })}
            </ButtonFilled>
            <>{isLoading && <ProgressBar />}</>
        </S.WizardContent>
    )
}
