import { FC, useRef, useState, useCallback, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { ProgressBar } from 'components/atoms'
import SearchInput from 'components/atoms/SearchInput'

import { useSearchInMicrosoft } from './useSearchInMicrosoft'
import * as S from '../../styles'
import { MicrosoftUser } from '../../types'

type MicrosoftUsersProps = {
    addUser: (user: MicrosoftUser) => void
}
export const MicrosoftUsers: FC<MicrosoftUsersProps> = ({ addUser }) => {
    const { t } = useTranslation('translation')
    const { handleSearch, setSearchText, searchResult, searching, nextLink } = useSearchInMicrosoft()
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [disabled, setDisabled] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null)
    const openHandler = () => {
        if (disabled) {
            return
        }
        setIsOpen((prevState) => !prevState)
    }

    const addUserHandler = useCallback(
        (user) => {
            addUser(user)
        },
        [addUser],
    )

    useEffect(() => {
        const currentElement = lastElement
        const currentObserver = new IntersectionObserver(handleObserver)

        if (currentElement) {
            currentObserver.observe(currentElement)
        }

        async function handleObserver(entries: Array<IntersectionObserverEntry>) {
            const first = entries[0]

            if (first.isIntersecting && nextLink && !searching) {
                handleSearch()
                return
            }
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement)
            }
        }
    }, [lastElement, nextLink, searching, handleSearch])

    const handelSearchByText = useCallback(
        (text: string) => {
            handleSearch(text)
        },
        [handleSearch],
    )

    return (
        <S.Column>
            <S.Header>Microsoft</S.Header>
            <S.SearchBox>
                <SearchInput
                    isBlankInput={true}
                    onInput={handelSearchByText}
                    iconStyles={S.IconStyle}
                    placeholder={t('Search people') as string}
                />
                {/*<S.DropDownContainer ref={wrapperRef} disabled={false} isOpen={isOpen}>
                    <S.DropDownInterfaceWrapper onClick={openHandler}>
                        <S.ArrowWrapper className="flex-center">
                            <FilterSvg />
                        </S.ArrowWrapper>
                    </S.DropDownInterfaceWrapper>
                </S.DropDownContainer>*/}
            </S.SearchBox>
            <S.ListUsersWrapper>
                <S.ListUsers>
                    <SimpleBar
                        style={{ maxHeight: '100%', marginRight: 10 }}
                        autoHide={true}
                        className="custom-vertical-scroll"
                    >
                        {searchResult.map((user, index) => (
                            <S.UserItem
                                key={user.id}
                                ref={(r) => index >= searchResult.length - 1 && setLastElement(r)}
                            >
                                <S.UserInfo>
                                    <S.UserName>{user.displayName}</S.UserName>
                                    <S.UserEmail>{user.mail}</S.UserEmail>
                                </S.UserInfo>
                                <S.PlusIcon onClick={() => addUserHandler(user)}>&#43;</S.PlusIcon>
                            </S.UserItem>
                        ))}
                    </SimpleBar>
                </S.ListUsers>
            </S.ListUsersWrapper>
            <>{searching && <ProgressBar />}</>
        </S.Column>
    )
}
