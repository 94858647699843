import { createContext, useContext } from 'react'

export { CateringProvider } from './CateringProvider'

import type { Catering, MenuItem } from '@graphql/autogenerate/schemas'

export interface MenuItemWithQuantity extends MenuItem {
    quantity?: number
}

interface CateringContextProps {
    currentCatering: Catering | null
    setCurrentCatering: (catering: Catering | null) => void
    selectMenuItems: MenuItemWithQuantity[] // Изменяем тип на MenuItemWithQuantity[]
    setSelectMenuItems: (menuItems: MenuItemWithQuantity[]) => void // Изменяем тип на MenuItemWithQuantity[]
}

export const CateringContext = createContext<CateringContextProps | null>(null)

export const useCateringContext = () => {
    const context = useContext(CateringContext)
    if (!context) {
        throw new Error('useCateringContext must be used within a CateringProvider')
    }
    return context
}
