import React, { FC, useState } from 'react'

import { PreloaderContext } from 'src/context/preloader/preloader'

import ProgressBar from 'components/atoms/ProgressBar'

const defaultPreloaderState = { isLoad: false, progressValue: null as number | null }

export const PreloaderProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState(defaultPreloaderState)

    const setValueProgressBar = (value: number) => {
        setState({ progressValue: value, isLoad: true })
    }

    const showPreloader = (isLoad: boolean) => {
        setState({ isLoad, progressValue: null })
    }

    return (
        <PreloaderContext.Provider value={{ showPreloader, setValueProgressBar }}>
            {state.isLoad && <ProgressBar value={state.progressValue} />}
            {children}
        </PreloaderContext.Provider>
    )
}
