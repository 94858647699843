import styled from 'styled-components'

export const AuthRoot = styled.div`
    display: flex;
    position: relative;
    flex-direction: column-reverse;
    justify-content: start;

    min-height: 100vh;
    padding: 8px;
    background-color: ${(props) => props.theme.color.greyBackground};

    @media (min-width: 1024px) {
        flex-direction: row;
        padding: 12px;
    }
`

export const AuthRouteWrappContent = styled.div`
    width: 359px;
    height: max-content;
    padding: 32px 32px 24px;
    z-index: 1;

    margin: 0 auto;

    background-color: ${(props) => props.theme.color.white};
    border-radius: 32px;

    @media (min-width: 480px) {
        width: 420px;
    }

    @media (min-width: 1024px) {
        width: 30%;
        min-width: 450px;

        height: auto;
        padding: 56px 40px 24px;
    }
`

export const AuthRouteLogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 92px;

    @media (min-width: 480px) {
        height: 140px;
    }

    @media (min-width: 1024px) {
        height: auto;
    }
`

export const AuthRouteLogo = styled.img`
    width: 72px;

    @media (min-width: 480px) {
        width: 112px;
    }

    @media (min-width: 1024px) {
        width: 349px;
    }
`

export const AuthRoutFormContent = styled.div`
    width: 100%;
    height: 100%;
`

export const Wizard = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
`

export const WizardHeader = styled.h1`
    font-size: 32px;
    font-weight: 600;
`

export const WizardContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`

export const ContainerForConfetti = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
`
