import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { defaultPermissions } from 'shared/defaultPermissions'
import { RootState } from 'store'
import { ENotificationChannel, ERoleEmployee, TCompany } from 'types/data.types'
import {
    EUserCustomRules,
    EUserLocale,
    EUserPermissions,
    EUserShareAttendance,
    User,
    UserResponse,
} from 'types/user.types'

const initialState: User = {
    id: '',
    photo: '',
    email: '',
    favouriteOfficeID: '',
    firstname: '',
    lastname: '',
    turnOnExperimentalFeatures: false,
    locale: EUserLocale.EN,
    shareAttendance: EUserShareAttendance.ALL,
    departmentIDs: [],
    favouriteColleagueIDs: [],
    favouriteReservableID: '',
    buddyID: '',
    position: null,
    office: null,
    demoAccountID: '',
    notificationChannel: ENotificationChannel.EMAIL,
    fullName: '',
    role: ERoleEmployee.EMPLOYEE,
    permissions: [],
    rules: null,
    isAdmin: false,
    cognitoUserId: '',
    departmentsString: '',
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userDataReceived: (state, action: PayloadAction<{ employee: UserResponse; company: TCompany }>) => {
            const {
                id,
                photo,
                email,
                departmentIDs,
                favouriteColleagueIDs,
                buddyID,
                Office,
                Position,
                notificationChannels,
                firstname,
                lastname,
                locale,
                shareAttendance,
                role,
                CustomRole,
                demoAccountID,
                favouriteReservableID,
                turnOnExperimentalFeatures,
                favouriteOfficeID,
                cognitoUserId,
                departmentsString,
            } = action.payload.employee

            const CustomsRoles = action.payload.company?.CustomRoles?.items || []

            let permissions = defaultPermissions[role || ERoleEmployee.EMPLOYEE]

            const RoleWithPermissions = CustomsRoles.find(
                (customRole) => customRole.id === CustomRole?.id || customRole.name === role,
            )

            if (RoleWithPermissions) {
                permissions = (RoleWithPermissions.Permissions?.items as EUserPermissions) || []
            }

            return {
                id,
                photo,
                email,
                favouriteOfficeID,
                firstname,
                lastname,
                turnOnExperimentalFeatures,
                locale: locale || EUserLocale.EN,
                shareAttendance: shareAttendance || EUserShareAttendance.ALL,
                departmentIDs: departmentIDs || [],
                favouriteColleagueIDs: favouriteColleagueIDs || [],
                favouriteReservableID: favouriteReservableID,
                buddyID: buddyID,
                position: Position,
                office: Office,
                demoAccountID: demoAccountID,
                notificationChannel:
                    notificationChannels === null ? state.notificationChannel : notificationChannels[0],
                fullName: `${firstname} ${lastname}`,
                role,
                permissions: permissions,
                rules: RoleWithPermissions ? (RoleWithPermissions.CustomRulesForRole as EUserCustomRules) : null,
                isAdmin: role === ERoleEmployee.MANAGER || role === ERoleEmployee.OWNER,
                cognitoUserId,
                departmentsString,
            }
        },
        userDataCleared: () => initialState,
    },
})

export const userActions = userSlice.actions
export default userSlice.reducer

export const selectedDepartmentIDs = createSelector(
    (state: RootState) => state.user.departmentIDs,
    (departmentIDs) => departmentIDs,
)

export const selectedFavouriteColleaguesIDs = createSelector(
    (state: RootState) => state.user.favouriteColleagueIDs,
    (IDs) => IDs,
)

export const selectedRules = createSelector(
    (state: RootState) => state.user.rules,
    (rules) => rules,
)

export const selectedIsAdmin = createSelector(
    (state: RootState) => state.user.isAdmin,
    (isManagerOrOwner) => isManagerOrOwner,
)

export const selectedCurrentUser = createSelector(
    (state: RootState) => state.user,
    (user) => user,
)
