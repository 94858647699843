import { Assessment } from 'src/components/widgets/NewPasswordInput/components/SafetyAssessment/index'
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`

export const Strength = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`

export const StrengthText = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
`

export const StrengthTitle = styled.span<{ color?: string }>`
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: ${({ color }) => color};
`

export const StrengthAssessment = styled.span<{ color: string }>`
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: ${({ color }) => color || 'rgba(227, 232, 238, 1)'};
`

export const StrengthLine = styled.div<{ assessment: Assessment }>`
    display: flex;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
`

export const StrengthLineItem = styled.span<{ color: string | undefined }>`
    flex: 1;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: ${({ color }) => color || 'rgba(227, 232, 238, 1)'};
`
