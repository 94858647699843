import moment from 'moment-timezone'

import { Booking } from '@graphql/autogenerate/schemas'

export const findRelevantBooking = (
    bookings: Booking[],
    employeeID: string,
    selectedReservableID: string | null,
): Booking | null => {
    // TODO use office timezone
    const currentUsersTime = moment.tz('Europe/Berlin')
    const employeeBookings = bookings.filter(
        (b) => b?.Employee?.id === employeeID && b.reservableID === selectedReservableID,
    )
    const relevantBookings = employeeBookings.filter((b) => {
        return moment(b.endTime.substring(0, 19)).isAfter(currentUsersTime)
    })

    const currentInterval = relevantBookings.find((interval) => {
        return moment(interval.startTime.substring(0, 19)).isBefore(currentUsersTime)
    })

    if (currentInterval) {
        return currentInterval
    }

    const nextBooking = relevantBookings.sort(
        (a, b) =>
            moment(a.startTime.substring(0, 19)).diff(currentUsersTime) -
            moment(b.startTime.substring(0, 19)).diff(currentUsersTime),
    )[0]

    return nextBooking || employeeBookings[0] || null
}
