import { ProgressBar } from 'components/atoms'

import Steps from './components/Steps'
import * as S from './styles'
import { useWizard } from './useWizard'

const InitialSetup = () => {
    const {
        components: [LeftColumn, RightColumn],
        isLoading,
        step,
    } = useWizard()

    return (
        <S.AuthRoot>
            <S.AuthRouteWrappContent>
                <S.AuthRoutFormContent>
                    <S.Wizard>
                        <S.WizardHeader>
                            Let’s get
                            <br /> you started
                        </S.WizardHeader>
                        <Steps activeStep={step} />
                        {LeftColumn}
                    </S.Wizard>
                </S.AuthRoutFormContent>
            </S.AuthRouteWrappContent>
            <S.AuthRouteLogoContainer>{RightColumn}</S.AuthRouteLogoContainer>
            <>{isLoading && <ProgressBar />}</>
        </S.AuthRoot>
    )
}

export default InitialSetup
