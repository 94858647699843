import { createContext, useContext, ReactElement } from 'react'

import { noop } from 'helpers'

export type AlertDataType = {
    title: string
    hasIcon?: boolean
    children?: ReactElement | null
    btnType?: string
}

export const defaultAlert: AlertDataType = {
    title: '',
    hasIcon: false,
    children: null,
}

type AlertContextType = {
    changeAlertData: (data: AlertDataType) => void
}

export const AlertCTX = createContext<AlertContextType>({ changeAlertData: noop })

export const useCustomAlert = (): AlertContextType => useContext(AlertCTX)
