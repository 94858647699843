import { EventInput } from '@fullcalendar/core'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment/moment'

import { BookingEquipment, Equipment } from '@graphql/autogenerate/schemas'
import { MainEmployeeData } from 'types/data.types'

import { InitialStateProps } from './resourceBookingSlice.types'

type SelectWeekNumberType = {
    weekNumber: number
    year: number
}

const initialState: InitialStateProps = {
    year: moment().year(),
    weekNumber: moment().isoWeek(),
    myBookings: [],
    events: [],
    resourceBookings: [],
    open: false,
    startWorkTime: '08:00',
    endWorkTime: '24:00',
    employeeID: '',
    currentResource: null,
    resourceID: '',
    loadingBookingInfo: false,
    selectedEmployee: null,
    currentBooking: null,
    editFormValue: null,
    selectedBookingTime: ['00:00', '24:00'],
    availableTimeForBooking: ['00:00', '24:00'],
    isEditMode: false,
    isAvailable: true,
    isNotAvailableReason: '',
    isFormLoading: false,
}

const editBookingResourceSlice = createSlice({
    name: 'editBookingResource',
    initialState,
    reducers: {
        init: (state, action: PayloadAction<Partial<InitialStateProps>>) => {
            return {
                ...state,
                ...action.payload,
            }
        },

        selectCurrentReservable: (state, action: PayloadAction<Equipment | null>) => {
            return {
                ...state,
                currentResource: action.payload,
            }
        },

        setReservableId: (state, action: PayloadAction<string>) => {
            state.resourceID = action.payload
        },

        selectWeekNumber: (state, action: PayloadAction<SelectWeekNumberType>) => {
            return {
                ...state,
                weekNumber: action.payload.weekNumber,
                year: action.payload.year,
            }
        },

        switchEditBooking: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                open: action.payload,
            }
        },

        setLoadingBookingInfo: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loadingBookingInfo: action.payload,
            }
        },

        setEvents(state, action: PayloadAction<EventInput[]>) {
            state.events = action.payload
        },

        addEvent(state, action: PayloadAction<EventInput>) {
            state.events.push(action.payload)
        },

        updateEvent(state, action: PayloadAction<{ id: string; event: Partial<EventInput> }>): void {
            const index = state.events.findIndex((e) => e.id === action.payload.id)
            if (index !== -1) {
                state.events[index] = { ...state.events[index], ...action.payload.event }
            }
        },

        removeEvent(state, action: PayloadAction<string>) {
            state.events = state.events.filter((e) => e.id !== action.payload)
        },

        setCurrentBooking: (state, action: PayloadAction<BookingEquipment | null>) => {
            const payload = action.payload
            if (!payload) {
                return {
                    ...state,
                    currentBooking: null,
                    currentReservable: null,
                }
            }

            return {
                ...state,
                currentBooking: payload,
            }
        },

        changeCurrentBooking: (state, action: PayloadAction<BookingEquipment>) => {
            const payload = action.payload
            if (!payload) {
                return {
                    ...state,
                    currentBooking: null,
                    currentReservable: null,
                }
            }

            return {
                ...state,
                currentBooking: action.payload,
            }
        },

        updateCurrentBooking: (state, action: PayloadAction<Partial<BookingEquipment>>) => {
            state.currentBooking = {
                ...(state.currentBooking as BookingEquipment),
                ...action.payload,
            }
        },

        setEdit: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isEditMode: action.payload,
            }
        },

        setSelectedEmployee: (state, action: PayloadAction<MainEmployeeData | null>) => {
            return {
                ...state,
                selectedEmployee: action.payload,
            }
        },

        setIsFormLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isFormLoading: action.payload,
            }
        },

        setIsAvailable: (state, action: PayloadAction<{ isAvailable: boolean; isNotAvailableReason: string }>) => ({
            ...state,
            isAvailable: action.payload.isAvailable,
            isNotAvailableReason: action.payload.isNotAvailableReason,
        }),

        afterSaveEvent: (state) => {
            return {
                ...state,
                loadingBookingInfo: false,
                // currentSpace: null,
                // selectedEmployee: null,
                currentBooking: null,
                // selectedBookingTime: ['00:00', '24:00'],
                // checkInStatus: false,
                // loadingTimeLine: true,
            }
        },

        reset: (state) => ({
            ...initialState,
            // don't reset working time
            employeeID: state.employeeID,
            startWorkTime: state.startWorkTime,
            endWorkTime: state.endWorkTime,
        }),
    },
})

export const editBookingResourceActions = editBookingResourceSlice.actions
export default editBookingResourceSlice.reducer
