import React, { FC, useState } from 'react'

import CustomAlert from 'src/components/molecules/CustomAlert'
import { AlertCTX } from 'src/context/alert/alertContext'
import styled from 'styled-components'

import { TickSVG } from 'assets/common'
import { AlertDataType, defaultAlert } from 'context/alert/alertContext'
import { ButtonOutlined } from 'styles/buttons-styles'

const AlertIconWrapper = styled.div`
    background-color: #1bd665;
    width: 40px;
    height: 40px;
    border-radius: 100%;
`

export const AlertProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [alertData, setAlertData] = useState(defaultAlert)

    const changeAlertData = (data: AlertDataType) => setAlertData(data)

    const closeHandler = () => setAlertData(defaultAlert)

    return (
        <AlertCTX.Provider value={{ changeAlertData }}>
            {children}
            {alertData.title && (
                <CustomAlert
                    title={alertData.title}
                    icon={
                        alertData.hasIcon ? (
                            <AlertIconWrapper className="flex-center">
                                <TickSVG width={16} height={13} fill="#ffffff" />
                            </AlertIconWrapper>
                        ) : undefined
                    }
                    onClose={closeHandler}
                    buttons={<ButtonOutlined onClick={closeHandler}>{alertData.btnType || 'OK'}</ButtonOutlined>}
                >
                    {alertData.children}
                </CustomAlert>
            )}
        </AlertCTX.Provider>
    )
}
