import React, { FC, useState, useEffect } from 'react'

import { app, pages } from '@microsoft/teams-js'
import { MsTeamsContext } from 'src/context/ms-teams/msTeams'

export const MsTeamsProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [inTeams, setInTeams] = useState<boolean>(false)
    const [fullScreen, setFullScreen] = useState<boolean | undefined>(undefined)
    const [context, setContext] = useState<app.Context | undefined>(undefined)

    useEffect(() => {
        console.log('[YOFFIX] initialize called')
        app.initialize()
            .then(() => {
                console.log('[YOFFIX] initialize success')
                app.getContext()
                    .then((ctx) => {
                        console.log('[YOFFIX] getContext success')
                        setInTeams(true)
                        setContext(ctx)
                        setFullScreen(ctx.page.isFullScreen)
                        pages.registerFullScreenHandler((isFullScreen) => {
                            setFullScreen(isFullScreen)
                        })
                    })
                    .catch(() => {
                        setInTeams(false)
                    })
            })
            .catch(() => {
                setInTeams(false)
            })
    }, [])

    return <MsTeamsContext.Provider value={{ inTeams, fullScreen, context }}>{children}</MsTeamsContext.Provider>
}
