import React, { FC } from 'react'

import { CateringProvider } from 'components/widgets/Catering/context'
import { useCompanyData } from 'context'
import { CompanyProvider } from 'context/company'

import Wrapper from '../components/Wrapper/Wrapper'

const EmployeeTemplateComponent: FC = () => {
    const { loading } = useCompanyData()

    return <CompanyProvider>{!loading && <Wrapper />}</CompanyProvider>
}

const EmployeeTemplate: FC = () => {
    return (
        <CompanyProvider>
            <CateringProvider>
                <EmployeeTemplateComponent />
            </CateringProvider>
        </CompanyProvider>
    )
}

export default EmployeeTemplate
