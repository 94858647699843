import React, { useState, FC, ChangeEvent, CSSProperties, useEffect } from 'react'

import ClearIcon from '@mui/icons-material/Clear'

import { SearchSVG } from 'assets/common'

import { SearchInputContainer, SearchInputField, SearchInputLabel } from './styles'

type TProps = {
    onInput: (text: string) => void
    onFocus?: () => void
    placeholder?: string
    iconStyles?: CSSProperties
    isBlankInput?: boolean
}

const SearchInput: FC<TProps> = ({ onInput, placeholder, iconStyles, isBlankInput, onFocus }) => {
    const [inputText, setInputText] = useState('')

    useEffect(() => {
        if (isBlankInput) {
            clearInput()
        }
    }, [isBlankInput])

    const handleInput = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        onInput(value)
        setInputText(value)
    }

    const clearInput = () => {
        setInputText('')
        onInput('')
    }

    return (
        <SearchInputContainer className="search">
            <SearchInputLabel htmlFor="search-input">
                {inputText.length === 0 ? (
                    <SearchSVG className="search_icon" style={iconStyles} />
                ) : (
                    <ClearIcon
                        onClick={() => {
                            clearInput()
                        }}
                        style={{ ...iconStyles, cursor: 'pointer' }}
                    />
                )}
            </SearchInputLabel>
            <SearchInputField
                id="search-input"
                autoComplete="off"
                placeholder={placeholder}
                onInput={handleInput}
                onFocus={onFocus}
                value={inputText}
            />
        </SearchInputContainer>
    )
}

export default SearchInput
