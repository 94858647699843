import { useState, useCallback, useEffect } from 'react'

import { API, Auth as AmplifyAuth } from 'aws-amplify'
import { debounce } from 'lodash'

export const useSearchInMicrosoft = () => {
    const [searchText, setSearchText] = useState<string>('')
    const [searching, setSearching] = useState<boolean>(false)
    const [searchResult, setSearchResult] = useState<any[]>([])
    const [searchError, setSearchError] = useState<string | null>(null)
    const [nextLink, setNextLink] = useState<string | null>(null)

    const searchInMicrosoftApi = async (next?: string, text?: string) => {
        const requestData = {
            headers: {
                Authorization: (await AmplifyAuth.currentSession()).getIdToken().getJwtToken(),
            },
            queryStringParameters: {
                nextLink: next,
                limit: 10,
                searchString: text,
            },
        }

        const response = await API.get(`list-microsoft-users`, '/microsoft-saas/list-microsoft-users', requestData)

        setNextLink(response['@odata.nextLink'] || null)

        return response?.value || []
    }

    const searchInMicrosoft = useCallback(
        debounce(async (next?, text?: string) => {
            setSearching(true)
            setSearchError(null)

            try {
                const result = await searchInMicrosoftApi(next, text)
                setSearchResult((prev) => [...prev, ...result])
            } catch (e) {
                // @ts-ignore
                setSearchError(e.message)
            } finally {
                setSearching(false)
            }
        }, 500),
        [],
    )

    const handleSearch = useCallback(
        (text?: string) => {
            setSearchText(text || '')
            searchInMicrosoft(nextLink, text)
        },
        [nextLink],
    )

    useEffect(() => {
        searchInMicrosoft()
    }, [searchInMicrosoft])

    return {
        handleSearch,
        setSearchText,
        searching,
        searchResult,
        searchError,
        nextLink,
    }
}
