import { gql } from '@apollo/client'

import { MAIN_EMPLOYEE, ROOM_RESERVABLE } from '@graphql/dataQuery'

export const CALENDAR_EVENT = gql`
    ${MAIN_EMPLOYEE}
    ${ROOM_RESERVABLE}
    fragment CalendarEventFullFields on CalendarEvents {
        id
        startTime
        endTime
        reservableID
        organiserEmployeeID
        title
        description
        owner
        isPrivate
        employeesIDs
        date

        OrganiserEmployee {
            ...MainEmployeeFields
            isSeatingInOffice
        }

        Employees {
            items {
                Employee {
                    ...MainEmployeeFields
                    isSeatingInOffice
                }
            }
        }

        CalendarEventRequest {
            id
            repeatType
            daysOfWeek
            dates
            effectiveFrom
            effectiveTo
        }

        Catering {
            id
            enabled
            title
        }

        equipmentItems {
            id
            title
            description
            quantity
        }

        menuItems {
            id
            description
            title
            quantity
        }

        CalendarEventGuests {
            items {
                id
                employeeFullName
                Guest {
                    fullName
                    email
                }
            }
        }

        Reservable {
            ...ReservableRoomFields
        }
    }
`

export const CALENDAR_EVENT_REQUEST = gql`
    ${MAIN_EMPLOYEE}
    fragment CalendarEventRequestFields on CalendarEventRequest {
        id
        startTime
        endTime
        reservableID
        organiserEmployeeID
        title
        description
        status
        repeatType
        dates
        daysOfWeek
        isPrivate
        employeesIDs

        OrganiserEmployee {
            ...MainEmployeeFields
        }

        Catering {
            id
            enabled
            title
        }

        menuItems {
            id
            description
            title
            quantity
        }
    }
`
