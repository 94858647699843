import * as React from "react";
import { forwardRef } from "react";
const SvgFavouriteColleagues = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { width: 16, height: 15, viewBox: "0 0 16 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { id: "Vector", d: "M7.13821 1.06728C7.45704 0.310908 8.54296 0.310908 8.86179 1.06728L10.2498 4.36016C10.3843 4.67903 10.6882 4.8969 11.037 4.9245L14.6399 5.20949C15.4675 5.27496 15.803 6.294 15.1725 6.82692L12.4275 9.14704C12.1617 9.3717 12.0456 9.72422 12.1268 10.0601L12.9655 13.5292C13.1581 14.326 12.2796 14.9558 11.5711 14.5288L8.48652 12.6698C8.18783 12.4898 7.81217 12.4898 7.51348 12.6698L4.42893 14.5288C3.72042 14.9558 2.84189 14.326 3.03452 13.5292L3.87316 10.0601C3.95437 9.72422 3.83829 9.3717 3.57248 9.14704L0.827478 6.82692C0.196958 6.294 0.532526 5.27496 1.36009 5.20949L4.96296 4.9245C5.31184 4.8969 5.61575 4.67903 5.75016 4.36016L7.13821 1.06728Z", fill: "#333333", fillOpacity: 0.25, style: {
  fill: "#333333",
  fill: "color(display-p3 0.2000 0.2000 0.2000)",
  fillOpacity: 0.25
} }));
const ForwardRef = forwardRef(SvgFavouriteColleagues);
export default ForwardRef;
