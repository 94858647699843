import { FunctionComponentElement, createElement, useState, useCallback } from 'react'

import { useLocation } from 'react-router-dom'

import { useAppSelector } from 'hooks'
import { selectCompany } from 'store/slices/company'

import { AdminConsent } from './components/AdminConsent'
import EditCompany from './components/EditCompany'
import { useImportUsers } from './components/ImportUsers/useImportUsers'
import { InitialLeftColumn } from './components/InitialLeftColumn'
import { InitialRightColumn } from './components/InitialRightColumn'
import { LastLeftColumn } from './components/LastLeftColumn'

const initialStep = 1
export const useWizard = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const from = searchParams.get('from')
    const presentStep = searchParams.get('step')
    const [isLoading, setIsLoading] = useState(false)
    const [step, setStep] = useState(presentStep ? parseInt(presentStep) : initialStep)
    const company = useAppSelector(selectCompany)

    if (from === 'ms-teams' && step === initialStep) {
        localStorage.setItem('is-ms-teams-flow', 'true')
    }

    const goNext = useCallback(() => {
        setStep(step + 1)
    }, [step])

    const [leftWidgetImportUsers, rightWidgetImportUsers] = useImportUsers({
        goNext,
        companyID: company?.id || '',
    })

    let leftColumn: FunctionComponentElement<any> | null = createElement(InitialLeftColumn)
    let rightColumn: FunctionComponentElement<any> | null = createElement(InitialRightColumn)

    if (!company) {
        return { components: [leftColumn, rightColumn], isLoading, step }
    }

    switch (step) {
        case 1:
            leftColumn = createElement(EditCompany, { goNext, company, setIsLoading })
            break
        case 2:
            leftColumn = createElement(AdminConsent, { goNext, company, setIsLoading })
            break
        case 3:
            leftColumn = leftWidgetImportUsers
            rightColumn = rightWidgetImportUsers
            break
        case 4:
            leftColumn = createElement(LastLeftColumn)
            break
    }

    return {
        components: [leftColumn, rightColumn],
        isLoading,
        step,
    }
}
