import * as React from "react";
import { forwardRef } from "react";
const SvgDescription = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M3 17C3 17.5523 3.44772 18 4 18H12C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16H4C3.44772 16 3 16.4477 3 17ZM4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7C21 6.44772 20.5523 6 20 6H4ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12Z", fill: "#333333", fillOpacity: 0.5, style: {
  fill: "#333333",
  fill: "color(display-p3 0.2000 0.2000 0.2000)",
  fillOpacity: 0.5
} }));
const ForwardRef = forwardRef(SvgDescription);
export default ForwardRef;
