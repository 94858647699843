import { createElement, useCallback, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'

import {
    MUTATION_CREATE_DEPARTMENT,
    MUTATION_CREATE_EMPLOYEE,
    MUTATION_CREATE_POSITION,
    MUTATION_INVITE_EMPLOYEES,
} from '@graphql/mutations'
import { QUERY_LIST_DEPARTMENTS, QUERY_LIST_POSITIONS } from '@graphql/queries'
import {
    EmployeeStatus,
    TEmployee,
    TInputEmployee,
    SyncSource,
    TDepartment,
    TPosition,
    TInputPosition,
} from 'types/data.types'

import { ImportUsers } from './ImportUsers'
import { WizardContent } from './WizardContent'
import { MicrosoftUser } from './types'

type Props = {
    goNext: () => void
    companyID: string
}
export const useImportUsers = ({ goNext, companyID }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [importedUsers, setImportedUsers] = useState<MicrosoftUser[]>([]) // [ImportedUser]
    const { data: dataDepartments } = useQuery<{ listDepartmentsByCompanyAndName: { items: Array<TDepartment> } }>(
        QUERY_LIST_DEPARTMENTS,
        {
            variables: {
                companyID: companyID,
            },
            errorPolicy: 'ignore',
        },
    )
    const { data: dataPositions } = useQuery<{ listPositionsByCompanyAndName: { items: Array<TPosition> } }>(
        QUERY_LIST_POSITIONS,
        {
            variables: {
                companyID: companyID,
            },
        },
    )

    const [createEmployee] = useMutation<{ createEmployee: TEmployee }, { input: TInputEmployee }>(
        MUTATION_CREATE_EMPLOYEE,
    )

    const [inviteEmployees] = useMutation<{ employee: TEmployee; error: string }, { employeeIDs: string[] }>(
        MUTATION_INVITE_EMPLOYEES,
    )

    const [createDepartment] = useMutation<
        { createDepartment: TDepartment },
        { input: Omit<TDepartment, 'id'> & { departmentCompanyId: string } }
    >(MUTATION_CREATE_DEPARTMENT)
    const [createPosition] = useMutation<{ createPosition: TPosition }, { input: TInputPosition }>(
        MUTATION_CREATE_POSITION,
    )

    const handleSave = useCallback(async () => {
        const tempDepartments: TDepartment[] = dataDepartments?.listDepartmentsByCompanyAndName?.items || []
        const tempPositions: TPosition[] = dataPositions?.listPositionsByCompanyAndName?.items || []
        const tempCreatedUsers: Array<string> = []

        setIsLoading(true)

        for (const user of importedUsers) {
            const firstname = user.givenName || user.displayName.split(' ')[0]
            const lastname = user.surname || user.displayName.split(' ')[1]

            let department = tempDepartments.find((item) => item.name === user.department)
            let position = tempPositions.find((item) => item.name === user.jobTitle)

            if (!department && user.department) {
                const dep = await createDepartment({
                    variables: {
                        input: {
                            name: user.department,
                            companyID: companyID,
                            departmentCompanyId: companyID,
                        },
                    },
                })

                if (dep.data?.createDepartment) {
                    department = dep.data.createDepartment
                    tempDepartments.push(department)
                }
            }

            if (!position && user.jobTitle) {
                const pos = await createPosition({
                    variables: {
                        input: {
                            name: user.jobTitle,
                            companyID: companyID,
                            positionCompanyId: companyID,
                        },
                    },
                })

                if (pos.data?.createPosition) {
                    position = pos.data?.createPosition
                    tempPositions.push(position)
                }
            }

            const createdUser = await createEmployee({
                variables: {
                    input: {
                        email: user.mail,
                        firstname: firstname,
                        lastname: lastname,
                        status: EmployeeStatus.ADDED,
                        employeeCompanyId: companyID,
                        departmentIDs: department ? [department.id] : [],
                        positionID: position?.id,
                        employeeDepartmentId: department?.id,
                        employeePositionId: position?.id,
                        companyID: companyID,
                        source: SyncSource.SCIM_AD,
                    },
                },
            })

            if (createdUser.data?.createEmployee) {
                tempCreatedUsers.push(createdUser.data.createEmployee.id)
            }
        }

        await inviteEmployees({
            variables: {
                employeeIDs: tempCreatedUsers,
            },
        })

        setIsLoading(false)

        goNext()
    }, [goNext, importedUsers, dataDepartments, dataPositions])

    if (!companyID) {
        return [null, null]
    }

    return [createElement(WizardContent, { handleSave, isLoading }), createElement(ImportUsers, { setImportedUsers })]
}
