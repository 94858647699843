import { useEffect } from 'react'

import { useAuth } from 'context'

const useAutoLogout = () => {
    const { userAuth, signOut } = useAuth()

    useEffect(() => {
        const handleInvalidToken = async (e: StorageEvent) => {
            if (
                e.key &&
                e.key ===
                    `CognitoIdentityServiceProvider.${import.meta.env.VITE_USER_POOLS_WEB_CLIENT_ID}.${userAuth?.employeeId}.idToken` &&
                e.oldValue &&
                e.newValue === null
            ) {
                await signOut()
                return
            }
        }

        window.addEventListener('storage', handleInvalidToken)
        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken)
        }
    }, [userAuth?.employeeId])
}

export default useAutoLogout
