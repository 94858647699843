import { useState, FC, ChangeEvent, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import Avatar from '@material-ui/core/Avatar'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'

import defaultLogo from 'assets/icons/default-company-logo.svg?url'
import { CustomInput } from 'components/atoms'
import GoogleMapInput, { getStrAddress } from 'components/atoms/GoogleMapInput'
import { useCompanyData } from 'context'
import { useAppDispatch, useGetImagesS3 } from 'hooks'
import { loaderActions } from 'store/slices/loader'
import { ButtonFilled, LinkOutlined } from 'styles/buttons-styles'
import { TPhotoLoaded } from 'types/common.types'
import { TCompany } from 'types/data.types'

import * as S from './styles'
import { SpaceInterfaceFormControl } from '../../../Settings/components/CompanyEdit/styles'
import { useCompanyEdit } from '../../../Settings/hooks/useCompanyEdit'

const schema = yup.object<IFormInputs>().shape({
    companyName: yup.string().required('Company name is required!'),
    address: yup.string(),
    phone: yup.string(),
})

interface IFormInputs {
    companyName: string
    address?: string
    phone?: string
}

type TProps = {
    company: TCompany
    goNext: () => void
    setIsLoading: (isLoading: boolean) => void
}

export const EditCompany: FC<TProps> = ({ company, goNext }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation(['translation'])
    const [urlCompanyLogo] = useGetImagesS3(company.companyLogo)
    const [logoCompany, setLogoCompany] = useState<TPhotoLoaded | string | null>(company.companyLogo || null)
    const [newLogo, setNewLogo] = useState<string | null>(null)
    const [isDataChanged, setIsDataChanged] = useState(false)
    const { saveUpdateCompany } = useCompanyEdit(company.id)
    const { update } = useCompanyData()
    const [addressCompany, setAddressCompany] = useState<string>(
        getStrAddress({
            country: company.country,
            city: company.city,
            address: company.address,
        }),
    )

    const { control, handleSubmit, formState } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            companyName: company.title,
            phone: company.phoneNumber,
        },
    })

    useEffect(() => {
        if (formState.isDirty) {
            setIsDataChanged(true)
        }
    }, [formState])

    const handleSave = async (dataSubmit: IFormInputs) => {
        await saveUpdateCompany(dataSubmit, logoCompany, addressCompany)
        dispatch(loaderActions.loaderShow(false))
        setIsDataChanged(false)
        await update()
        goNext()
    }

    const handleChangeAddress = (addressString: string) => {
        if (addressString !== addressCompany) {
            setIsDataChanged(true)
            setAddressCompany(addressString)
        }
    }

    const handleKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleSubmit(handleSave)()
        }
    }

    const onUpdateLogo = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.item(0)
        if (!file) {
            return
        }
        const img = {
            src: URL.createObjectURL(file),
            file: file,
            fileName: uuidv4() + '-' + file?.name,
            originalFileName: file?.name,
        }
        setLogoCompany(img)
        setNewLogo(img.src)
        setIsDataChanged(true)
    }

    return (
        <S.EditCompany>
            <div>
                <Controller
                    name="companyName"
                    control={control}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type="text"
                            label="Company name"
                            helperText={formState.errors.companyName?.message}
                            error={!!formState.errors.companyName?.message}
                            onKeyPress={handleKeyPressed}
                        />
                    )}
                />
                <h3>Company logo</h3>
                <S.UploadLogo>
                    <Avatar
                        variant="rounded"
                        className="photo-loader-avatar"
                        src={newLogo ? newLogo : urlCompanyLogo || defaultLogo}
                    />
                    <LinkOutlined as="label" width="148px" htmlFor="upload-file">
                        Upload logo
                        <input
                            type="file"
                            id="upload-file"
                            accept="image/jpeg,image/png,image/gif"
                            onChange={onUpdateLogo}
                            className="dn"
                        />
                    </LinkOutlined>
                </S.UploadLogo>
                <h3>Details</h3>
                <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type="text"
                            label="Phone number"
                            helperText={formState.errors.phone?.message}
                            error={!!formState.errors.phone?.message}
                            onKeyPress={handleKeyPressed}
                        />
                    )}
                />
                <SpaceInterfaceFormControl fullWidth size="small" variant="outlined">
                    <GoogleMapInput
                        onChange={(address) => handleChangeAddress(address.str || '')}
                        initStringAddress={addressCompany}
                    />
                </SpaceInterfaceFormControl>
            </div>

            <ButtonFilled disable={!isDataChanged} width="100%" onClick={handleSubmit(handleSave)}>
                {t('Next step', { ns: 'translation' })}
            </ButtonFilled>
        </S.EditCompany>
    )
}
