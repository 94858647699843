import { createContext, useContext } from 'react'

import { app } from '@microsoft/teams-js'

export const MsTeamsContext = createContext<{
    inTeams: boolean
    fullScreen?: boolean
    context?: app.Context
}>({
    inTeams: false,
})

export const useMsTeams = () => useContext(MsTeamsContext)
