import { TAddressGoogle } from 'types'

export const getStrAddress = (address?: TAddressGoogle) => {
    if (address === undefined) {
        return ''
    }

    const arrAddress = [] as Array<string>

    if (address.address) {
        arrAddress.push(address.address)
    }
    if (address.city) {
        arrAddress.push(address.city)
    }
    if (address.country) {
        arrAddress.push(address.country)
    }

    return arrAddress.join(', ')
}
