import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'

import { TextFieldProps } from '@material-ui/core'
import SafetyAssessment from 'src/components/widgets/NewPasswordInput/components/SafetyAssessment'

import HintRequirements from './components/HintRequirements'
import PasswordHint from './components/PasswordHint'
import { IconEyeOpenSVG, IconEyeCloseSVG } from '../../../assets/icons'
import { CustomInput } from '../../atoms'

interface NewPasswordInputProps {
    value: string
    onEnter?: () => unknown
    onChange?: TextFieldProps['onChange']
}

const iconStyles = {
    width: 24,
    cursor: 'pointer',
}

function checkForDigitsOnly(value: string) {
    const regExp = new RegExp('(?=.*[0-9])')
    return regExp.test(value)
}

function checkForUpperAndLowerCase(value: string) {
    const regExp = new RegExp('(?=.*[a-z])(?=.*[A-Z])')

    return regExp.test(value)
}

const NewPasswordInput = ({ value, onEnter, onChange }: NewPasswordInputProps) => {
    const [showPassword, setShowPassword] = useState(false)
    const [inFocus, setInFocus] = useState(false)

    const inputType = showPassword ? 'text' : 'password'

    const handlerFocus = useCallback((focusValue: boolean) => () => setInFocus(focusValue), [])

    const toggleShowPassword = () => setShowPassword(!showPassword)

    useEffect(() => {
        if (!value) {
            setShowPassword(false)
        }
    }, [value])

    const Icon = showPassword ? IconEyeCloseSVG : IconEyeOpenSVG

    const isValid = useMemo(() => {
        const result: [boolean, boolean, boolean] = [
            value.length >= 8,
            checkForUpperAndLowerCase(value),
            checkForDigitsOnly(value),
        ]

        return result
    }, [value])

    return (
        <div style={{ position: 'relative' }}>
            <CustomInput
                name="password"
                type={inputType}
                label="New password"
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        onEnter?.()
                    }
                }}
                onFocus={handlerFocus(true)}
                onBlur={handlerFocus(false)}
                InputProps={{
                    endAdornment: <Icon onClick={toggleShowPassword} style={iconStyles} />,
                }}
                value={value}
                onChange={onChange}
            />
            <PasswordHint
                hintRequirements={<HintRequirements value={value} isValid={isValid} />}
                safetyAssessment={<SafetyAssessment value={value} isValid={isValid} />}
            />
        </div>
    )
}

export default memo(NewPasswordInput)
