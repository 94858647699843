import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

import editBookingReducer from 'components/widgets/EditBooking/slices/editBookingSlice'
import employeeFiltersReducer from 'components/widgets/Filters/slices/employeeFilters'
import calendarScheduleReducer from 'containers/Calendar/slices/calendarSchedule'
import dashboardReducer from 'containers/Dashboard/slices/dashboard'
import editBookingResourceReducer from 'containers/Resources/slices/resourceBookingSlice'
import resourcesReducer from 'containers/Resources/slices/resourcesSlice'
import meetingRoomsReducer from 'containers/RoomsPage/slices/meetingRooms'
import mobileRoomsV2 from 'containers/RoomsPageMobile/slices/mobileRoomsV2'
import spaceScreenReducer from 'containers/SpaceScreen/slices/spaceScreen'
import calendarReducer from 'containers/UniversalCalendar/calendarSlice'
import guestsReducer from 'containers/VisitorsManagement/slices/visitors'

import company from './slices/company'
import loaderReducer from './slices/loader'
import messageReducer from './slices/message'
import userReducer from './slices/user'
import { createAppSyncApolloClient } from '../appSync/apollo-client'

const rootReducer = combineReducers({
    company,
    user: userReducer,
    calendar: calendarReducer,
    message: messageReducer,
    loader: loaderReducer,
    spaceScreen: spaceScreenReducer,
    calendarSchedule: calendarScheduleReducer,
    mobileRoomsV2: mobileRoomsV2,
    editBooking: editBookingReducer,
    dashboard: dashboardReducer,
    meetingRooms: meetingRoomsReducer,
    resources: resourcesReducer,
    editBookingResource: editBookingResourceReducer,
    employeeFilters: employeeFiltersReducer,
    guests: guestsReducer,
})

const client: ApolloClient<NormalizedCacheObject> = createAppSyncApolloClient({
    connectToDevTools: import.meta.env.VITE_NODE_ENV === 'development',
})

export const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: {
                    client,
                },
            },
        }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
